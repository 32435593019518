import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";

import { msalConfig } from "../auth/authConfig";
import { getRoutePathList, storeRoutePathList } from "../utils/RouterStorage";
import Logo from "../utils/Logo";
import Emitter from "../utils/Emitter";
import Feedback from "./topbar/Feedback";
import Contact from "./topbar/Contact";
import Logout from "./topbar/Logout";
import Notification from "./topbar/Notification";
import GPTButton from "./topbar/GPTButton"
import * as sidebarService from "../utils/sidebarService";
import MultiLevelNav from "./navbar/MultiLevelNav";
import LayoutStyles from "./LayoutStyles";
import { getAutorenewedToken } from "../auth/msalUtils";
import "../../styles/loading.css";
import { Button, Divider } from "@material-ui/core";
import clsx from "clsx";
import ChatBot from "../utils/ChatBot";
import { Typography, Link, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    //backgroundColor: theme.palette.background.paper,
    //padding: theme.spacing(2),
    paddingRight:theme.spacing(2),
    textAlign: "right",
    marginBottom:"1%",
  },
}));


export default function Layout() {
  const { instance, accounts, inProgress } = useMsal();
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  let defaultURL = "/";
  let imageURL = defaultURL + (process.env.REACT_APP_SPINNER_FAVICON_NAME ? process.env.REACT_APP_SPINNER_FAVICON_NAME :  "android-chrome-192x192.png");
  const classess = useStyles();

  const disableImcpAssist = process.env.REACT_APP_DISABLE_IMCP_ASSIST
  // If a user is not logged in and authentication is not already in progress, invoke login
  if (accounts.length === 0 && inProgress === InteractionStatus.None) {
    navigate("/loginCallback");
  } else {
    if (inProgress === InteractionStatus.None) {
      const apiScope = msalConfig.auth.clientId + "/.default";
      const accessTokenRequest = {
        scopes: [`${apiScope}`],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Get route path list
          const storedRouteList = getRoutePathList();
          if (!storedRouteList) {
            fetch(process.env.REACT_APP_API_PATH + "/route/active", {
              method: "GET",
              headers: {
                authorization: "Bearer " + accessTokenResponse.accessToken,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                storeRoutePathList(JSON.stringify(data));
              })
              .catch((error) => {
                console.error("Failed to get route path list!", error);
              });
          }
          setIsLoaded(true);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.error("An error while fetching Token!", error);
        });
    }
  }

  const classes = LayoutStyles();
  const [open, setOpen] = React.useState(true);
  const [termsOfUse, setTermsOfUse] = useState([]);
  const [termsOfUseEnable, setTermsOfUseEnable] = useState('false');

  const transSiderbarList = (inNavData) => {
    //THIS IS WHERE THE API DATA WILL BE LOADED FROM
    let oldNavItems = inNavData.sort(
      (a, b) => a.sidebarParent - b.sidebarParent
    );
    return Object.values(
      oldNavItems.reduce((a, o) => {
        o.sidebarIconName = sidebarService.generateIconCode(o.sidebarIconName);
        if (o.sidebarParent === 0) {
          a[o.sidebarId] = a[o.sidebarId] || { ...o, items: [] };
        } else {
          if (a[o.sidebarParent] && a[o.sidebarParent].items)
            a[o.sidebarParent].items.push({ ...o });
        }
        return a;
      }, {})
    );
  };

  const [navData, setNavData] = useState([]);

  const getTermsPdf = async () => {
    fetch(process.env.REACT_APP_API_PATH + "/terms", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        const data = await response.json();
        setTermsOfUse(data);

        const enable  = data.map((item) => {
          return item.termsFileExists;
        })
        setTermsOfUseEnable(enable.toString());
        
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getSidebarList = async () => {
    fetch(process.env.REACT_APP_API_PATH + "/sidebar/active", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        // Set the realtime sidebar list
        const orderedData = transSiderbarList(data)
          .sort((a, b) => a.sidebarOrder - b.sidebarOrder)
          .map((x) => {
            if (x.items && x.items.length > 1)
              x.items = x.items.sort((a, b) => a.sidebarOrder - b.sidebarOrder);
            return x;
          });
        setNavData(orderedData);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    getTermsPdf();
    function handleResize() {
      if (window.innerWidth > 1024) setOpen(true);
      else setOpen(false);
    }
    window.addEventListener("resize", handleResize);
    if (isLoaded) getSidebarList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useMemo(() => {
    Emitter.on("SIDEBAR_UPDATE", async () => {
      fetch(process.env.REACT_APP_API_PATH + "/sidebar/active", {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      })
        .then(async (response) => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            console.error("Something went wrong while update sidebar", error);
          }

          // Set the realtime sidebar list
          const orderedData = transSiderbarList(data)
            .sort((a, b) => a.sidebarOrder - b.sidebarOrder)
            .map((x) => {
              if (x.items && x.items.length > 1)
                x.items = x.items.sort(
                  (a, b) => a.sidebarOrder - b.sidebarOrder
                );
              return x;
            });
          setNavData(orderedData);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    });
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={1}>{MultiLevelNav(navData, open, handleDrawerOpen)}</Box>
      <Box flexGrow={1} />
    </Box>
  );

  const openInNewTab = async () => {
    if (termsOfUse.length > 0) {
      const base64String = termsOfUse.map((item) => {
        return item.termsConvertedBase64String;
      });

      const fileName = termsOfUse.map((item) => {
        return item.termsName;
      });

      const fileType = termsOfUse.map((item) => {
        return item.termsFileType;
      });
      if (termsOfUseEnable === "true") {
        let pdfWindow = window.open("");
        pdfWindow.document.body.innerHTML = '<div id="docViewC"></div>';
        pdfWindow.document.write(
          "<html<head><title>Terms of Use</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>"
        );

        pdfWindow.document.write(
          "<body><embed width='100%' height='100%' src='data:" +
            fileType +
            ";base64, " +
            encodeURI(base64String) +
            "#toolbar=0'></embed></body></html>"
        );

        pdfWindow.document.documentElement.style.overflow = "hidden";
        pdfWindow.document.body.style.overflow = "hidden";
      } else {
        const newTab = window.open("", "_blank");
        newTab.document.title = "Terms of Use";
      }
    } else {
      const newTab = window.open("", "_blank");
      newTab.document.title = "Terms of Use";
    }
  };

  const contentTerms = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      style={{ textAlign: "center" }}
    >
        <Box>
          <Button
            role="link"
            onClick={() => openInNewTab()}
            style={{ textTransform: "none" }}
            className={clsx(classes.menuButton, classes.active)}
          >
            Terms of Use
          </Button>
        </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return isLoaded ? (
    <AuthenticatedTemplate>
      <div className={classes.root}>
        <CssBaseline />
        <Box boxShadow={3}>
          <AppBar
            position="fixed"
            elevation={0}
            color="secondary"
            style={{ boxShadow: "inherit" }}
            className={classes.appBar}
          >
            <Toolbar disableGutters={true} style={{ boxShadow: "inherit" }} >
              <IconButton
                color="inherit"
                edge="start"
                aria-label="Open drawer"
                onClick={handleDrawerOpen}
                className={classes.menuButton}
                
              >
                <MenuIcon
                  classes={{
                    root: open
                      ? classes.menuButtonIconOpen
                      : classes.menuButtonIconClosed,
                  }}
                 
                />
              </IconButton>
              <RouterLink to={defaultURL}>
                <Logo />
              </RouterLink>


              <Box flexGrow={1} />

              {/* <GPTButton/> */}
              <Notification />
              <Feedback />
              
              <Contact />
              <Logout />
            </Toolbar>
          </AppBar>
        </Box>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.toolbar} />

          {content}

          <Divider />
          {open ? <Box>{contentTerms}</Box> : <Box></Box>}
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Outlet />
          <footer className={classess.footer}>
          {disableImcpAssist == "false" && <ChatBot/>}
        </footer>
        </main>
      </div>
    </AuthenticatedTemplate>
  ) : (
    <div className={"sk-circle"}>
      <div
        style={{
          position: "relative",
          float: "left",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img
          alt="Logo"
          width="96px"
          height="96px"
          src={imageURL}
        />
      </div>
      <div className={"sk-circle1 sk-child"}></div>
      <div className={"sk-circle2 sk-child"}></div>
      <div className={"sk-circle3 sk-child"}></div>
      <div className={"sk-circle4 sk-child"}></div>
      <div className={"sk-circle5 sk-child"}></div>
      <div className={"sk-circle6 sk-child"}></div>
      <div className={"sk-circle7 sk-child"}></div>
      <div className={"sk-circle8 sk-child"}></div>
      <div className={"sk-circle9 sk-child"}></div>
      <div className={"sk-circle10 sk-child"}></div>
      <div className={"sk-circle11 sk-child"}></div>
      <div className={"sk-circle12 sk-child"}></div>
    </div>
  );
}

Layout.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
};
