import { Button, Card, CardActions, CardContent, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import Page from "../utils/Page";
import PageHeader from "../utils/PageHeader";
import TocIcon from "@material-ui/icons/Toc";
import SettingIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NotFoundView from "../errors/NotFoundView";

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    icon: {
        color: theme.palette.text.primary,
        fontSize: 18,
    },
    card:{
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(3),
        borderRadius: "5px", 
        padding: "0px", 
        border: "0px" ,
        backgroundColor:"#fafafa",
      },
      cardContent:{
        paddingTop: "0px", 
        paddingLeft: "10px", 
        height: "200px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        cursor:"pointer"
      },
      bottomSpace:{
        minHeight:100,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
      },

}));

const DataConfigurationMenuView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
  
    useEffect(() =>{
        if(process.env.REACT_APP_DISABLE_DATA_CONFIGURATION == "true")
        {
            navigate('/not-found')
        }     
    },[])

    return (
        <Page title="Data Configuration">
            <PageHeader
                title="Data Configuration"
                subtitle="Configure Data"
                icon={<TocIcon className={classes.icon} forntsize="large" />}
                disableBackButton={true}
            />
            <Paper className={classes.pageContent}>
                <Grid container>

                    <Grid item lg={3} md={6} xs={12}>

                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <Card
                            className={classes.card}
                            onClick={() => navigate('/data-configuration/connection')}
                        >
                            <CardContent className={classes.cardContent}>
                                <SettingIcon style={{ width: 150, height: 150 }}></SettingIcon>
                            </CardContent>
                            <Typography
                                align="left"
                                color="textPrimary"
                                variant="h5"
                                component="p"
                                className={classes.bottomSpace}
                            >
                                Manage Existing Connection
                            </Typography>
                        </Card>

                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <Card
                            className={classes.card}
                            onClick={() => navigate('/data-configuration/connection')}
                        >
                            <CardContent className={classes.cardContent}>
                                <AddIcon style={{ width: 150, height: 150 }}></AddIcon>
                            </CardContent>
                            <Typography
                                align="left"
                                color="textPrimary"
                                variant="h5"
                                component="p"
                                className={classes.bottomSpace}
                            >
                                Add New Connection
                            </Typography>
                        </Card>

                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                    </Grid>

                </Grid>
                
            </Paper>
        </Page>
    );
}
export default DataConfigurationMenuView;