import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  useTheme
} from '@material-ui/core';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import MaterialButton from 'src/components/utils/Button';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
  },
  dialogTitle: {
    textAlign: 'center'
  },
  dialogContent: {
    textAlign: 'center'
  },
  dialogAction: {
    justifyContent: 'center'
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.lightRed,
    //color: "#f83245",
    color: theme.palette.secondary.red,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'default'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '8rem'
    }
  }
}));

export default function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog
      open={confirmDialog?.isOpen ? confirmDialog?.isOpen : false}
      classes={{ paper: classes.dialog }}
    >
      {confirmDialog.isNoIcon ? (
        ''
      ) : (
        <DialogTitle className={classes.dialogTitle}>
          <IconButton disableRipple className={classes.titleIcon}>
            <NotListedLocationIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h5">{confirmDialog.title}</Typography>
        <Typography variant="subtitle1">{confirmDialog.subtitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <MaterialButton
          text="Cancel"
          color="default"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
        <MaterialButton
          text={confirmDialog.btnText ? confirmDialog.btnText : 'Delete'}
          style={{
            backgroundColor: confirmDialog.isNoIcon
              ? theme.palette.primary.main
              : theme.palette.secondary.red,
            color: confirmDialog.isNoIcon
              ? theme.palette.blackColor.main
              : theme.palette.whiteColor.main
          }}
          onClick={confirmDialog.onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
}
