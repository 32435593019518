import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react'
import Controls from '../../utils/controls/Controls';
import { useForm, Form } from '../../form/useForm';
import MuiButton from '../../utils/Button';


const initialValues = {
    dataSetId: 0,
    sourceId: 0,
    dataSetContainer: "",
    dataSetSchema: "",
    dataSetType: "",
    dataSetName: "",
    dataSetTitle: "",
    lastUpdatedDateTime: Date.now(),
    isActive: true,
    customSqlQuery: "",
    customSourcePath: "",
    dataSetFormat: "",
    dataSetStatus: "",
    folderPathRaw: "",
    folderPathCurated: "",
    folderPathConsumption: "",
    databricksNotebookPath: "",
    cmRenameCols: true,
    cmDropNullCols: true,
    fastTrackCopy: true,
    keyColumns: "",
    ingestType: "",
    explore: true,
    landingTable: "",
    sourceLoadStoredProcedure: "",
    targetTable: "",
    targetLoadStoredProcedure: "",
    firstRowHasHeader: true,
    indexColumns: "",
    datasetDateColumn: "",
    fullLoadStartDateTime: Date.now()
}

export default function UpdateDatasetForm(props) {

    const { addOrEdit, recordForEdit, disableContent } = props
    const validate = (fieldValues = values) => {

        let temp = { ...errors }

        if ('sourceId' in fieldValues){
            temp.sourceId = fieldValues.sourceId ? "" : "This field is required."
        }
        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x === "")
        }
        
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null){
            setValues({
                ...recordForEdit
            })
        }     
    }, [recordForEdit, setValues])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={4}>
                    <Controls.Input
                        variant="outlined"
                        label="Dataset ID"
                        name="dataSetId"
                        value={values.dataSetId}
                        onChange={handleInputChange}
                        error={errors.dataSetId}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Dataset Schema"
                        name="dataSetSchema"
                        value={values.dataSetSchema}
                        onChange={handleInputChange}
                        error={errors.dataSetSchema}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Dataset Title"
                        name="dataSetTitle"
                        value={values.dataSetTitle}
                        onChange={handleInputChange}
                        error={errors.dataSetTitle}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Custom SQL Query"
                        name="customSqlQuery"
                        value={values.customSqlQuery}
                        onChange={handleInputChange}
                        error={errors.customSqlQuery}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Dataset Status"
                        name="dataSetStatus"
                        value={values.dataSetStatus}
                        onChange={handleInputChange}
                        error={errors.dataSetStatus}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Folder Path Consumption"
                        name="folderPathConsumption"
                        value={values.folderPathConsumption}
                        onChange={handleInputChange}
                        error={errors.folderPathConsumption}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="cmDropNullCols"
                        label="Cm Drop Null Cols"
                        value={values.cmDropNullCols}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Ingest Type"
                        name="ingestType"
                        value={values.ingestType}
                        onChange={handleInputChange}
                        error={errors.ingestType}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Source Load Stored Procedure"
                        name="sourceLoadStoredProcedure"
                        value={values.sourceLoadStoredProcedure}
                        onChange={handleInputChange}
                        error={errors.sourceLoadStoredProcedure}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="firstRowHasHeader"
                        label="First Row Has Header"
                        value={values.firstRowHasHeader}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />
                    <Controls.DatePicker
                        label="Full Load Date Time"
                        name="fullLoadStartDateTime"
                        value={values.fullLoadStartDateTime}
                        onChange={handleInputChange}
                        error={errors.fullLoadStartDateTime}
                        margin="dense"
                        disabled={disableContent}
                    />
                </Grid>

                <Grid item xs={4}>                    
                    <Controls.Input
                        variant="outlined"
                        label="Source ID"
                        name="sourceId"
                        value={values.sourceId}
                        onChange={handleInputChange}
                        error={errors.sourceId}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Dataset Type"
                        name="dataSetType"
                        value={values.dataSetType}
                        onChange={handleInputChange}
                        error={errors.dataSetType}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.DatePicker
                        label="Last Update Date Time"
                        name="lastUpdatedDateTime"
                        value={values.lastUpdatedDateTime}
                        onChange={handleInputChange}
                        error={errors.lastUpdatedDateTime}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Custom Source Path"
                        name="customSourcePath"
                        value={values.customSourcePath}
                        onChange={handleInputChange}
                        error={errors.customSourcePath}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Folder Path Raw"
                        name="folderPathRaw"
                        value={values.folderPathRaw}
                        onChange={handleInputChange}
                        error={errors.folderPathRaw}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Databricks Notebook Path"
                        name="databricksNotebookPath"
                        value={values.databricksNotebookPath}
                        onChange={handleInputChange}
                        error={errors.databricksNotebookPath}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="fastTrackCopy"
                        label="Fast Track Copy"
                        value={values.fastTrackCopy}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="explore"
                        label="Explore"
                        value={values.explore}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Target Table"
                        name="targetTable"
                        value={values.targetTable}
                        onChange={handleInputChange}
                        error={errors.targetTable}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Index Columns"
                        name="indexColumns"
                        value={values.indexColumns}
                        onChange={handleInputChange}
                        error={errors.indexColumns}
                        margin="dense"
                        disabled={disableContent}
                    />
                </Grid>
                <Grid item xs={4}>                    
                    <Controls.Input
                        variant="outlined"
                        label="Dataset Container"
                        name="dataSetContainer"
                        value={values.dataSetContainer}
                        onChange={handleInputChange}
                        error={errors.dataSetContainer}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Dataset Name"
                        name="dataSetName"
                        value={values.dataSetName}
                        onChange={handleInputChange}
                        error={errors.dataSetName}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Dataset Format"
                        name="dataSetFormat"
                        value={values.dataSetFormat}
                        onChange={handleInputChange}
                        error={errors.dataSetFormat}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Folder Path Curated"
                        name="folderPathCurated"
                        value={values.folderPathCurated}
                        onChange={handleInputChange}
                        error={errors.folderPathCurated}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="cmRenameCols"
                        label="Cm Rename Cols"
                        value={values.cmRenameCols}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Key Columns"
                        name="keyColumns"
                        value={values.keyColumns}
                        onChange={handleInputChange}
                        error={errors.keyColumns}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Landing Table"
                        name="landingTable"
                        value={values.landingTable}
                        onChange={handleInputChange}
                        error={errors.landingTable}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Target Load Stored Procedure"
                        name="targetLoadStoredProcedure"
                        value={values.targetLoadStoredProcedure}
                        onChange={handleInputChange}
                        error={errors.targetLoadStoredProcedure}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Dataset Date Column"
                        name="datasetDateColumn"
                        value={values.datasetDateColumn}
                        onChange={handleInputChange}
                        error={errors.datasetDateColumn}
                        margin="dense"
                        disabled={disableContent}
                    />
                </Grid>

                <Grid container item xs={12} justifyContent="flex-end">
                    {disableContent? <div> </div> : 
                        <div>
                            <MuiButton
                                    text="Submit"
                                    type="submit"
                            />
                            <MuiButton
                                variant="contained"
                                color="default"
                                size="large"
                                text="Reset"
                                onClick={resetForm}
                            />
                        </div>
                    }                    
                </Grid>
            </Grid>
        </Form>
    )
}
