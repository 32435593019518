import { ReactNode, ChangeEvent } from "react";
import {
  FormControl,
  InputLabel,
  Select as Dropdown,
  MenuItem,
  FormHelperText,
  makeStyles,
  TextField,
  Box,
} from "@material-ui/core";
import { OptionConfig, SelectOption, Validation } from "src/types";
import { useGet } from "../../../hooks";
import { Field } from "formik";

// const useStyles = makeStyles((theme) => ({
//   paddingDense: {
//     paddingTop: "10.5px",
//     paddingBottom: "10.5px",
//   },
// }));
interface FieldInput {
  field: any;
  form: any;
  meta: any;
}
interface SelectProps {
  name?: string;
  label: string;
  disabled?: boolean;
  isNameValue?: boolean;
  options?: SelectOption[]; //this should be retired, it's not currently used (unsure if this may break IMCP in unforseen ways)
  endpoint?: OptionConfig; //this should be renamed to options
  error?: any;
  validation?: Validation;
}

export default function Select(props: SelectProps) {
  const {
    name,
    label,
    isNameValue,
    options,
    endpoint,
    validation,
    // ...other
  } = props;
  const { data } = useGet(endpoint && endpoint?.endpoint, !!endpoint?.endpoint);
  const optionsClean = data && endpoint && data?.map(endpoint.map);
  const newOptions: { id: string; title: string }[] = [];
  newOptions.push({ id: '0', title: "None" });
  const optionSet =
    optionsClean && optionsClean?.length > 0
      ? optionsClean.map((item) => {
          newOptions.push({ id: item.id.toString(), title: item.title });
        })
      : null;
  return (
    <Box
      sx={{
        marginTop: "5%",
        // marginBottom: "-1%",
      }}
    >
      <Field
        name={name}
        // margin="dense"
        validation={validation}
      >
        {({ field, form, meta }: FieldInput) => (
          <>
            <FormControl
              style={{
                minWidth: "75%",
              }}
              error={validation && !!validation.validation(field.value)}
            >
              <InputLabel>{label}</InputLabel>
              <Dropdown {...field}>
                {optionsClean && optionsClean?.length > 0 ? (
                  name === "defaultDashboardId" ? (
                    newOptions.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={isNameValue ? item.title : item.id}
                      >
                        {item.title === "None" ? (
                          <i>{item.title}</i>
                        ) : (
                          item.title
                        )}
                      </MenuItem>
                    ))
                  ) : (
                    optionsClean.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={isNameValue ? item.title : item.id}
                      >
                        {item.title === "None" ? (
                          <i>{item.title}</i>
                        ) : (
                          item.title
                        )}
                      </MenuItem>
                    ))
                  )
                ) : (
                  <MenuItem></MenuItem>
                )}
              </Dropdown>
              {validation && validation.validation(field.value) && (
                <FormHelperText>
                  {validation && validation.validation(field.value)}
                </FormHelperText>
              )}
            </FormControl>
          </>
        )}
      </Field>
    </Box>
  );
}
