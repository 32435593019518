import { Box, Icon, Typography, useTheme } from "@material-ui/core";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import { makeStyles } from "@material-ui/styles";
import { ChatMessage } from "./ChatMessage";
import Markdown from "react-markdown";
import { CircularProgress } from "@material-ui/core";
import RedditIcon from "@material-ui/icons/Reddit";
import { format } from 'date-fns';

type ChatBubbleProps = {
  chatMessage: ChatMessage;
  devMode: boolean;
};

export default function ChatBubble(props: ChatBubbleProps) {
  const currentDate = new Date();
  const theme = useTheme();
  const chatIconStyle = {
    color: "#26890D",
  };

  const useStyles = makeStyles((theme) => ({
    myList: {
      "& ul, & ol": {
        listStylePosition: "outside",
        marginLeft: "1.5em",
        marginTop: "0.5em",
        marginBottom: "0.5em",
      },
      "& p + p": {
        //Adjust margin between new lines, only between paragraphs
        lineHeight: 1.5,
        marginTop: "1.0em",
      },
      // Prevent a code block from overflowing
      "& code": {
        whiteSpace: "pre-wrap",
      },
      // Code block formatting
      "& pre": {
        backgroundColor: "#f5f5f5",
        width: "fit-content",
        borderRadius: "5px",
        padding: "10px",
        border: "1px solid #e0e0e0",
        marginTop: "1.0em",
        marginBottom: "1.0em",
      },
    },
    triangle: {
      position: 'absolute',
      top: '50%',
      right: '-10px',
      transform: 'translateY(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: '10px solid #f0f0f0',
      bgcolor: "#F7F7F7"
    },
  }));

  const formatDate = (date) => {
    return format(date, 'dd-MMM-yyyy , hh:mm:ss a');
  };
  
  const classes = useStyles();

  return (
    <Box
    // sx={{
    //   display: "flex",
    //   flexDirection: "row",
    //   alignItems:"center"
    // }}
    >
      <Box
      sx={{
        display: "flex",
        flexDirection: props.chatMessage.role === "llm" ? "row" : "row-reverse",
        alignItems:"center"
      }}
      >
        <Box
          sx={{
            margin: 5,
            marginRight: 10,
            // bgcolor:
            //   props.chatMessage.role === "llm"
            //     ? "rgba(0, 85, 135, 1)"
            //     : "#46A92D",
            flex: "0 0 auto",
            height: "45px",
            width: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: "2px solid #26890D"
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {props.chatMessage.role === "llm" ? (
              <Icon style={chatIconStyle}>
                {/* <RedditIcon /> */}
                <img
                  // style={{ maxWidth: "100%", width: "auto", height: "auto" }}
                  alt="Logo"
                  src="/static/images/auto_awesome_deloitte_green.svg"
                  // {...props}
                />
              </Icon>
            ) : (
              <Box sx={{ color: "#26890D" }} fontSize="0.8rem" fontWeight="bold">
                {props.chatMessage.name}
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            padding: 10,
            width: "fit-content",
            borderRadius: 5,
            maxWidth: "100%",
            margin: 5,
          }}
        >
          <Typography variant="body1">{formatDate(props.chatMessage.date)}</Typography>
        </Box>
        
      </Box>
      <Box
       sx={{
        display: "flex",
        flexDirection: props.chatMessage.role === "llm" ? "row" : "row-reverse",
        alignItems:"center"
      }}>
        <Box
          sx={{
            bgcolor: "#f0f0f0",
            padding: 10,
            width: "fit-content",
            borderRadius: 10,
            maxWidth: "100%",
            margin: 5,
            //backgroundColor: "#F7F7F7"
          }}
        >
          <div className={classes.myList}>
            <>
              {props.chatMessage.role == "llm" &&
                props.chatMessage.message == "" ? (
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <CircularProgress size={20}></CircularProgress>
                </Box>
              ) :(
              <>
              <Markdown>{props.chatMessage.message}</Markdown>
              <div className={classes.triangle}></div>
              </>
                
              )}
            </>
          </div>
          {props.chatMessage.role === "llm" && props.devMode ? (
            <Box sx={{ color: "#005587" }}>
              <Typography variant="caption">
                TOKENS : {props.chatMessage.tokens} | PRICE:{" "}
                {props.chatMessage.price.toFixed(5)}{" "}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>

      </Box>
      
    </Box>
  );
}
