import { useState } from "react";
import { TwitterPicker } from 'react-color';
import { Theme } from './Theme';

interface Props{
    theme : Theme,
    themeSelected(theme:Theme):Theme,
}

export default function ColorPicker({theme, themeSelected}:Props){ 
    const [color, setColor] = useState(theme);

    const handleChangeComplete = data => {
        const updatedColor = {
            ...theme,
            colorHexCode: data.hex,
          };
        setColor(updatedColor);
        themeSelected(updatedColor);
       
    };

    
    return (

       <TwitterPicker onChange={ handleChangeComplete } triangle="hide"  color={ theme.colorHexCode } />
    )
}