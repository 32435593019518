import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@material-ui/core";
import React from "react";
import MaterialButton from 'src/components/utils/Button';
import Error from "@material-ui/icons/ErrorOutline";

interface AlertProps{
    project:string[]
    fileName:string
    sendData:any
}
const AlertDialog=(props:AlertProps)=> {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();

    function generate(element: React.ReactElement) {
        return [0, 1, 2].map((value) =>
          React.cloneElement(element, {
            key: value,
          }),
        );
      }

    const handleClose = () => {
        setOpen(false);
        props.sendData();
    };
  
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{color: theme.palette.secondary.red}}>
                <div style={{display:"flex", alignItems:"center"}}><Error></Error><span style={{paddingLeft:"2%"}}>{"Failed to upload template"}</span></div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{props.fileName} contains rows that do not match projects configured in the platform.</DialogContentText>
            <DialogContentText id="alert-dialog-description">The following projects do not exist:</DialogContentText>
            <DialogContentText>
            <ul style={{paddingLeft:"3%"}}>
                {props.project.map((pro,idx) => (
                    <li key={idx}>{pro}</li>
                ))}
                </ul>

            </DialogContentText>
            <DialogContentText id="alert-dialog-description"> Please configure projects by first uploading Project.csv</DialogContentText>
          </DialogContent>
          
          <DialogActions>
            <MaterialButton onClick={handleClose}
            text={'Close'}
            style={{
                backgroundColor:theme.palette.secondary.red,
                color: theme.palette.whiteColor.main
              }}>Close</MaterialButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  export default AlertDialog