import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/utils/Page';
import Toolbar from './AppToolbar';
import AppCard from './AppCard'; 
import { getAutorenewedToken } from '../auth/msalUtils';
import Emitter from '../utils/Emitter';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  AppCard: {
    height: '100%',
    maxHeight: "500px",
    maxWidth: "500px"
  }
}));

const Applications = () => {
  const classes = useStyles();
  const [applications, setApplications] = useState([]);
  const [appList, setAppList] = useState([]);

  const loadApplications = async () => {
      //THIS IS WHERE THE API FETCH CALL SHOULD BE MADE
      fetch(process.env.REACT_APP_API_PATH + '/applications/active', {
        method: 'GET',
        headers: { 'authorization': await getAutorenewedToken() }
      }).then(async response => {
          const data = await response.json();
  
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (data && data.message) || response.statusText;
              setAppList([]);
              setApplications([]);
              return Promise.reject(error);
          }
          setAppList(data);
          setApplications(data);
      }).catch(error => {
        setAppList([]);
        setApplications([]);
        console.error('There was an error!', error);
      });
  };

  Emitter.on('FILTER_APPLICATIONS', (target)=>{
    if (target === "") {
      setApplications(appList);
    }else{
      setApplications(appList.filter(data => Object.values(data).some(val => String(val).toLowerCase().includes(target.toString().toLowerCase()))));
    }
  });

  useEffect(() => {
    loadApplications();
  }, []);

  return (
    <Page
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            {applications.map((application) => (
              <Grid
                item
                key={application.applicationId}
                lg={3}
                md={6}
                xs={12}
              >
                <AppCard
                  className={classes.AppCard}
                  application={application}
                />
              </Grid>            
            ))}
          </Grid>
        </Box>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          { applications.length > 8 ?<Pagination
            color="primary"
            count={Math.ceil(applications.length/8)}
            size="small"
          /> : <div/> }
        </Box>
      </Container>
    </Page>
  );
};

export default Applications;
