import React, { useEffect, useState, useRef, useReducer } from 'react';
import PropTypes from 'prop-types';

import FolderIcon from '@material-ui/icons/Folder';
import StorageIcon from '@material-ui/icons/Storage';
import PublishIcon from '@material-ui/icons/Publish';
import RefreshIcon from '@material-ui/icons/Refresh';
import Grid from '@material-ui/core/Grid';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {
  Paper,
  makeStyles,
  TableBody,
  CircularProgress,
  TableRow,
  TableCell,
  Toolbar,
  Box,
  Checkbox
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Page from 'src/components/utils/Page';
import PageHeader from 'src/components/utils/PageHeader';
import DataTable from '../../utils/DataTable';
import Notification from 'src/components/utils/Notification';
import ConfirmDialog from 'src/components/utils/ConfirmDialog';
import MaterialButton from 'src/components/utils/Button';
import { getAdminIconName } from '../../utils/RouterStorage';
import {
  humanFileSize,
  UploadProgressBar
} from '../../utils/BlobService';
import { getAutorenewedToken } from '../../auth/msalUtils';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.primary.greyColor})`,
      color: theme.palette.whiteColor.main
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label':
      {
        backgroundColor: 'transparent'
      }
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  }
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxheight: 300,
    flexGrow: 1,
    maxWidth: 400,
    paddingRight: '15px'
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  searchInput: {
    width: '25%'
  },
  newButton: {
    marginRight: '10px',
    fontSize: '14px',
    float: 'right'
  }
}));

const fileObjList = [
  {
    id: 0,
    value: 'fileId',
    label: 'ID',
    active: false,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 1,
    value: 'isSelected',
    label: 'Select file',
    active: true,
    checkbox: true,
    disableSorting: true
  },
  {
    id: 2,
    value: 'fileName',
    label: 'File name',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 3,
    value: 'uploadTime',
    label: 'Uploaded At',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 4,
    value: 'fileSize',
    label: 'Size',
    active: true,
    checkbox: false,
    disableSorting: false
  }
];

const UploadMediaView = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isReferesh, setIsRefresh] = useState(true);

  const [filesList, setFilesList] = useState([]);
  const [totalFilesList, setTotalFilesList] = useState({});
  const [dirNodeList, setDirNodeList] = useState([]);
  const [portalName, setPortalName] = useState('');
  const [defaultSelectedDir, setDefaultSelectedDir] =
  useState('/applications/');
const [currentSelectedDir, setCurrentSelectedDir] =
  useState('/applications/');
const [isDelDisabled, setIsDelDisabled] = useState(true);
const [selectedFiles, setSelectedFiles] = useState([]);

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: ''
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: ''
  });

  const activeHeaders = fileObjList.filter((header) => header.active);
  const expPortalName = ['/portal'];

  const TreeRender = (data, index) => {
    const isChildren = Array.isArray(data);
    if (isChildren) {
      return data.map((node, idx) =>
        TreeRender(node, index.toString() + idx.toString())
      );
    }
    return (
      <StyledTreeItem
        nodeId={data ? data : index}
        key={index}
        labelText={data ? data : ''}
        labelIcon={FolderIcon}
      />
    );
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(filesList, activeHeaders);

  async function getPortalAllowedPath() {
    return fetch(
      process.env.REACT_APP_API_PATH + "/storageaccount/portal/allowedpaths",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      const datavalue = data.join(";").toString();
      return datavalue;
    });
  }

  async function getPortalAllowedFileTypes() {
    return fetch(
      process.env.REACT_APP_API_PATH +
        "/storageaccount/portal/allowedfileformats",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  }

  const getFilesInfo = async (inDirName) => {
    setIsLoading(true);
    const allowedPath = await getPortalAllowedPath();
    setPortalName('/portal');
    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
        '/storageaccount/portal/getblobs?contianerName=' +
        allowedPath,
      {
        method: 'GET',
        headers: { authorization: await getAutorenewedToken() }
      }
    )
      .then(async (response) => {
        let containerList = {};
        const data = await response.json();
        setIsLoading(false);
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        setDefaultSelectedDir(inDirName ? inDirName : data[0].containerPath);
        setCurrentSelectedDir(inDirName ? inDirName : data[0].containerPath);
        for (let idx = 0; idx < data.length; idx++) {
          const mediaResponse = data[idx].blobList;
          if (mediaResponse.err)
            alert('Get file list error');
          containerList[data[idx].containerPath] = mediaResponse.map(
            (media) => ({
              fileName: media.name,
              uploadTime: new Date(media.uploadTime).toString(),
              filePath: media.url ? media.url : media.name,
              fileSize: humanFileSize(media.fileSize, true),
            })
          );
        }
        setTotalFilesList(containerList);
        setDirNodeList(Object.keys(containerList));
        setFilesList(
          inDirName
            ? containerList[inDirName]
            : containerList[data[0].containerPath]
        );
      })
      .catch((error) => {
        alert('There was an error!');
      });
  };

  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    getFilesInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshFileTable = () => {
    getFilesInfo(currentSelectedDir);
  };

  const handleDelFiles = async () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });

    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
        "/storageaccount/portal/delete?contianerName=" +
        encodeURIComponent(currentSelectedDir),
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: await getAutorenewedToken(),
        },
        body: JSON.stringify(selectedFiles.map((obj) => obj.fileName)),
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      getFilesInfo(currentSelectedDir);
      setSelectedFiles([]);
      setIsDelDisabled(true);
    });
    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'error'
    });
  };

  const [progressObj, dispatch] = useReducer((state, action) => {
    if (action.type === 'Delete') {
      delete state[action.value];
      return state;
    } else {
      return { ...state, ...action.value };
    }
  }, {});

  const updateProgress = (key, progress) => {
    dispatch({
      type: 'Update',
      value: { [key]: Math.round(progress * 1000) / 10 }
    });
  };

  const handleUpload = async (event) => {
    const uploadFiles = event.target.files;
    const fileFound = filesList.some((element) => {
      if (element.fileName === uploadFiles[0].name) {
        return true;
      }
      return false;
    });

    if (uploadFiles.length === 1) {
      if (!fileFound) {
        const allowedFileTypes = await getPortalAllowedFileTypes();
        if (allowedFileTypes.includes(uploadFiles[0].type)) {
          const authorization = await getAutorenewedToken();
          [...uploadFiles].forEach(async (file, idx) => {
            const formData = new FormData();
            formData.append("file", file);
            const headerReqest = {
              method: 'POST',
              headers:
              {
                authorization: authorization,
              },
              body: formData
            }
            fetch(
              process.env.REACT_APP_API_PATH +
                '/storageaccount/portal/upload?contianerName=' +
                encodeURIComponent(currentSelectedDir),
              headerReqest
            ).then(async (response) => {
              const data = await response.json();
              // check for error response
              if (!response.ok) {
                // get error message from body or default to response statusText
                setNotify({
                  isOpen: true,
                  message: 'Failed to upload data',
                  type: 'error',
                });
                const error = (data && data.message) || response.statusText;
                uploadInputRef.current.value = "";
                return Promise.reject(error);
              } else {
                dispatch({ type: 'Delete', value: file.name });
                getFilesInfo(currentSelectedDir);
                if(data.error) 
                {
                  setNotify({
                    isOpen: true,
                    message: 'Failed to upload data',
                    type: 'error',
                  });
                } 
                else {
                  setNotify({
                    isOpen: true,
                    message: 'Data uploaded Successfully',
                    type: 'success',
                  });
                }
              }
            });
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Please upload jpge or png image only",
            type: "error",
          });
        }
      } else {
        setNotify({
          isOpen: true,
          message: 'Failed to Upload as "File already Exists"',
          type: "error",
        });
      }
    } else {
      setNotify({
        isOpen: true,
        message: "Please upload one file at a time",
        type: "error",
      });
    }
    uploadInputRef.current.value = "";
  };

  const uploadInputRef = useRef();

  const handleDirTreeSelected = (inPath) => {
    if (totalFilesList && totalFilesList[inPath]) {
      setFilesList(totalFilesList[inPath]);
      setCurrentSelectedDir(inPath);
    }
  };

  const handleFileChecked = (inFileRow) => {
    const selectedIndex = selectedFiles.indexOf(inFileRow);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedFiles, inFileRow);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedFiles.slice(1));
    } else if (selectedIndex === selectedFiles.length - 1) {
      newSelected = newSelected.concat(selectedFiles.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedFiles.slice(0, selectedIndex),
        selectedFiles.slice(selectedIndex + 1)
      );
    }

    setSelectedFiles(newSelected);

    setIsDelDisabled(newSelected.length === 0);
  };

  const isFileSelected = (fRow) => selectedFiles.indexOf(fRow) >= 0;

  const handleSelectAllFiles = (checked) => {
    setSelectedFiles(checked ? filesList : []);
    setIsDelDisabled(!checked);
  };

  return (
    <Page title="File Explorer">
      <PageHeader
        title="File Explorer"
        subtitle="View, add or remove files"
        icon={<FontAwesomeIcon icon={['fas', getAdminIconName()]} />}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <MaterialButton
            text="Refresh"
            variant="outlined"
            startIcon={<RefreshIcon />}
            className={classes.newButton}
            onClick={() => {
              refreshFileTable();
            }}
          />
          <input
            ref={uploadInputRef}
            type="file"
            multiple={false}
            accept='image/*'
            onChange={(e) => {
              handleUpload(e);
            }}
            style={{ display: 'none' }}
          />
          <MaterialButton
            text="Upload"
            variant="outlined"
            startIcon={<PublishIcon />}
            className={classes.newButton}
            onClick={() =>
              uploadInputRef.current && uploadInputRef.current.click()
            }
          />
          <MaterialButton
            text="Delete"
            variant="outlined"
            startIcon={<DeleteIcon />}
            className={classes.newButton}
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure you want to delete this file?",
                subtitle: "You can't undo this operation",
                onConfirm: () => {
                  handleDelFiles();
                },
              })
            }}
            disabled={isDelDisabled}
          />
        </Toolbar>
        <UploadProgressBar progress={progressObj} />
        <Grid container>
          <Grid item xs={2}>
            <TreeView
              className={classes.root}
              defaultExpanded={expPortalName}
              defaultSelected={defaultSelectedDir}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              onNodeSelect={(event, value) => {
                handleDirTreeSelected(value);
              }}
            >
              <StyledTreeItem
                nodeId={portalName}
                labelText={portalName}
                labelIcon={StorageIcon}
              >
                {TreeRender(dirNodeList, 1)}
              </StyledTreeItem>
            </TreeView>
          </Grid>
          {isLoading && (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          )}
          <Grid item xs={10}>
            {filesList !== null && !isLoading ? (
              <>
                <TblContainer>
                  <TblHead handleHeadClicked={handleSelectAllFiles} />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((row, idx) => (
                      <TableRow
                        hover
                        key={idx}
                        value={row}
                        onClick={() => {
                          handleFileChecked(row);
                        }}
                      >
                        {activeHeaders.map((col) =>
                          col.checkbox === true ? (
                            <TableCell key={col.id}>
                              <Checkbox
                                inputProps={{ 'aria-labelledby': row.fileName }}
                                checked={isFileSelected(row)}
                              />
                            </TableCell>
                          ) : (
                            <TableCell key={col.id}>{row[col.value]}</TableCell>
                          )
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <br></br>

                <TblPagination />
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Page>
  );
};

export default UploadMediaView;
