import React from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Page from "src/components/utils/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    color: theme.palette.primary.main + " !important",
    fontWeight: "700",
    fontSize: "135px",
    marginTop: "0px !important",
  },
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        bgcolor="white"
        mt={7}
        pt={4}
      >
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={8}>
              <Box mb={5}>
                <Typography align="left" color="textPrimary" variant="h4">
                  Sorry! The page you requested cannot be found.
                </Typography>
              </Box>
              {/* <Typography
              align="left"
              color="textPrimary"
              variant="subtitle2"
            >
              You either tried some shady route or you came here by mistake.
              Whichever it is, please try using the navigation.
            </Typography> */}
            </Grid>
            <Grid item xs={4}>
              <Box pl={5} textAlign="right">
                <Typography className={classes.image} variant="h1">
                  404
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
