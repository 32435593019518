import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
import MuiButton from '../utils/Button';
import PageHeader from '../utils/PageHeader';
import Page from '../utils/Page';
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import TemplateUploadView from "../template_upload/TemplateUpload";
import { getAutorenewedToken } from '../auth/msalUtils';
import axios from "axios";
import ConnectionTypeView from './ConnectionTypeView';
import ConnectionForm from './ConnectionForm';
import DataMapping from './DataMapping';

const ConnectionTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#86BC25',
  },
})(Tabs);

const ConnectionTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#86BC25',
      opacity: 1,
    },
    '&$selected': {
      color: '#86BC25',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#86BC25',
    },
  },
  selected: {},
  disabled:{}
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 18,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
}));

interface ValidateProject{
  ProjectsExits:boolean
  WbsExits:boolean
  ContractPackageExits:boolean
}
export default function ConnectionsStepperView() {
  const classes = useStyles();
  const [currStep, setCurrStep] = React.useState(0);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<string>("");
  const [linkedServiceId, setLinkedServiceId] = useState<string>("");
  const apiPath = process.env.REACT_APP_API_PATH;

  const handleChange = (event, newValue) => {
   setCurrStep(newValue)
  };

  const handleConntionTypeSelection =(id:number, type:string) => {
    setSelectedId(id);
    setSelectedType(type);
    setCurrStep(currStep+1);
  }
  
  const handleNextStep =(id:number) => {
    setCurrStep(id)
  }
  
  const handleLinkedServiceId =(id:string) => {
    setLinkedServiceId(id)
  }
  
  
  return (
      <Page title="Data Upload">
          <PageHeader
              title="Data Upload"
              subtitle="Upload Data"
              icon={<GroupRoundedIcon className={classes.icon} forntsize="large" />}
              disableBackButton={true}
          />
          <Paper className={classes.pageContent}>
              <div className={classes.root}>
                  <div>
                      <ConnectionTabs value={currStep} onChange={handleChange} aria-label="ant example">
                          <ConnectionTab disabled={true} label="Step:1 Select Connection" />
                          <ConnectionTab disabled={true} label="Step:2 Connect" />
                          <ConnectionTab disabled={true}label="Step:3 Data Mapping" />  
                      </ConnectionTabs>
                      <Typography className={classes.padding} />
                  </div>
              </div>
              {currStep == 0 && <ConnectionTypeView type={handleConntionTypeSelection}></ConnectionTypeView>}
              {currStep == 1 && <ConnectionForm id={selectedId} type={selectedType} nextStep={handleNextStep} linkedServiceId={handleLinkedServiceId}></ConnectionForm>}
              {currStep == 2 && <DataMapping sourceConnection={selectedType} linkedServiceId={linkedServiceId}></DataMapping>}
              
          </Paper>

      </Page>
  );
}   