import React, { useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Page from "src/components/utils/Page";
import Toolbar from "./FilterToolbar";
import DashboardCard from "./DashboardCard";
import { getUserFirstName, getAutorenewedToken, getUserName } from "../auth/msalUtils";
import { useGet } from "src/hooks";
import { number } from "prop-types";
import { useNavigate } from "react-router";
import Emitter from "../utils/Emitter";
import DisplayPowerBIPage from "../dashboards/MultiPowerBI.jsx";

import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    //@ts-ignore
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  dashboardCard: {
    height: "100%",
  },
}));
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ? process.env.REACT_APP_MIXPANEL_TOKEN : "")
mixpanel.track_pageview({user_id:window.sessionStorage.getItem('USER_ID')});

const Greeting = () => {
  var greeting = "Hello";
  const today = new Date();
  const curHr = today.getHours();
  const { accounts } = useMsal();
  const fullName =
    accounts && accounts?.length > 0
      ? accounts[0]?.name?.substring(accounts[0]?.name.indexOf(",") + 1)
      : getUserFirstName();

  if (curHr < 12) {
    greeting = "Good Morning";
  } else if (curHr < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }
  return (
    <Typography color="textPrimary" display="inline" variant="h4">
      {" "}
      {greeting} {fullName}
    </Typography>
  );
};
//NEW CONTENT

function HomePageView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [lastFilter, setLastFilter] = useState("");

  const { data: Categories } = useGet("FUNCTIONS_DASHBOARDS");
  const { data: dashboards } = useGet("DASHBOARDS_ACTIVE");

  const activeDashboards = dashboards ? dashboards : [];
  

  const defaultCategory = (Categories as any)?.defaultResourceCategory
    ?.resourceCategoryName;
  const defaultDashboardID = (Categories as any)?.defaultResourceCategory
    ?.defaultDashboardId;

  const prevFilter = useRef("");
  useEffect(() => {
    prevFilter.current = filter !== null ? filter : "";
  }, [filter]);



  const filterDefaultDashboardValues  = activeDashboards?.filter((item: any) => item.dashboardId === Number(defaultDashboardID));

  const filterRouteValue = filterDefaultDashboardValues?.map((item: any) => {
    return item.route;
  });

  const filterRoutePath = filterRouteValue?.map((item: any) => {
    return item.routePath;
  });

  const defaultFilter =
    filter === "" && prevFilter.current === "" && lastFilter == ""? defaultCategory : filter;

  const uniqueCategories = (Categories as any)?.resourceCategories;
  const idMappedCategories = uniqueCategories?.map(
    (item: any) => item?.resourceCategoryId
  );
  const filteredbyitem = idMappedCategories?.filter(
    (item: any, idx: number, array: Array<any>) =>
      !!(array?.indexOf(item) === idx)
  );
  const categoryMappedItems = filteredbyitem?.map((item: any) => {
    return (Categories as any)?.resourceCategories?.find((cat: any) => {
      return cat?.resourceCategoryId === item;
    });
  });

  const activeDashboardsFiltered = activeDashboards?.filter((item: any) =>
    defaultFilter
      ? item?.resourceCategory?.resourceCategoryName === defaultFilter
      : true
  );

  const activeDashboardsSorted = activeDashboardsFiltered?.sort(
    (a: any, b: any) => a?.dashboardDisplayOrder - b?.dashboardDisplayOrder
  );

  const activeDashboardsMapped = activeDashboardsSorted?.map(
    (dashboard: any) => (
      <Grid item key={dashboard.dashboardId} lg={3} md={6} xs={12}>
        <DashboardCard
          title={dashboard?.dashboardName}
          subtitle={dashboard?.dashboardDescription}
          onClick={() => {
            Emitter.emit("SIDEBAR_CLICKED", dashboard.dashboardName);
            return navigate(dashboard.route.routePath);
          }}
          imageUrl={dashboard?.dashboardImageUrl}
        />
      </Grid>
    )
  );

  const powerBIDashbaord = () => {
    if(filterRoutePath?.length === 0 ? null : filterRoutePath?.toString() !== undefined)
    {
      const propvalue = filterRoutePath?.length === 0 ? null : filterRoutePath?.toString();
      return <DisplayPowerBIPage value={propvalue} />
    }
  }

  return (
    // @ts-ignore
    <Page title="Home" className={classes.root}>
      { Number(defaultDashboardID) !== 0 ? ( 
      <Container maxWidth={false}>
        <Greeting />
        <div>{powerBIDashbaord()}</div>
        </Container>
        ) : activeDashboards ? (
          <Container maxWidth={false}>
          <Greeting />
          <Toolbar
            areaClicked={(area: string) => setFilter(area)}
            prevAreaClicked={(area: string) => setLastFilter(area)}
            defaultCategory={defaultCategory}
            areasList={categoryMappedItems}
          />
          <Box mt={4}>
            <Typography color="textSecondary" variant="body1">
              Dashboards
            </Typography>
            <Card style={{boxShadow:"none" }}>
              <CardContent>
                <Grid container spacing={3}>
                  {activeDashboards && activeDashboardsMapped}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>
      ) : (
        <div />
      )}
    </Page>
  );
}
export default HomePageView;
