import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  TableContainer,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import DataTable from "../../utils/DataTable";
import Searchbar from "src/components/utils/Searchbar";
import { getAutorenewedToken } from "../../auth/msalUtils";
import { getAdminIconName } from "../../utils/RouterStorage";
import { useGet } from "src/hooks";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
}));

const feedbackConfig = [
  {
    id: 0,
    value: "feedbackId",
    label: "Feedback ID",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 1,
    value: "subject",
    label: "Subject",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 2,
    value: "userFeedback",
    label: "Feedback",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 3,
    value: "createdBy",
    label: "Created By",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 4,
    value: "createdOn",
    label: "Created On",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 5,
    value: "url",
    label: "URL",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
];

const UpdateFeedbackView = () => {
  const { data, refetch } = useGet<any>("FEEDBACK");

  const classes = useStyles();
  // const [filterFn, setFilterFn] = useState({
  //   fn: (items: typeof feedbackConfig) => {
  //     return items;
  //   },
  // });

  const activeHeaders = feedbackConfig.filter((header) => header.active);

  const [errorMessage, setErrorMessage] = useState("");

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(data, activeHeaders);

  const handleSearch = (e: any) => {
    let target = e.target;
    // setFilterFn({
    //   fn: (items) => {
    //     if (target.value === "") {
    //       return items;
    //     } else {
    //       return items.filter((data) =>
    //         Object.values(data).some((val) =>
    //           String(val)
    //             .toLowerCase()
    //             .includes(target.value.toString().toLowerCase())
    //         )
    //       );
    //     }
    //   },
    // });
  };

  return (
    <Page title="View Feedback">
      <PageHeader
        title="View Feedback"
        subtitle="View, Update user feedback"
        // @ts-ignore
        icon={<FontAwesomeIcon icon={["fas", getAdminIconName()]} />}
        backButton={true}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <Searchbar handleSearch={handleSearch} title="Search" />
        </Toolbar>
        {data && data.length > 0 ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting()?.map((row: any) => (
                  <TableRow hover key={row["feedbackId"]}>
                    {" "}
                    {/* value={row} */}
                    {activeHeaders?.map((col) => (
                      <TableCell key={col.id}>{row[col.value]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <br></br>
            <TblPagination />
          </>
        ) : (
          <TableContainer></TableContainer>
        )}
        <p>{errorMessage}</p>
      </Paper>
    </Page>
  );
};

export default UpdateFeedbackView;
