import React from "react";
import { TextField, FormControl, FormHelperText, Box } from "@material-ui/core";
import { Field, FormikProvider, useField, ErrorMessage } from "formik";
import { Validation } from "src/types/config";

interface FieldInput {
  field: any;
  form: any;
  meta: any;
}
interface InputProps {
  label: string;
  name: string;
  validation?: Validation;
  error?: any;
}

export default function Input(props: InputProps) {
  const { name, label, error = null, validation } = props;
  return (
    <Box
      sx={{
        marginTop: "5%",
        minWidth: "75%",
      }}
    >
      <Field name={name} validation={validation}>
        {({ field, form, meta }: FieldInput) => (
          <TextField
            {...field}
            error={validation && validation?.validation(field.value)}
            helperText={validation && validation?.validation(field.value)}
            style={{}}
            name={name}
            label={label}
            // margin="dense"
          />
        )}
        {/* <ErrorMessage name={name} /> */}
      </Field>
    </Box>
  );
}
