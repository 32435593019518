import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CheckIcon from "@material-ui/icons/Check";
import { getAutorenewedToken } from '../auth/msalUtils';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ColumnMapping from './ColumMapping';

const DestinationTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#86BC25',
    },
  })(Tabs);
  
  const DestinationTab = withStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row', // Align items horizontally
      alignItems: 'center', // Center content vertically
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#86BC25',
        opacity: 1,
      },
      '&$selected': {
        color: '#86BC25',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#86BC25',
      },
    },
    wrapper:{
      flexDirection:'row'
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);
  
interface DestinationColumnTabProps
{
    dashboardId:number
    linkedServiceId:string
    sourceConnection:string
}
interface DataSet
 {
    dataSetId:number
    dataSet:string
    mappingCompleted:boolean
 }
const DestinationColumnTab = (props: DestinationColumnTabProps) => {
 const [dataSet, setDataSet] = useState<DataSet[]>([]);
 const [selectedTable, setSelectedTable] = useState<string>();
 const [selectedTableId, setSelectedTableId] = useState<number>();
 const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    var tbl = dataSet[newValue]
    setSelectedTable(tbl.dataSet);
    setSelectedTableId(tbl.dataSetId);
    setValue(newValue);
  };

  const getDestinationTables = async () => {

    fetch(process.env.REACT_APP_API_PATH + "/dataconfiguration/dataSet?linkedServiceId="+ props.linkedServiceId + "&dashboardId="+props.dashboardId, {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() }
    }).then(async (response) => {
        const data = await response.json();
        setLoading(false);
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        setDataSet(data);
    }).catch((error) => {
            setLoading(false);
    });
} 
useEffect(() => {
    if(dataSet.length > 0)
    {
        var tbl = dataSet[0]
        setSelectedTable(tbl.dataSet);
        setSelectedTableId(tbl.dataSetId);
    }
},[dataSet])

useEffect(() => {
    getDestinationTables()
    setValue(0)
},[props.dashboardId])

 
const handleMappingCompleted =(mappingCompleted:boolean) => {

  setDataSet(prevItems =>
    prevItems.map(item =>
    item.dataSetId ===selectedTableId ? { ...item, mappingCompleted:mappingCompleted} : item
    )
)
}

  return (
    <div>
      <DestinationTabs value={value} onChange={handleChange} indicatorColor="primary">
        {dataSet.map((tab,index) => (
           <DestinationTab key={index} icon={tab.mappingCompleted ?<CheckIcon style={{marginRight:10 , color:"#86BC25"}} />:""} label={tab.dataSet} />
        ))}
        
      </DestinationTabs>
      <ColumnMapping linkedServiceId={props.linkedServiceId} sourceConnection={props.sourceConnection} 
                    destinationTable={selectedTable} destinationTableId={selectedTableId} mappingCompleted={handleMappingCompleted}></ColumnMapping>
    </div>
  );
};

export default DestinationColumnTab;