import React, { forwardRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

interface PageProps {
  children: ReactNode;
  title: string;
}
const Page = forwardRef(
  ({ children, title = "", ...rest }: PageProps, ref: any) => {
    return (
      <div ref={ref} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);




export default Page;