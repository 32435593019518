import {
  FormControl,
  FormControlLabel,
  InputLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
  Box,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";
import React from "react";
import { Field } from "formik";

export default function Checkbox(props: any) {
  const { name, label, error, ...other } = props;
  return (
    <Box
      sx={{
        marginTop: "8%",
      }}
    >
      <FormControl>
        <FormLabel>
          <FormGroup>
            <FormControlLabel
              label={label}
              control={
                <Field
                  name={name}
                  as={MuiCheckbox}
                  type="checkbox"
                  color="primary"
                />
              }
            />
          </FormGroup>
        </FormLabel>
      </FormControl>
    </Box>
  );
}
