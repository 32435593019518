import UpdateContainer from "../UpdateContainer";
import { Config } from "../../../types/config";
import { useGet } from "../../../hooks";

const initialValues = {
  sidebarId: 0,
  sidebarName: "",
  sidebarParent: null,
  routePath: null,
  sidebarIconName: "",
  sidebarOrder: null,
};

const sidebarConfig: Config[] = [
  {
    name: "sidebarId",
    value: (state) => state?.sidebarId,
    label: "Sidebar ID",
    active: true,
    type: "id",
  },
  {
    name: "sidebarName",
    value: (state) => state?.sidebarName,
    label: "Tab Name",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Tab Name is required" : undefined,
    },
  },
  {
    name: "sidebarIconName",
    value: (state) => state?.sidebarIconName,
    label: "Icon",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) => (!value ? "Icon is required" : undefined),
    },
  },
  {
    name: "sidebarParent",
    value: (state) => state?.sidebarParent,
    label: "Sidebar Parent",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Sidebar Parent is required" : undefined,
    },
  },
  {
    name: "sidebarOrder",
    value: (state) => state?.sidebarOrder,
    label: "Sidebar Order",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Sidebar Order is required" : undefined,
    },
  },
  {
    name: "routePath",
    value: (state) => state?.routePath,
    label: "Sidebar Route",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) => (!value ? "Route is required" : undefined),
    },
  },
];

const UpdateSidebarView = () => {
  const reducer = (data: typeof initialValues) => {
    const route = data.routePath;
    return { ...data, route };
  };
  const { data, refetch } = useGet("SIDEBAR");

  return (
    <UpdateContainer
      name="sidebar"
      title="Sidebar details"
      subtitle="View, Add or Update sidebar items"
      endpoint="SIDEBAR"
      idName="sidebarId"
      config={sidebarConfig}
      initialValues={initialValues}
      skip={["resourceCategoryName"]}
      data={data}
      refetch={refetch}
      reducer={reducer}
    />
  );
};

export default UpdateSidebarView;
