import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AppsIcon from '@material-ui/icons/Apps';
import PublishIcon from '@material-ui/icons/Publish';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import SettingIcon from "@material-ui/icons/Settings";
import BuildIcon from "@material-ui/icons/BuildRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import securityIcon from '@material-ui/icons/SecurityOutlined';

export function getTabNameFromID(inTabList, inId) {
  const sideBarInfo = inTabList.filter(t=>t.sidebarId === inId)
  return sideBarInfo[0] ? sideBarInfo[0].sidebarName : '';
}

export function generateIconCode(inIconName){
  switch(inIconName.toString().toLowerCase()){
      case 'home icon':
      case 'home': 
        return <HomeOutlinedIcon/>;
      case 'dashboard icon':
      case 'users' :
        return <DashboardIcon/>;
      case 'assessment icon':
        return <AssessmentOutlinedIcon/>;
      case 'apps icon':
      case 'rocket':
        return <AppsIcon/>;
      case 'publish icon':
      case 'archive':
        return <PublishIcon/>;
      case 'assignment icon':
        return <AssignmentOutlinedIcon/>;
      case 'build-icon':
          return <FontAwesomeIcon icon={["fas", "tools"]} style={{marginLeft:4}}/>;
      case 'security':
          return <securityIcon/>;
      case 'supervisor account icon':
      case 'user-cog':
        return <SupervisorAccountOutlinedIcon/>;
      case 'settings':
          return <SettingIcon/>;
      default:
        return '';
  }
}
