import React, { useState, useEffect, useRef } from "react";
import { styled, makeStyles } from "@material-ui/styles";
import { TextField, IconButton } from "@material-ui/core";
import NavigationRoundedIcon from "@material-ui/icons/NavigationRounded";

interface chatInputProps {
  handleEnterMessage: (e: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement> ) => void;
  messageInputRef: React.RefObject<HTMLInputElement>;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    minHeight: "55px",
    height: "fit-content",
    backgroundColor: "white",
    boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.5)",
    borderRadius: "20px",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    display: "flex",
    paddingLeft: "20px",
    zIndex: 1,
    bgcolor: "transparent",
  },
  inputContainer: {
    width: "93%",
    paddingBottom: "12px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
}));

function ChatInput(props : chatInputProps) {
  const [iconColour, setIconColour] = useState("#26890D");
  const [message, setMessage] = useState('');
  const classes = useStyles();
  const rotateIconStyle = {
    transform: "rotate(90deg)",
    color: iconColour,
  };

  const checkColour = (e: HTMLInputElement) => 
  {
    setIconColour(e.target.value.length >= 1 ? "#26890D" : "#e0e0e0" )
  }

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <TextField
          id="standard-basic"
          variant="standard"
          fullWidth
          InputProps={{ disableUnderline: true }}
          placeholder="Enter your message..."
          onKeyPress={props.handleEnterMessage}
          inputRef={props.messageInputRef}
          //onChange={(e) => setMessage(e.target.value)}
          multiline
        />
      </div>

      <IconButton onClick={props.handleEnterMessage} style={rotateIconStyle}>
        <NavigationRoundedIcon fontSize="large" />
      </IconButton>
    </div>
  );
}
export default ChatInput;
