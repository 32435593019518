import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import UpdateContainer from "../UpdateContainer";
import { useGet } from "../../../hooks";
import { Config } from "../../../types/config";

const securityGroupConfig: Config[] = [
  {
    name: "securityGroupId",
    value: (state) => state?.securityGroupId,
    label: "ID",
    active: true,
    type: "id",
    validation: {
      validation: (value: string) => (!value ? "Id is required" : undefined),
    },
  },
  {
    name: "securityGroupName",
    value: (state) => state?.securityGroupName,
    label: "Group Name",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) => (!value ? "Name is required" : undefined),
    },
  },
  {
    name: "securityGroupDescription",
    value: (state) => state?.securityGroupDescription,
    label: "Description",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Description is required" : undefined,
    },
  },
  {
    name: "securityGroupActive",
    value: (state) => state?.securityGroupActive,
    label: "Active",
    active: true,
    type: "checkbox",
  },
];

const initialValues = {
  securityGroupId: 0,
  securityGroupName: "",
  securityGroupDescription: "",
  securityGroupActive: false,
};

const UpdateSecurityGroup = () => {
  const reducer = (data: typeof initialValues) => {
    return data;
  };
  const { data, refetch } = useGet("SECURITY_GROUPS");
  return (
    <UpdateContainer
      name="security group"
      title="Update security groups"
      subtitle="View, Add or Update security groups"
      endpoint="SECURITY_GROUPS"
      idName="securityGroupId"
      config={securityGroupConfig}
      // skip={['routePath', 'resourceCategoryName', 'routeActive']}
      initialValues={initialValues}
      data={data}
      refetch={refetch}
      reducer={reducer}
    />
  );
};

export default UpdateSecurityGroup;
