import React, { useEffect, useState } from "react";

import {
  Paper,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import MaterialButton from "src/components/utils/Button";
import DataTable from "../../utils/DataTable";
import Notification from "src/components/utils/Notification";
import Searchbar from "src/components/utils/Searchbar";
import { getAutorenewedToken } from "../../auth/msalUtils";
import { getAdminIconName } from "../../utils/RouterStorage";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
  iconButton: {
    color: theme.palette.grey[500],
  },
}));

const UpdateUserSecurity = () => {
  const classes = useStyles();

  const uniqueId = "userId";
  const tableName = "User Security Group";
  const pageHeader = "Update User Security Group";
  const pageSubtitle = "Add, update or remove security groups among users";

  const [records, setRecords] = useState(null);
  const [recordsSelectedList, setRecordsSelectedList] = useState([]);
  const [securityRecords, setSecurityRecords] = useState(null);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const activeHeaders = [
    {
      id: 0,
      value: "id",
      label: "ID",
      active: false,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 1,
      value: "arrow",
      label: "",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 2,
      value: "userId",
      label: "User ID",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 3,
      value: "userName",
      label: "Username",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 4,
      value: "userFirstName",
      label: "First Name",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 5,
      value: "userLastName",
      label: "Last Name",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
  ].filter((header) => header.active);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, filterFn);

  let updatedUserGroup = {};
  // GET request using fetch with error handling
  const fetchData = async (isInit = false) => {
    // Get data from combined API
    fetch(process.env.REACT_APP_API_PATH + "/usersecurity/userscombined", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        // let mixedData = [];
        const activeData = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (activeData && activeData.message) || response.statusText;
          return Promise.reject(error);
        }
        setRecords(activeData.users);
        if (isInit) setRecordsSelectedList(activeData.users.map((x) => false));
        let securityGroup = activeData.securityGroups.map(sec => ({
          ...sec,
          reader:false,
          writer:false,
          approver:false
        }))
        setSecurityRecords(securityGroup);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const contentComparer = (otherArray) => {
    return (current) => {
      return (
        otherArray.filter(function (other) {
          return other.securityGroupId === current.securityGroupId;
        }).length === 0
      );
    };
  };
  
  const contentComparerforUpdate = (otherArray) => {
    return (current) => {
      const match = otherArray.find((other) => other.securityGroupId === current.securityGroupId);
      if (match) {
        // Return true if any of the relevant fields are different
        return (
          match.reader !== current.reader ||
          match.writer !== current.writer ||
          match.approver !== current.approver
        );
      }
      // If no match is found, it indicates a difference
      return true;
    };
  };
// Removed previous code for individually updating the user security
  
  const updateGroup = async (inNewList, inDelList, inUpdateList, apiToken) => {
    try {
      // DELETE request to remove user security groups
      if (inDelList.length > 0) {
        const deleteResponse = await fetch(process.env.REACT_APP_API_PATH + "/usersecurity", {
          method: "DELETE",
          headers: { "Content-Type": "application/json", authorization: apiToken },
          body: JSON.stringify(inDelList),
        });
  
        if (!deleteResponse.ok) {
          const errorData = await deleteResponse.json();
          throw new Error(errorData.message || `Error: ${deleteResponse.status}`);
        }
      }
  
      // POST request to add new user security groups
      if (inNewList.length > 0) {
        const postResponse = await fetch(process.env.REACT_APP_API_PATH + "/usersecurity", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: apiToken,
          },
          body: JSON.stringify(inNewList),
        });
  
        if (!postResponse.ok) {
          const errorData = await postResponse.json();
          throw new Error(errorData.message || `Error: ${postResponse.status}`);
        }
      }
  
      // PUT request to update existing user security groups
      if (inUpdateList.length > 0) {
        const putResponse = await fetch(process.env.REACT_APP_API_PATH + "/usersecurity", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: apiToken,
          },
          body: JSON.stringify(inUpdateList),
        });
  
        if (!putResponse.ok) {
          const errorData = await putResponse.json();
          throw new Error(errorData.message || `Error: ${putResponse.status}`);
        }
      }
  
      // If any API calls were made, fetch the updated data and show success notification
      if (inDelList.length > 0 || inNewList.length > 0 || inUpdateList.length > 0) {
        fetchData();
        setNotify({
          isOpen: true,
          message: "Submitted Successfully",
          type: "success",
        });
      }
  
    } catch (error) {
      console.error("An error occurred during the API requests:", error.message);
    }
  };

  // Removed previous code for individually updating the user security

  const updateSecurityGroup = async () => {
    
    // Get the add and delete group list
    let newSecurityGrp = [],
      updateSecurityGrp =[],
      delSecurityGrp = [];

    records.forEach((item) => {
      if (updatedUserGroup[item.userId]) {
        delSecurityGrp = delSecurityGrp.concat(
          item.userSecurityGroups
            .filter(contentComparer(updatedUserGroup[item.userId]))
            .map((x) => {
              return {
                userId: x.userId,
                securityGroupId: x.securityGroupId,
                userSecurityGroupId: x.userSecurityGroupId,
                reader:x.reader,
                writer:x.writer,
                approver:x.approver
              };
            })
        );

        updateSecurityGrp= updateSecurityGrp.concat(
          item.userSecurityGroups
            .filter(contentComparerforUpdate(updatedUserGroup[item.userId]))
            .map((x) => {
              const updatedItem = updatedUserGroup[item.userId].find(
                (y) => y.securityGroupId === x.securityGroupId
              );

              return {
                userId: x.userId,
                securityGroupId: x.securityGroupId,
                userSecurityGroupId: x.userSecurityGroupId,
                reader: updatedItem ? updatedItem.reader : x.reader,
                writer: updatedItem ? updatedItem.writer : x.writer,
                approver: updatedItem ? updatedItem.approver : x.approver
              };
            })
        );

        newSecurityGrp = newSecurityGrp.concat(
          updatedUserGroup[item.userId]
            .filter(contentComparer(item.userSecurityGroups))
            .map((y) => {
              return {
                userId: y.userId,
                securityGroupId: y.securityGroupId,
                userSecurityGroupId: 0,
                reader:y.reader,
                writer:y.writer,
                approver:y.approver
              };
            })
        );
      }
    });
   
    updateSecurityGrp = updateSecurityGrp.filter(del => 
      !delSecurityGrp.some(upd => upd.userSecurityGroupId === del.userSecurityGroupId)
    );

    // Run API to reflect the change
    const apiToken = await getAutorenewedToken();
    if (newSecurityGrp.length > 0 || delSecurityGrp.length > 0 || updateSecurityGrp.length > 0) {
      updateGroup(newSecurityGrp, delSecurityGrp, updateSecurityGrp, apiToken);
    } else {
      setNotify({
        isOpen: true,
        message: "No updated user security group",
        type: "success",
      });
    }
  };

  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    fetchData(true);
  }, []);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((data) =>
            Object.values(data).some((val) =>
              String(val)
                .toLowerCase()
                .includes(target.value.toString().toLowerCase())
            )
          );
        }
      },
    });
  };

  function CollapseTableRow(props) {
    const row = props.value;
    const currentUserID = props.rowId;
    const [open, setOpen] = useState(recordsSelectedList[props.selectedIdx]);
    const [selected, setSelected] = useState([]);

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        // Find elements in `securityRecords` that are not in `selected`
        const newRecords = securityRecords.filter(
          (securityRecord) =>
            !selected.some((selectedItem) => selectedItem.securityGroupId === securityRecord.securityGroupId)
        );
        // Combine the existing `selected` items with the new ones
        const updatedSelection = [...selected, ...newRecords];
    
        // Update `setSelected` with the combined list
        setSelected(updatedSelection);
        updatedUserGroup[currentUserID] = updatedSelection.map((item) => {
          return { userId: currentUserID, securityGroupId: item.securityGroupId, reader: item.reader, writer: item.writer, approver: item.approver };
        });
      } else {
        setSelected([]);
        updatedUserGroup[currentUserID] = []
      }
    };

    
    const handleClick = (gRow) => {
      let newSelected = [];
    
      // Check if the item is already selected
      const isSelected = selected.some(item => item.securityGroupId === gRow.securityGroupId);
    
      if (isSelected) {
        // If selected, remove the item from the selection
        newSelected = selected.filter(item => item.securityGroupId !== gRow.securityGroupId);
      } else {
        // If not selected, add the item to the selection
        newSelected = selected.concat(gRow);
      }
    
      // Update the state with the new selection
      setSelected(newSelected);
    
      // Update the updatedUserGroup with the new selection
      updatedUserGroup[currentUserID] = newSelected.map((item) => {
        return { userId: currentUserID, securityGroupId: item.securityGroupId, reader: item.reader, writer: item.writer, approver: item.approver };
      });
    };
    

    const isItemSelected = (gRow) => { 
      return selected.find(x => x.securityGroupId == gRow.securityGroupId)?true:false;}

    const isReaderSelected = (gRow) =>  {
     let sel =  selected.find(x => x.securityGroupId == gRow.securityGroupId)
      return sel?.reader ?? false
    }
    const isWriterSelected = (gRow) =>  {
      let sel =  selected.find(x => x.securityGroupId == gRow.securityGroupId)
       return sel?.writer ?? false
     }
     const isApproverSelected = (gRow) =>  {
      let sel =  selected.find(x => x.securityGroupId == gRow.securityGroupId)
       return sel?.approver ?? false
     }
   
  
    const handleReaderClick = (gRow) => {
      // Find the selected object based on securityGroupId
      const newSelected = selected.map((item) => {
        if (item.securityGroupId === gRow.securityGroupId) {
          // Return a new object with reader set to true
          return { ...item, reader: !item.reader };
        }
        // Return the item unchanged if it's not the one we're looking for
        return item;
      });
    
      // Update the state with the modified array
      setSelected(newSelected);

      updatedUserGroup[currentUserID] = newSelected.map((item) => {
        return { userId: currentUserID, securityGroupId: item.securityGroupId, reader:item.reader, writer:item.writer, approver:item.approver };
      });
    };
    const handleWriterClick = (gRow) => {
      // Find the selected object based on securityGroupId
      const newSelected = selected.map((item) => {
        if (item.securityGroupId === gRow.securityGroupId) {
          // Return a new object with reader set to true
          return { ...item, writer: !item.writer };
        }
        // Return the item unchanged if it's not the one we're looking for
        return item;
      });
    
      // Update the state with the modified array
      setSelected(newSelected);
      updatedUserGroup[currentUserID] = newSelected.map((item) => {
        return { userId: currentUserID, securityGroupId: item.securityGroupId, reader:item.reader, writer:item.writer, approver:item.approver };
      });
    };
    const handleApproverClick = (gRow) => {
      // Find the selected object based on securityGroupId
      const newSelected = selected.map((item) => {
        if (item.securityGroupId === gRow.securityGroupId) {
          // Return a new object with reader set to true
          return { ...item, approver: !item.approver };
        }
        // Return the item unchanged if it's not the one we're looking for
        return item;
      });
    
      // Update the state with the modified array
      setSelected(newSelected);
      updatedUserGroup[currentUserID] = newSelected.map((item) => {
        return { userId: currentUserID, securityGroupId: item.securityGroupId, reader:item.reader, writer:item.writer, approver:item.approver };
      });
    };
      

    const toggleSecTable = (inFlag) => {
      setOpen(!inFlag);
      recordsSelectedList[props.selectedIdx] = !inFlag;
    };

    
    

    useEffect(() => {
      let newSelected = [];
      if (row && row.userSecurityGroups) {
        row.userSecurityGroups.forEach((item) => {
          let security = securityRecords.find(
            (x) => x.securityGroupId === item.securityGroupId
          );
          // If security exists, keep it selected
          if (security) {
            const updatedSecurity = {
              ...security,
              approver: item.approver,
              reader: item.reader,
              writer: item.writer,
            };
            newSelected.push(updatedSecurity);
          }
        });
        setSelected(newSelected);
      }
    }, [row]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <React.Fragment>
        <TableRow hover>
          {activeHeaders.map((col) =>
            col.value === "arrow" ? (
              <TableCell key={col.id} onClick={() => toggleSecTable(open)}>
                <IconButton aria-label="expand row">
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            ) : (
              <TableCell key={col.id} onClick={() => toggleSecTable(open)}>
                {row[col.value]}
              </TableCell>
            )
          )}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Security Group
                </Typography>
                <Table size="small" aria-label="securitygroup">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected && selected.length < securityRecords.length
                          }
                          checked={
                            selected &&
                            selected.length === securityRecords.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{ "aria-label": "select all groups" }}
                        />
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Reader</TableCell>
                      <TableCell>Writer</TableCell>
                      <TableCell>Approver</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {securityRecords.map((groupRow) => (
                      <TableRow
                        key={groupRow.securityGroupId}
                        
                      >
                        <TableCell component="th" scope="row">
                          <Checkbox
                            checked={isItemSelected(groupRow)}
                            inputProps={{
                              "aria-labelledby": groupRow.securityGroupName,
                            }}
                            onClick={() => handleClick(groupRow)}
                          />
                        </TableCell>
                        <TableCell>{groupRow.securityGroupName}</TableCell>
                        <TableCell>
                          {groupRow.securityGroupDescription}
                        </TableCell>
                        <TableCell padding="checkbox">
                        <Checkbox
                          disabled={!isItemSelected(groupRow)}
                          checked={isReaderSelected(groupRow)}
                          onChange={() => handleReaderClick(groupRow)}
                          inputProps={{ "aria-label": "select all groups" }}
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={!isItemSelected(groupRow)}
                          checked={isWriterSelected(groupRow)}
                          onChange={() => handleWriterClick(groupRow)}
                          inputProps={{ "aria-label": "select all groups" }}
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={!isItemSelected(groupRow)}
                          checked={isApproverSelected(groupRow)}
                          onChange={() => handleApproverClick(groupRow)}
                          inputProps={{ "aria-label": "select all groups" }}
                        />
                      </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Page title={pageHeader}>
      <PageHeader
        title={pageHeader}
        subtitle={pageSubtitle}
        icon={<FontAwesomeIcon icon={["fas", getAdminIconName()]} />}
        backButton={true}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <Searchbar title={"Search"} handleSearch={handleSearch} />

          <MaterialButton
            text="Save"
            variant="outlined"
            startIcon={<SaveIcon />}
            className={classes.newButton}
            onClick={() => {
              updateSecurityGroup();
            }}
          />
        </Toolbar>

        {records !== null && securityRecords !== null ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row, idx) => (
                  <CollapseTableRow
                    key={row[uniqueId]}
                    value={row}
                    rowId={row[uniqueId]}
                    selectedIdx={idx}
                  ></CollapseTableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </>
        ) : (
          <TblContainer>
            <TblHead />
          </TblContainer>
        )}
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Page>
  );
};

export default UpdateUserSecurity;
