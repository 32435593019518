import { Config, SelectOption } from "src/types";
import UpdateContainer from "../UpdateContainer";
import { useGet } from "../../../hooks";

const initialValues = {
  adminId: 0,
  adminName: "",
  adminTitle: "",
  route: {
    routeId: 0,
    routeCategory: {
      routeCategoryId: 2,
      routeCategoryName: "hi",
    },
    routePath: "/",
    componentType: "",
    routeActive: true,
  },
  adminDescription: "",
  adminIcon: "",
};

const UpdateAdminView = () => {
  const reducer = (data: typeof initialValues) => {
    return data;
  };

  const { data, refetch } = useGet("ADMIN");

  const adminConfig: Config[] = [
    {
      name: "adminId",
      value: (state) => state?.adminId,
      label: "Admin ID",
      active: true,
      type: "id",
    },
    {
      name: "adminName",
      value: (state) => state?.adminName,
      label: "Admin Name",
      active: true,
      type: "input",
      validation: {
        validation: (value: string) =>
          !value ? "Admin Name is required" : undefined,
      },
    },
    {
      name: "adminTitle",
      value: (state) => state?.adminTitle,
      label: "Admin Category",
      active: true,
      type: "input",
      validation: {
        validation: (value: string) =>
          !value ? "Admin Category is required" : undefined,
      },
    },
    {
      name: "adminDescription",
      value: (state) => state?.adminDescription,
      label: "Description",
      active: true,
      type: "input",
      validation: {
        validation: (value: string) =>
          !value ? "Description is required" : undefined,
      },
    },
    {
      name: "adminIcon",
      value: (state) => state?.adminIcon,
      label: "Icon",
      active: true,
      type: "input",
      validation: {
        validation: (value: string) =>
          !value ? "Icon is required" : undefined,
      },
    },
    {
      name: "route.componentType",
      value: (state) => state?.route?.componentType,
      label: "Component Type",
      active: true,
      isNameValue: true,
      type: "select",
      options: {
        endpoint: "COMPONENT_TYPE",
        map: (itm: any, index) => ({
          id: index,
          title: itm,
        }),
        display: (state: any) => state?.route?.componentType,
      },
      validation: {
        validation: (value: string) =>
          !value ? "Component Type is required" : undefined,
      },
    },
    {
      name: "route.routePath",
      value: (state) => state?.route.routePath,
      label: "Route Path",
      active: true,
      type: "input",
      validation: {
        validation: (value: string) =>
          !value ? "Route Path is required" : undefined,
      },
    },
  ];

  return (
    <UpdateContainer
      name="admin console"
      title="Update admin console"
      subtitle="View, Add or Update admin consoles"
      endpoint="ADMIN"
      idName="adminId"
      config={adminConfig}
      // skip={["resourceCategoryName"]}
      initialValues={initialValues}
      data={data}
      refetch={refetch}
      reducer={reducer}
    />
  );
};

export default UpdateAdminView;
