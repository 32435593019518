import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import MaterialButton from "src/components/utils/Button";
import DataTable from "../../utils/DataTable";
import Notification from "src/components/utils/Notification";
import ConfirmDialog from "src/components/utils/ConfirmDialog";
import Searchbar from "src/components/utils/Searchbar";
import { getAutorenewedToken } from "../../auth/msalUtils";
import { getAdminIconName } from "../../utils/RouterStorage";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
  iconButton: {
    color: theme.palette.grey[500],
  },
}));

const RouteTab = (props) => {
  const classes = useStyles();

  const { name } = props;

  const uniqueId = "routeId";
  const tableName = "Route";
  const pageHeader = `Update ${name} Route Security`;
  const pageSubtitle = "Add, update or remove routes and their security groups";

  const [records, setRecords] = useState(null);
  const [securityRecords, setSecurityRecords] = useState(null);
  const [recordsSelectedList, setRecordsSelectedList] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  let updatedRouteGrp = {};

  const activeHeaders = [
    {
      id: 0,
      value: "id",
      label: "ID",
      active: false,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 1,
      value: "arrow",
      label: "",
      active: true,
      checkbox: false,
      disableSorting: true,
    },
    {
      id: 2,
      value: "routeId",
      label: "ID",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 3,
      value: "routePath",
      label: "Path",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 4,
      value: "componentType",
      label: "Component Type",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
  ].filter((header) => header.active);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, filterFn);

  // GET request using fetch with error handling
  const fetchData = async (isInit = false) => {
    fetch(process.env.REACT_APP_API_PATH + "/routesecurity/routescombined", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        const mixedData = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (mixedData && mixedData.message) || response.statusText;
          return Promise.reject(error);
        }
        setSecurityRecords(mixedData.securityGroups);
        setRecords(
          mixedData.routes.map((item) => {
            item.routeSecurity = item.routeSecurity.map((x) => {
              return {
                ...x,
                ...mixedData.securityGroups.filter(
                  (z) => z.securityGroupId === x.securityGroupId
                )[0],
              };
            });
            return item;
          })
        );
        if (isInit) setRecordsSelectedList(mixedData.routes.map((x) => false));
      })
      .catch((error) => {
        alert("There was an error!");
      });
  };

  const contentComparer = (otherArray) => {
    return (current) => {
      return (
        otherArray.filter(function (other) {
          return other.securityGroupId === current.securityGroupId;
        }).length === 0
      );
    };
  };

  const addGroup = (inGroupList, apiToken) => {
    fetch(process.env.REACT_APP_API_PATH + "/routesecurity", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: apiToken },
      body: JSON.stringify(inGroupList),
    })
      .then((ret) => {
        const isJson = ret.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && ret.json();

        // check for error response
        if (ret.ok) {
          fetchData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || ret.status;
          alert("Error happened while add route security", error);
          return Promise.reject(error);
        }
      })
      .catch((error) => {
       alert("An error while calling API to add route security!", error);
      });
  };

  const deleteGroup = (inGroupList, apiToken) => {
    fetch(process.env.REACT_APP_API_PATH + "/routesecurity", {
      method: "DELETE",
      headers: { "Content-Type": "application/json", authorization: apiToken },
      body: JSON.stringify(inGroupList),
    })
      .then((ret) => {
        const isJson = ret.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && ret.json();

        // check for error response
        if (ret.ok) {
          fetchData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || ret.status;
          alert("Error happened while delete route security");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        alert("An error while calling API to delete route security!");
      });
  };

  const updateGroup = (inNewList, inDelList, apiToken) => {
    fetch(process.env.REACT_APP_API_PATH + "/routesecurity", {
      method: "DELETE",
      headers: { "Content-Type": "application/json", authorization: apiToken },
      body: JSON.stringify(inDelList),
    })
      .then((ret) => {
        const isJson = ret.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && ret.json();

        // check for error response
        if (ret.ok) {
          fetch(process.env.REACT_APP_API_PATH + "/routesecurity", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: apiToken,
            },
            body: JSON.stringify(inNewList),
          })
            .then((res) => {
              const isJson = res.headers
                .get("content-type")
                ?.includes("application/json");
              const data = isJson && res.json();

              // check for error response
              if (res.ok) {
                fetchData();
                setNotify({
                  isOpen: true,
                  message: "Submitted Successfully",
                  type: "success",
                });
              } else {
                // get error message from body or default to response status
                const error = (data && data.message) || ret.status;
                alert("Error happened while add route security");
                return Promise.reject(error);
              }
            })
            .catch((error) => {
              alert("An error while calling API to add route security!");
            });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || ret.status;
          alert("Error happened while delete route security");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        alert("An error while calling API to delete route security!");
      });
  };

  const updateSecurityGroup = async () => {
    // Get the add and delete group list
    let newSecurityGrp = [],
      delSecurityGrp = [];
    records.forEach((item) => {
      if (updatedRouteGrp[item.routeId]) {
        delSecurityGrp = delSecurityGrp.concat(
          item.routeSecurity
            .filter(contentComparer(updatedRouteGrp[item.routeId]))
            .map((x) => {
              return {
                routeId: x.routeId,
                securityGroupId: x.securityGroupId,
                routeSecurityId: x.routeSecurityId,
              };
            })
        );
        newSecurityGrp = newSecurityGrp.concat(
          updatedRouteGrp[item.routeId]
            .filter(contentComparer(item.routeSecurity))
            .map((y) => {
              return {
                routeId: y.routeId,
                securityGroupId: y.securityGroupId,
                routeSecurityId: 0,
              };
            })
        );
      }
    });
    // Run API to reflect the change
    const apiToken = await getAutorenewedToken();
    if (newSecurityGrp.length > 0 && delSecurityGrp.length > 0) {
      updateGroup(newSecurityGrp, delSecurityGrp, apiToken);
    } else if (newSecurityGrp.length > 0) {
      addGroup(newSecurityGrp, apiToken);
    } else if (delSecurityGrp.length > 0) {
      deleteGroup(delSecurityGrp, apiToken);
    } else {
      setNotify({
        isOpen: true,
        message: "No updated route security group",
        type: "success",
      });
    }
  };

  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    fetchData(true);
  }, []);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((data) =>
            Object.values(data).some((val) =>
              String(val)
                .toLowerCase()
                .includes(target.value.toString().toLowerCase())
            )
          );
        }
      },
    });
  };

  function CollapseTableRow(props) {
    const row = props.value;
    const currentRouteId = props.rowId.toString();
    const [open, setOpen] = useState(recordsSelectedList[props.selectedIdx]);
    const [selected, setSelected] = useState([]);

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        setSelected(securityRecords);
        return;
      }
      setSelected([]);
    };

    const handleClick = (gRow) => {
      const selectedIndex = selected.indexOf(gRow);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, gRow);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);

      updatedRouteGrp[currentRouteId] = newSelected.map((item) => {
        return {
          routeId: currentRouteId,
          securityGroupId: item.securityGroupId,
        };
      });
    };

    const isItemSelected = (gRow) => selected.indexOf(gRow) !== -1;

    const toggleRouteTable = (inFlag) => {
      setOpen(!inFlag);
      recordsSelectedList[props.selectedIdx] = !inFlag;
    };

    useEffect(() => {
      let newSelected = [];
      if (row && row.routeSecurity) {
        row.routeSecurity.forEach((item) => {
          newSelected.push(
            securityRecords.filter(
              (x) => x.securityGroupId === item.securityGroupId
            )[0]
          );
        });
      }
      setSelected(newSelected);
    }, [row]);

    return (
      <React.Fragment>
        <TableRow hover>
          {activeHeaders.map((col) =>
            col.value === "arrow" ? (
              <TableCell key={col.id} onClick={() => toggleRouteTable(open)}>
                <IconButton aria-label="expand row">
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            ) : (
              <TableCell key={col.id} onClick={() => toggleRouteTable(open)}>
                {row[col.value]}
              </TableCell>
            )
          )}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Security Group
                </Typography>
                <Table size="small" aria-label="securitygroup">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < securityRecords.length
                          }
                          checked={
                            securityRecords.length > 0 &&
                            selected.length === securityRecords.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{ "aria-label": "select all groups" }}
                        />
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {securityRecords.map((groupRow) => (
                      <TableRow
                        key={groupRow.securityGroupId}
                        onClick={() => handleClick(groupRow)}
                      >
                        <TableCell component="th" scope="row">
                          <Checkbox
                            checked={isItemSelected(groupRow)}
                            inputProps={{
                              "aria-labelledby": groupRow.securityGroupName,
                            }}
                          />
                        </TableCell>
                        <TableCell>{groupRow.securityGroupName}</TableCell>
                        <TableCell>
                          {groupRow.securityGroupDescription}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Page title={pageHeader}>
      <PageHeader
        title={pageHeader}
        subtitle={pageSubtitle}
        icon={<FontAwesomeIcon icon={["fas", getAdminIconName()]} />}
        backButton={true}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <Searchbar title={"Search"} handleSearch={handleSearch} />

          <MaterialButton
            text="Save"
            variant="outlined"
            startIcon={<SaveIcon />}
            className={classes.newButton}
            onClick={() => {
              updateSecurityGroup();
            }}
          />
        </Toolbar>

        {records !== null ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row, idx) => (
                  <CollapseTableRow
                    key={row[uniqueId]}
                    value={row}
                    rowId={row[uniqueId]}
                    selectedIdx={idx}
                  ></CollapseTableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </>
        ) : (
          <TblContainer>
            <TblHead />
          </TblContainer>
        )}
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Page>
  );
};

export default RouteTab;
