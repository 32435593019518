const MENU_COLOR_SESSION_STORAGE_KEY = 'MENU_COLOR';

const hex = window.sessionStorage.getItem(MENU_COLOR_SESSION_STORAGE_KEY) ? 
            window.sessionStorage.getItem(MENU_COLOR_SESSION_STORAGE_KEY) : "#000000";

let red =  parseInt(hex.substring(1, 3), 16); 
let green = parseInt(hex.substring(3, 5), 16);
let blue = parseInt(hex.substring(5, 7), 16);


const rgbConverter =
{
    getSelection: (hexCode?: string) => {
        return hexCode ? ` rgba(${parseInt(hexCode.substring(1, 3), 16)}, ${parseInt(hexCode.substring(3, 5), 16)}, ${parseInt(hexCode.substring(5, 7), 16)}, 0.08)` :
            ` rgba(${red}, ${green}, ${blue}, 0.08)`;
    },
    getHover: (hexCode?: string) => {
        return hexCode ? ` rgba(${parseInt(hexCode.substring(1, 3), 16)}, ${parseInt(hexCode.substring(3, 5), 16)}, ${parseInt(hexCode.substring(5, 7), 16)}, 0.04)` :
                                                     ` rgba(${red}, ${green}, ${blue}, 0.04)`; },
}

export default rgbConverter