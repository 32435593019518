import React, { useEffect, useState } from "react";

import {
  Paper,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import MaterialButton from "src/components/utils/Button";
import DataTable from "../../utils/DataTable";
import Notification from "src/components/utils/Notification";
import Searchbar from "src/components/utils/Searchbar";
import { getAutorenewedToken } from "../../auth/msalUtils";
import { getAdminIconName } from "../../utils/RouterStorage";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
  iconButton: {
    color: theme.palette.grey[500],
  },
  
}));


const UpdateRoleLevelSecurity = () => {
  const classes = useStyles();

  const uniqueId = "userId";
  const pageHeader = "Manage Data Governance";
  const pageSubtitle = "Add, update or remove data governance for users";

  const [records, setRecords] = useState(null);
  const [assignedProject, setAssignedProject] = useState([]);
  const [isAssignedProjectLoaded, setIsAssignedProjectLoaded] = useState(false);
  const [isProjectLoaded, setIsProjectLoaded] = useState(false);
  const [updatedUser, setUpdatedUser] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const activeHeaders = [
    {
      id: 0,
      value: "id",
      label: "ID",
      active: false,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 1,
      value: "arrow",
      label: "",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 2,
      value: "userId",
      label: "User ID",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 3,
      value: "userName",
      label: "Username",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 4,
      value: "userFirstName",
      label: "First Name",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 5,
      value: "userLastName",
      label: "Last Name",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
  ].filter((header) => header.active);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, filterFn);

  const fetchProjects = async (isInit = false) => {
    // Get data from combined API
    fetch(process.env.REACT_APP_API_PATH + "/rolelevelsecurity/getUserProjects", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        // let mixedData = [];
        const activeData = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (activeData && activeData.message) || response.statusText;
          return Promise.reject(error);
        }
        setRecords(activeData);
        setIsProjectLoaded(true)
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const getassignedproject = async () => {
    // Get data from combined API
    fetch(process.env.REACT_APP_API_PATH + "/rolelevelsecurity/getassignedproject", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        // let mixedData = [];
        const result = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (result && result.message) || response.statusText;
          return Promise.reject(error);
        }
        setAssignedProject(result);
        setIsAssignedProjectLoaded(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    //fetchData(true);
    fetchProjects(true);
    getassignedproject();
  }, []);

  useEffect(() => {
    if (isAssignedProjectLoaded && isProjectLoaded ) {
      const updatedData = records.map(rec => {
        const assignedProjects = assignedProject.filter(x => x.userId === rec.userId);
       
        if (assignedProjects && assignedProjects.length > 0) {
          return {
            ...rec,
            projects: rec.projects.map(item => {
              const project = assignedProjects.find(x => x.projectId === item.projectId);
              if (project) {
                return {
                  ...item,
                  selected: true,
                  contracts: item.contracts.map(cont => {
                    const contract = project.contracts.find(x => x.contractId === cont.contractPackageNumber);
                    return contract ? { ...cont, selected: true } : cont;
                  })
                };
              }
              return item;
            })
          };
        }
        return rec;
      });
      setRecords(updatedData);
    }
  }, [isAssignedProjectLoaded, isProjectLoaded]);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((data) =>
            Object.values(data).some((val) =>
              String(val)
                .toLowerCase()
                .includes(target.value.toString().toLowerCase())
            )
          );
        }
      },
    });
  };
  const updateUserProjects = (apiToken) => {
    // Get data from combined API
    fetch(
      process.env.REACT_APP_API_PATH +"/rolelevelsecurity/updateUserProjectRole",
      {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: apiToken },
        body: JSON.stringify(updatedUser)
      }
    )
      .then(async (response) => {
        // check for error response
        if (!response.ok) {
          setNotify({
            isOpen: true,
            message: "Failed to save role level security.",
            type: "error",
          });
          // get error message from body or default to response statusText
          return Promise.reject(response.statusText);
        }
        setUpdatedUser([])
        setNotify({
          isOpen: true,
          message: "Role level security updated.",
          type: "success",
        });
        
       
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "Failed to save role level security.",
          type: "error",
        });
      });
  };
  const updateUserProjectContract = (updatedData, row) => {
    // Find the user with updated data
    const selectedUser = updatedData.find((x) => x.userId === row.userId);

    // Filter the selected user's projects and contracts
    const filteredProjects = selectedUser.projects
      .filter((project) => project.selected)
      .map((project) => ({
        ...project,
        contracts: project.contracts.filter((contract) => contract.selected),
      }));

    // Update the selected user with the filtered projects
    const updatedSelectedUser = { ...selectedUser, projects: filteredProjects };

    // Check if the user already exists in updatedUser and update accordingly,
    if (updatedUser.length > 0 && updatedUser.some((x) => x.userId === selectedUser.userId)) {
      const result = updatedUser.filter((x) => x.userId !== selectedUser.userId);
      result.push(updatedSelectedUser);
      setUpdatedUser(result);
    } else {
      setUpdatedUser([...updatedUser, updatedSelectedUser]);
    }
  }
  const  saveUser = async () => {
     const apiToken = await getAutorenewedToken();
     updateUserProjects(apiToken);
    
  }
  function CollapseTableRow(props) {
    const row = props.value;
    const [projects, setProjects] = useState(props.value.projects);
     const [projectSelected, setProjectSelected] = useState(projects.filter(x => x.selected))

     const handleAllProjectSelection = () => {
    
      const selection =  !(projects.length == projectSelected.length)
      const updatedData = records.map((rec) =>
        rec.userId === row.userId
          ? {
              ...rec,
              projects: rec.projects.map((item) => ({
                ...item,
                selected: selection,
                contracts: item.contracts.map((cont) =>
                  !selection ? { ...cont, selected: false } : cont
                ),
              })),
            }
          : rec
      );
      setRecords(updatedData);
      updateUserProjectContract(updatedData, row);
    };

    const handleProjectSelection = (proj) => {
      const updatedData = records.map(rec =>
        rec.userId === row.userId
          ? {
            ...rec,
            projects: rec.projects.map(item =>
              item.projectId === proj.projectDetails.projectId
                ? {
                  ...item, selected: !proj.projectDetails.selected,
                  contracts: item.contracts.map(cont =>
                  proj.projectDetails.selected ? { ...cont, selected: false } : cont
                  )
                }
                : item
            )
          }
          : rec
      );
      setRecords(updatedData);

      updateUserProjectContract(updatedData, row);
    };

    
    const toggleSecTable = (inFlag) => {
      const updatedData = records.map(item =>
        item.userId === row.userId ? { ...item, toggle: !inFlag } : item
      );
      setRecords(updatedData)
    };

    // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <React.Fragment>
        <TableRow hoverv >
          {activeHeaders.map((col) =>
            col.value === "arrow" ? (
              <TableCell key={col.id} onClick={() => toggleSecTable(row.toggle)}>
                <IconButton aria-label="expand row">
                  {row.toggle ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
              </TableCell>
            ) : (
              <TableCell key={col.id} onClick={() => toggleSecTable(row.toggle)}>
                {row[col.value]}
              </TableCell>
            )
          )}
        </TableRow>
        <TableRow hover>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={row.toggle} timeout="auto" unmountOnExit>
              <Box margin={4}>
                <Typography variant="h6" gutterBottom component="div">
                  Project
                </Typography>
                <Table size="small" aria-label="securitygroup">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            projects.length < projectSelected.length
                          }
                          checked={
                            projects.length == projectSelected.length
                          }
                          onChange={handleAllProjectSelection}
                          inputProps={{ "aria-label": "select all groups" }}
                        />
                      </TableCell>
                      <TableCell padding="checkbox" ></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects?.map((groupRow, idx) => (
                      <React.Fragment>
                        <CollapsePortfolioTableRow
                          key={row[uniqueId]}
                          value={row}
                          rowId={row[uniqueId]}
                          selectedIdx={idx}
                          project={groupRow}
                          contracts={groupRow.contracts}
                          projectSelection={handleProjectSelection}
                        >
                        </CollapsePortfolioTableRow>

                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }


  function CollapsePortfolioTableRow(props) {
    const row = props.value;
    const [contracts, setContracts] = useState(props.contracts)
    const [project, setProject] = useState(props.project)
    const [contractSelected, setContractSelected] = useState(props.contracts.filter(x => x.selected))


    const selectProject = (projectDetails) => {

      props.projectSelection({
        userDetails: {
          userId: row.userId,
          userName: row.userName,
        },
        projectDetails: projectDetails
      });
    }


    const toggleProjectSelection = () => {
      const updatedData = records.map(rec =>
        rec.userId === row.userId
          ? {
            ...rec,
            projects: rec.projects.map(item =>
              item.projectId === project.projectId
                ? { ...item, toggle: !project.toggle }
                : item
            )
          }
          : rec
      );
      setRecords(updatedData);
    };

    const contractSelection = (con) => {
      const updatedData = records.map(rec =>
        rec.userId === row.userId
          ? {
            ...rec,
            projects: rec.projects.map(item =>
              item.projectId === project.projectId
                ? {
                  ...item,
                  contracts: item.contracts.map(cont =>
                    cont.contractPackageNumber === con.contractPackageNumber
                      ? { ...cont, selected: !con.selected }
                      : cont
                  )
                }
                : item
            )
          }
          : rec
      );

      setRecords(updatedData);
      updateUserProjectContract(updatedData, row);

    };

    const handleAllContractSelection = () => {
    
      const selection =  !(contracts.length == contractSelected.length)
      const updatedData = records.map(rec =>
        rec.userId === row.userId
          ? {
            ...rec,
            projects: rec.projects.map(item =>
              item.projectId === project.projectId
                ? {
                  ...item,
                  contracts: item.contracts.map(cont =>
                   ({ ...cont, selected: selection})
                  )
                }
                : item
            )
          }
          : rec
      );

      setRecords(updatedData);
      updateUserProjectContract(updatedData, row);
    };

    return (
      <React.Fragment>
        <TableRow
          key={props.selectedIdx}
        >
          <TableCell key={props.selectedIdx} onClick={toggleProjectSelection}>
            <IconButton aria-label="expand row">
              {project.toggle ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <Checkbox
              checked={project.selected}
              onClick={() => selectProject(project)}
              inputProps={{
                "aria-labelledby": project.projectName,
              }}
               size="medium"
            />
          </TableCell>
          <TableCell>{project.projectName}</TableCell>
          <TableCell>{project.projectDescription}</TableCell>
        </TableRow>

        <TableRow hover>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={project.toggle} timeout="auto" unmountOnExit>
              <Box marginLeft={8}>
                <Typography variant="h6" gutterBottom component="div">
                  Contract Packages
                </Typography>
                <Table size="small" aria-label="securitygroup">
                  <TableHead>
                    <TableRow >
                      <TableCell padding="checkbox" >
                        <Checkbox
                          indeterminate={
                            contracts.length < contractSelected.length
                          }
                          checked={
                            contracts.length == contractSelected.length
                          }
                          disabled={!project.selected}
                          onChange={handleAllContractSelection}
                          inputProps={{ "aria-label": "select all groups" }}
                        />
                      </TableCell>
                      <TableCell>Contract Package Number</TableCell>
                      <TableCell>Contract Package Name</TableCell>
                      <TableCell>Contract Package Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contracts.map((cont, id) => (
                      <TableRow
                        key={id}
                      >
                        <TableCell component="th" scope="row" style={{paddingLeft:"40px"}}>
                          <Checkbox
                            onClick={() => contractSelection(cont)}
                            checked={cont.selected}
                            disabled={!project.selected}
                            inputProps={{
                              "aria-labelledby": cont.contractPackageNumber,
                            }}
                            size="medium"
                          />
                        </TableCell>
                        <TableCell>{cont.contractPackageNumber}</TableCell>
                        <TableCell>{cont.contractPackageName}</TableCell>
                        <TableCell>{cont.contractPackageDescription}</TableCell>
                      </TableRow>

                    ))}

                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>

      </React.Fragment>
    );
  }

  return (
    <Page title={pageHeader}>
      <PageHeader
        title={pageHeader}
        subtitle={pageSubtitle}
        icon={<FontAwesomeIcon icon={["fas", getAdminIconName()]} />}
        backButton={true}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <Searchbar title={"Search"} handleSearch={handleSearch} />
          <MaterialButton
            text="Save"
            variant="outlined"
            startIcon={<SaveIcon />}
            className={classes.newButton}
            onClick={saveUser}
            disabled = {updatedUser.length == 0}
          />
        </Toolbar>

        {records !== null ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row, idx) => (
                  <CollapseTableRow
                    key={row[uniqueId]}
                    value={row}
                    rowId={row[uniqueId]}
                    selectedIdx={idx}
                  ></CollapseTableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </>
        ) : (
          <TblContainer>
            <TblHead />
          </TblContainer>
        )}
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Page>
  );
};

export default UpdateRoleLevelSecurity;
