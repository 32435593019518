import { useLocation } from "react-router-dom";
import { getRoutePathList } from "./components/utils/RouterStorage";
import Layout from "./components/layout/Layout";
import NotFoundView from "./components/errors/NotFoundView";
import HomePageView from "./components/homepage/Homepage";
import ChatPage from "./components/chat/ChatPage";
import * as componentsMap from "./componentsMap";
import LoginCallback from "./components/auth/loginCallback";

const CustomComponent = () => {
  const storedRouteList = getRoutePathList();
  const routePath = useLocation();
  if (storedRouteList) {
    const currPathObj = JSON.parse(storedRouteList).filter(
      (x: any) => x.routePath === routePath.pathname
    )[0];
    if (currPathObj && currPathObj.componentType) {
      const CurrComponent =
        // @ts-ignore
        componentsMap[currPathObj.componentType as keyof componentsMap];
      if (CurrComponent) return <CurrComponent />;
    }
  }
  return <NotFoundView />;
};

export const routes = [
  { path: "/loginCallback", element: <LoginCallback /> },
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "", element: <HomePageView /> },
      { path: "chat", element: <ChatPage />},
      { path: ":pathL1", element: <CustomComponent /> },
      { path: ":pathL1/:pathL2", element: <CustomComponent /> },
      { path: ":pathL1/:pathL2/:pathL3", element: <CustomComponent /> },
      { path: "*", element: <NotFoundView /> },
    ],
  },
];
