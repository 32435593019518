import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@material-ui/core";
import React from "react";
import MaterialButton from 'src/components/utils/Button';
import Error from "@material-ui/icons/ErrorOutline";

interface MissingWbs{
    project:string
    wbsCode:string
}
interface AlertProps{
    missingWbs:MissingWbs[]
    fileName?:string
    sendData:any
}
const MissingWbsDialog=(props:AlertProps)=> {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();

    const handleClose = () => {
        setOpen(false);
        props.sendData();
    };
  
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{color: theme.palette.secondary.red}}>
                <div style={{display:"flex", alignItems:"center"}}><Error></Error><span style={{paddingLeft:"2%"}}>{"Failed to upload template"}</span></div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">The following WBS codes are not yet defined for the associated projects. Please ensure you first define your WBS on step 2:</DialogContentText>
            <DialogContentText>
            <ul style={{paddingLeft:"3%", maxHeight: "150px", overflow:"auto"}}>
                {props.missingWbs.map((pro,idx) => (
                    <li key={idx}> {pro.project} - {pro.wbsCode}</li>
                ))}
                </ul>

            </DialogContentText>
            <DialogContentText id="alert-dialog-description"> Please configure WBS by first uploading WorkBreakDownStructure.csv</DialogContentText>
          </DialogContent>
          
          <DialogActions>
            <MaterialButton onClick={handleClose}
            text={'Close'}
            style={{
                backgroundColor:theme.palette.secondary.red,
                color: theme.palette.whiteColor.main
              }}>Close</MaterialButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  export default MissingWbsDialog