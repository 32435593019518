import React, { useState } from "react";
import Popup from "src/components/utils/Popup";

import { ENDPOINTS } from "../../endpoints";

import { Config } from "src/types/config";
import { UpdatePage } from "./UpdatePage";
import { UpdateTable } from "../table/UpdateTable";
import { useMutate } from "src/hooks/query";
import UpdateForm from "./UpdateForm";

type RouteConfig =
  | "routePath"
  | "resourceCategoryName"
  | "routeActive"
  | "actions";

interface UpdateContainerProps {
  name: string;
  title: string;
  subtitle: string;
  data: Array<any> | undefined | null;
  refetch: Function;
  reducer: Function;
  idName: string;
  endpoint: keyof typeof ENDPOINTS;
  config: Config[];
  initialValues: any;
  skip?: RouteConfig[];
  disableBackButton?: boolean;
}

const UpdateContainer = ({
  name,
  title,
  subtitle,
  data,
  refetch,
  reducer,
  idName,
  endpoint,
  config,
  initialValues,
  disableBackButton,
}: UpdateContainerProps) => {
  const [recordForEdit, setRecordForEdit] = useState(null);

  const formatName = `${name.charAt(0).toUpperCase()}${name.slice(1)}`;

  //POPUP RELATED
  const [openPopup, setOpenPopup] = useState(false);
  const [disableContent, setDisableContent] = useState(false);
  const [popupTitle, setPopupTitle] = useState(`${formatName} Details`);

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const onComplete = () => {
    refetch();
    setRecordForEdit(null);
    setOpenPopup(false);
    setDisableContent(false);
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
  };

  const onError = (error: Error) => {
    setRecordForEdit(null);
    setOpenPopup(false);
    setDisableContent(false);
    setNotify({
      isOpen: true,
      message: `${error}`,
      type: "error",
    });
  };

  const { mutate: createMutate } = useMutate(
    endpoint,
    "POST",
    onComplete,
    onError
  );

  const { mutate: updateMutate } = useMutate(
    endpoint,
    "PUT",
    onComplete,
    onError
  );

  const { mutate: deleteMutation } = useMutate(
    endpoint,
    "DELETE",
    onComplete,
    onError
  );

  const onDelete = async (id: number) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteMutation({ body: undefined, id });
    if (idName !== "userId") {
      if (idName !== "routeId") {
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "warning",
        });
      }
    }
  };

  const formConfig = [...config];

  const openInPopup = (item: any, mode: string) => {
    setRecordForEdit(item);
    if (mode && mode === "edit") {
      setPopupTitle(`Edit ${name}`);
      setDisableContent(false);
    } else if (mode && mode === "add") {
      setPopupTitle(`Add New ${formatName}`);
      setDisableContent(false);
    } else {
      setPopupTitle(`${formatName} details`);
      setDisableContent(true);
    }
    setOpenPopup(true);
  };

  const addOrEdit = (data: any) => {
    // gets string title from the correct option
    const body = reducer(data);
    if (data[idName] === 0) {
      createMutate({
        body,
      });
    } else {
      updateMutate({
        body,
        id: data[idName],
      });
    }
  };

  return (
    <UpdatePage
      title={title}
      subtitle={subtitle}
      notify={notify}
      setNotify={setNotify}
      confirmDialog={confirmDialog}
      setConfirmDialog={setConfirmDialog}
      disableBackButton={disableBackButton}
    >
      <>
        <UpdateTable
          buttonTitle="Add new"
          openInPopup={openInPopup}
          onButtonClick={() => openInPopup(null, "add")}
          idName={idName}
          config={formConfig}
          records={data}
          setConfirmDialog={setConfirmDialog}
          onDelete={onDelete}
        />

        <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          title={popupTitle}
          disableContent={disableContent}
          openInPopup={openInPopup}
          row={recordForEdit}
        >
          <UpdateForm
            idName={idName}
            config={formConfig}
            initialValues={recordForEdit || initialValues}
            addOrEdit={addOrEdit}
            disableContent={disableContent}
          />
        </Popup>
      </>
    </UpdatePage>
  );
};

export default UpdateContainer;
