import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import rgbConverter from "../../../utils/rgbConverter"
import { hasChildren } from "./HasChildren";

const TEXT_COLOR_SESSION_STORAGE_KEY = 'TEXT_COLOR';

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(0),
    color: window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) ? window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) : theme.palette.text.primary,
    opacity:0.54
  },
  title: {
    marginRight: "auto",
    color: window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) ? window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) : theme.palette.text.primary,
    
  },
  active: {
    "&:hover": {
      backgroundColor: rgbConverter.getHover(),
      borderTopRightRadius:25,
      borderBottomRightRadius:25,
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& $selected': {
        backgroundColor: 'red',
      },
      "& $icon": {
        color:  window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) ? window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) : theme.palette.text.primary,
      },
    },
   
   
  },
  selected: {
    backgroundColor: rgbConverter.getSelection()+"!important",
    borderTopRightRadius:25,
    borderBottomRightRadius:25,
    borderLeft:"thick solid #26890D",
    color:"#26890D",
    "& $title": {
      color: "#26890D",
    },
    "& $icon": {
      color: "#26890D",
    },
    
},
}));

export default function MultiLevelNav(menu, barOpen, handleDrawerOpen) {
  const classes = useStyles();
  const MenuItem = ({ item, barOpen, classes, handleDrawerOpen }) => {
    classes = useStyles();
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return (
      <Component
        item={item}
        barOpen={barOpen}
        classes={classes}
        handleDrawerOpen={handleDrawerOpen}
      />
    );
  };

  const SingleLevel = ({ item, classes, className, handleDrawerOpen }) => {
    classes = useStyles();
    const location = useLocation();
    return (
      <ListItem
        button
        component={Link}
        to={item.sidebarRouteName ? item.sidebarRouteName : item.routePath}
        selected={location.pathname === item.routePath}
        className={clsx(className, classes.active)}
        classes={{ selected: classes.selected }}
      >
        <ListItemIcon edge="start" className={classes.icon}>
          {" "}
          {item.sidebarIconName}
        </ListItemIcon>
        <ListItemText className={classes.title} primary={item.sidebarName} />
      </ListItem>
    );
  };

  const MultiLevel = ({
    item,
    barOpen,
    classes,
    className,
    handleDrawerOpen,
  }) => {
    classes = useStyles();
    const { items: children } = item;
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const handleClick = () => {
      setOpen((prev) => !prev);

      if (!barOpen) {
        handleDrawerOpen();
      }
    };
    return (
      <React.Fragment>
        <ListItem
          button
          onClick={(event) => {
            handleClick();
          }}
          selected={location.pathname === item.routePath}
          className={clsx(className, classes.active)}
          classes={{ selected: classes.selected }}
          
        >
          <ListItemIcon className={classes.icon}>
            {item.sidebarIconName}
          </ListItemIcon>
          <ListItemText className={classes.title} primary={item.sidebarName} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={open && barOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <MenuItem
                key={key}
                item={child}
                component={Link}
                to={
                  item.sidebarRouteName
                    ? item.sidebarRouteName
                    : item.route
                    ? item.routePath
                    : ""
                }
              />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  return menu.map((item, key) => (
    <MenuItem
      key={key}
      item={item}
      handleDrawerOpen={handleDrawerOpen}
      barOpen={barOpen}
      classes={classes}
    />
  ));
}
