import React, { useEffect, useState } from "react";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  TableContainer,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import DataTable from "../../utils/DataTable";
import ActionButton from "../../utils/ActionButton";
import Popup from "src/components/utils/Popup";
import UpdatePropertyForm from "./UpdateDatasetUserForm";
import Notification from "src/components/utils/Notification";
import ConfirmDialog from "src/components/utils/ConfirmDialog";
import Searchbar from "src/components/utils/Searchbar";
import MuiButton from "src/components/utils/Button";
import { getAutorenewedToken } from "../../auth/msalUtils";
import { getAdminIconName } from "../../utils/RouterStorage";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
}));

const sourceConfig = [
  {
    id: 0,
    value: "propertyId",
    label: "Property ID",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 1,
    value: "datasetId",
    label: "Dataset ID",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 2,
    value: "propertyType",
    label: "Property Type",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 3,
    value: "propertyName",
    label: "Property Name",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 4,
    value: "propertyValue",
    label: "Property Value",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 17,
    value: "actions",
    label: "Actions",
    active: true,
    checkbox: false,
    disableSorting: true,
  },
];

const UpdatePropertyView = () => {
  const classes = useStyles();

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState(null);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //POPUP RELATED
  const [openPopup, setOpenPopup] = useState(false);
  const [disableContent, setDisableContent] = useState(false);
  const [popupTitle, setPopupTitle] = useState("Dataset Details");

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const activeHeaders = sourceConfig.filter((header) => header.active);

  const [errorMessage, setErrorMessage] = useState("");

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, filterFn);

  const getPropTableData = async () => {
    fetch(process.env.REACT_APP_API_PATH + "/dcc/datasetuserproperties", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        setRecords(data);
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("There was an error!");
      });
  };

  const addNewSource = async (inParam, inFn) => {
    fetch(process.env.REACT_APP_API_PATH + "/dcc/datasetuserproperties/0", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: await getAutorenewedToken(),
      },
      body: JSON.stringify(inParam),
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          inFn();
          setRecordForEdit(null);
          setOpenPopup(false);
          setDisableContent(false);

          getPropTableData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          alert("Error happened while adding new user property row");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("An error while calling API to add new user property!");
      });
  };

  const updateSource = async (inParam, inFn) => {
    fetch(process.env.REACT_APP_API_PATH + "/dcc/datasetuserproperties", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: await getAutorenewedToken(),
      },
      body: JSON.stringify(inParam),
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          inFn();
          setRecordForEdit(null);
          setOpenPopup(false);
          setDisableContent(false);

          getPropTableData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          alert("Error happened while updating user property");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("An error while calling API to update user property!");
      });
  };

  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    getPropTableData();
  }, []);

  const openInPopup = (item, editMode) => {
    //setDisableContent(disabled)
    setRecordForEdit(JSON.parse(JSON.stringify(item)));

    if (editMode) {
      setPopupTitle("Edit Dataset User Properties");

      setDisableContent(false);
    } else {
      setPopupTitle("Dataset User Properties Details");

      setDisableContent(true);
    }

    setOpenPopup(true);
  };

  const addOrEdit = (inSource, resetForm) => {
    if (inSource.sourceId === 0) {
      addNewSource(inSource);
    } else {
      updateSource(inSource);
    }
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    fetch(process.env.REACT_APP_API_PATH + "/dcc/datasetuserproperties/" + id, {
      method: "DELETE",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          getPropTableData();
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          alert("Error happened while deleting user property");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("An error while calling API to delete user property!");
      });

    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((data) =>
            Object.values(data).some((val) =>
              String(val)
                .toLowerCase()
                .includes(target.value.toString().toLowerCase())
            )
          );
        }
      },
    });
  };

  return (
    <Page title="View Dataset User Properties">
      <PageHeader
        title="View Dataset User Properties"
        subtitle="View, Add or Update dataset user properties"
        icon={<FontAwesomeIcon icon={["fas", getAdminIconName()]} />}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <Searchbar handleSearch={handleSearch} title="Search properties" />

          <MuiButton
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setPopupTitle("Add new user property");
              setDisableContent(false);
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          />
        </Toolbar>
        {records !== null ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow hover key={row["propertyId"]} value={row}>
                    {activeHeaders.map((col) =>
                      row[col.value] && col.checkbox === true ? (
                        <TableCell
                          key={col.id}
                          onClick={() => {
                            openInPopup(row);
                          }}
                        >
                          {" "}
                          <CheckIcon style={{ color: "green" }} />{" "}
                        </TableCell>
                      ) : row[col.value] === false && col.checkbox === false ? (
                        <TableCell
                          key={col.id}
                          onClick={() => {
                            openInPopup(row);
                          }}
                        >
                          {" "}
                          <ClearIcon style={{ color: "red" }} />{" "}
                        </TableCell>
                      ) : col.value === "actions" ? (
                        <TableCell key={col.id}>
                          <ActionButton
                            onClick={() => {
                              openInPopup(row, "edit");
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </ActionButton>
                          <ActionButton
                            color="default"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title:
                                  "Are you sure you want to delete this record?",
                                subtitle: "You can't undo this operation",
                                onConfirm: () => {
                                  onDelete(row["propertyId"]);
                                },
                              });
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </ActionButton>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={col.id}
                          onClick={() => {
                            openInPopup(row);
                          }}
                        >
                          {row[col.value]}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <br></br>
            <TblPagination />
          </>
        ) : (
          <TableContainer></TableContainer>
        )}
        <p>{errorMessage}</p>
      </Paper>

      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title={popupTitle}
        disableContent={disableContent}
        openInPopup={openInPopup}
        row={recordForEdit}
      >
        <UpdatePropertyForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          disableContent={disableContent}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Page>
  );
};

export default UpdatePropertyView;
