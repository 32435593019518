import { useNavigate } from "react-router-dom";
import { Button, IconButton, Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
export default function BackButton() {
  let navigate = useNavigate();
  return (
    <Box>
      <IconButton style={{ fill: "greyColor" }} onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
    </Box>
  );
}
