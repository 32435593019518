import React from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { loginRequest, msalConfig } from "./authConfig";
import {
  getStoredPath,
  getRoutePathList,
  storeRoutePathList,
} from "../utils/RouterStorage";
import "../../styles/loading.css";

const isValidUrl = (url: any) => {
  const whitelist = [
    /^\/$/,
    /^\/home$/,
    /^\/dashboards\/.+$/,
    /^\/admin\/?.*$/,
    /^\/applications$/,
    /^\/file-upload$/,
    /^\/chat\/.+$/,
  ];

  return whitelist.some((r) => r.test(url));
};

const LoginCallback = () => {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();

  let localUrl = getStoredPath() || "";
  let imageUrl = "/" + (process.env.REACT_APP_SPINNER_FAVICON_NAME ? process.env.REACT_APP_SPINNER_FAVICON_NAME : "android-chrome-192x192.png");

  if (accounts.length === 0 && inProgress === InteractionStatus.None) {
    console.error("No msal auth, so need to redirect to ms login page");
    instance.loginRedirect(loginRequest);
    navigate(localUrl);
  } else {
    if (!isValidUrl(localUrl)) {
      console.warn("Invalid local redirect URL, redirecting to home instead", localUrl);
      localUrl = "/";
    }

    if (inProgress === InteractionStatus.None) {
      const apiScope = msalConfig.auth.clientId + "/.default";
      const accessTokenRequest = {
        scopes: [`${apiScope}`],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Get route path list
          const storedRouteList = getRoutePathList();
          if (!storedRouteList) {
            fetch(process.env.REACT_APP_API_PATH + "/route/active", {
              method: "GET",
              headers: {
                authorization: "Bearer " + accessTokenResponse.accessToken,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                storeRoutePathList(JSON.stringify(data));
                navigate(localUrl);
              })
              .catch((error) => {
                console.error("Failed to get route path list!", error);
              });
          } else {
            navigate(localUrl);
          }
        })
        .catch((error) => {
          console.error("An error while fetching Token!",error);
        });
    }
  }

  return (
    <div className={"sk-circle"}>
      <div
        style={{
          position: "relative",
          float: "left",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img
          alt="Logo"
          width="96px"
          height="96px"
          src={imageUrl}
        />
      </div>
      <div className={"sk-circle1 sk-child"}></div>
      <div className={"sk-circle2 sk-child"}></div>
      <div className={"sk-circle3 sk-child"}></div>
      <div className={"sk-circle4 sk-child"}></div>
      <div className={"sk-circle5 sk-child"}></div>
      <div className={"sk-circle6 sk-child"}></div>
      <div className={"sk-circle7 sk-child"}></div>
      <div className={"sk-circle8 sk-child"}></div>
      <div className={"sk-circle9 sk-child"}></div>
      <div className={"sk-circle10 sk-child"}></div>
      <div className={"sk-circle11 sk-child"}></div>
      <div className={"sk-circle12 sk-child"}></div>
    </div>
  );
};

export default LoginCallback;
