import UpdateContainer from "../UpdateContainer";
import { useGet } from "../../../hooks";
import { Config } from "../../../types/config";

const initialValues = {
  routeCategoryId: 0,
  routeCategoryName: "",
};

const config: Config[] = [
  {
    name: "routeCategoryId",
    value: (state) => state?.routeCategoryId,
    label: "ID",
    active: true,
    type: "id",
  },
  {
    name: "routeCategoryName",

    value: (state) => state?.routeCategoryName,
    label: "Name",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) => (!value ? "Name is required" : undefined),
    },
  },
];
const UpdateRouteCategory = () => {
  const reducer = (data: typeof initialValues) => {
    return data;
  };
  const { data, refetch } = useGet("ROUTE_CATEGORY");
  return (
    <UpdateContainer
      name="route categories"
      title="Update route categories"
      subtitle="View, Add or Update route categories"
      endpoint="ROUTE_CATEGORY"
      idName="routeCategoryId"
      skip={["resourceCategoryName", "routePath", "routeActive"]}
      config={config}
      initialValues={initialValues}
      data={data}
      refetch={refetch}
      reducer={reducer}
    />
  );
};

export default UpdateRouteCategory;
