import { TableCell } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ActionButton from "../utils/ActionButton";

interface RowActionsProps {
  onEditButton: Function;
  onDeleteButton: Function;
  [key: string]: any;
  colId: number;
}

export const RowActions = ({
  onEditButton,
  onDeleteButton,
  colId,
  buttonDisable,
}: RowActionsProps) => {
  return (
    <TableCell key={colId}>
      {(() => {
        if (buttonDisable) {
          return (
            <div>
              <ActionButton onClick={onEditButton}>
                <EditOutlinedIcon fontSize="small" />
              </ActionButton>
              <ActionButton color="default"></ActionButton>
            </div>
          );
        } else {
          return (
            <div>
              <ActionButton onClick={onEditButton}>
                <EditOutlinedIcon fontSize="small" />
              </ActionButton>
              <ActionButton color="default" onClick={onDeleteButton}>
                <CloseIcon fontSize="small" />
              </ActionButton>
            </div>
          );
        }
      })()}
    </TableCell>
  );
};
