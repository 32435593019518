import { useQuery, useMutation } from "react-query";
import { ENDPOINTS } from "../endpoints";
import { fetchWrap } from "../utils/";

export const useGet = <T,>(
  endpoint?: keyof typeof ENDPOINTS | undefined,
  isEnabled?: boolean
) =>
  useQuery<T[], Error>(
    endpoint as keyof typeof ENDPOINTS,
    () => fetchWrap<T>(ENDPOINTS[endpoint as keyof typeof ENDPOINTS], "GET"),
    { enabled: isEnabled }
  );

export const useMutate = <T,>(
  endpoint: keyof typeof ENDPOINTS,
  method: "PUT" | "POST" | "DELETE",
  onComplete: (
    data: T[],
    variables: { body?: T; id?: number },
    context: unknown
  ) => void | Promise<unknown>,
  onError: (
    error: Error,
    variables: { body?: T; id?: number },
    context: unknown
  ) => void | Promise<unknown>
) =>
  useMutation<T[], Error, { body?: T; id?: number }>(
    [endpoint],
    ({ body, id }) => fetchWrap<T>(ENDPOINTS[endpoint], method, body, id),
    {
      onSuccess: onComplete,
      onError: onError,
    }
  );
