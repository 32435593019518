import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react'
import Controls from '../../utils/controls/Controls';
import { useForm, Form } from '../../form/useForm';
import MuiButton from '../../utils/Button';


const initialValues = {
    tableColumnId: 0,
    tableName: "",
    tableColumnVisible: true,
    tableColumnValue: "",
    tableColumnValueType: "",
    tableColumnLabel: "",
    tableColumnDescription: "",
    tableColumnOrder: 1
}

export default function UpdateTableColForm(props) {

    const { addOrEdit, recordForEdit, disableContent } = props
    const validate = (fieldValues = values) => {

        let temp = { ...errors }

        if ('tableName' in fieldValues){
            temp.tableName = fieldValues.tableName ? "" : "This field is required."
        }
        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x === "")
        }
        
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(recordForEdit ? recordForEdit : initialValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
      if (recordForEdit != null){
        setValues({ ...recordForEdit })
      }     
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={4}>
              <Controls.Input
                  variant="outlined"
                  label="Table Name"
                  name="tableName"
                  value={values.tableName}
                  onChange={handleInputChange}
                  error={errors.tableName}
                  margin="dense"
                  disabled={disableContent}
              />
              <Controls.Input
                  variant="outlined"
                  label="Column Description"
                  name="tableColumnDescription"
                  value={values.tableColumnDescription}
                  onChange={handleInputChange}
                  error={errors.tableColumnDescription}
                  margin="dense"
                  disabled={disableContent}
              />              
              <Controls.Checkbox
                  name="tableColumnVisible"
                  label="Visible"
                  value={values.tableColumnVisible}
                  onChange={handleInputChange}
                  disabled={disableContent}
              />
          </Grid>

          <Grid item xs={4}>                    
              <Controls.Input
                  variant="outlined"
                  label="Column Name"
                  name="tableColumnValue"
                  value={values.tableColumnValue}
                  onChange={handleInputChange}
                  error={errors.tableColumnValue}
                  margin="dense"
                  disabled={disableContent}
              />
              <Controls.Input
                  variant="outlined"
                  label="Column Value Type"
                  name="tableColumnValueType"
                  value={values.tableColumnValueType}
                  onChange={handleInputChange}
                  error={errors.tableColumnValueType}
                  margin="dense"
                  disabled={disableContent}
              />
          </Grid>
          <Grid item xs={4}>                    
              <Controls.Input
                  variant="outlined"
                  label="Column Label"
                  name="tableColumnLabel"
                  value={values.tableColumnLabel}
                  onChange={handleInputChange}
                  error={errors.tableColumnLabel}
                  margin="dense"
                  disabled={disableContent}
              />
              <Controls.Input
                  variant="outlined"
                  label="Order"
                  name="tableColumnOrder"
                  value={values.tableColumnOrder}
                  onChange={handleInputChange}
                  error={errors.tableColumnOrder}
                  margin="dense"
                  disabled={disableContent}
              />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {disableContent? <div> </div> : 
              <div>
                  <MuiButton text="Submit" type="submit"/>
                  <MuiButton
                      variant="contained"
                      color="default"
                      size="large"
                      text="Reset"
                      onClick={resetForm}
                  />
              </div>
            }                    
          </Grid>
        </Grid>
      </Form>
    )
}
