import {
  Paper,
  Grid,
  CircularProgress,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  useTheme,
  Divider,
  Checkbox,
  Typography
} from "@material-ui/core";
import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useReducer,
  useRef
} from "react";

import DataTable from "../../utils/DataTable";
import Notification from "../../utils/Notification";
import ConfirmDialog from "src/components/utils/ConfirmDialog";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useMutate } from "src/hooks/query";
import { getAutorenewedToken } from "src/components/auth/msalUtils";
import {
  humanFileSize,
  UploadProgressBar,
} from "../../utils/BlobService";

const useStyles = makeStyles((theme) => ({

  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
 
  hover: {
    "&:hover": {
      backgroundColor: "#e8e9eb",
      cursor: "pointer",
    },
    "&:click": {
      borderColor: theme.palette.text.primary,
    },
    flexDirection:'column'
    //borderColor: theme.palette.background.light,
    //backgroundColor: theme.palette.background.dark,
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 18,
  },
  labeltext:{
    margin:theme.spacing(4)
  }
}));
const fileConfig = [
  {
    id: 0,
    value: "fileName",
    label: "File Name",
    active: true,
    delete: true,
    disableSorting: false,
  },
  {
    id: 1,
    value: "filePath",
    label: "File",
    active: true,
    delete: true,
    disableSorting: false,
  },
  {
    id: 2,
    value: "fileSize",
    label: "File Size",
    active: true,
    delete: true,
    disableSorting: false,
  },
  {
    id: 3,
    value: "uploadTime",
    label: "Uploaded At",
    active: true,
    delete: true,
    disableSorting: false,
  },
  {
    id: 4,
    value: "Action",
    label: "",
    active: true,
    delete: false,
    disableSorting: false,
  },
];

export default function UpdatePortalLogo(){
  const classes = useStyles();
  const globalTheme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [file, setFile] = useState();

  const [imageDimensions, setImageDimensions] = useState({
    height:36,
    width:182
  });
  
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title:"Are you sure you want to delete this record?",
    subtitle: "You can't undo this operation",
    type: "",
  });
  const [records, setRecords] = useState(null);
  const activeHeaders = fileConfig.filter((header) => header.active);

  const stateRef = useRef();

  stateRef.current = records;

  async function getPortalAllowedPath() {
    return fetch(
      process.env.REACT_APP_API_PATH +
        "/portallogo/portal/allowedpaths",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      const datavalue = data.join(";").toString();
      return datavalue;
    });
  }

  async function getPortalAllowedFileTypes() {
    return fetch(
      process.env.REACT_APP_API_PATH +
        "/portallogo/portal/allowedfileformats",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  }

  const getFileList = async () => {
    setIsLoading(true);
    const allowedPath = await getPortalAllowedPath();
    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
        "/portallogo?contianerName=" +
        allowedPath,
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        setRecords(
          data[0].blobList.map((media) => ({
            fileName: media.name,
            uploadTime: new Date(media.uploadTime).toString(),
            filePath: media.url ? media.url : media.name,
            fileSize: humanFileSize(media.fileSize, true),
          }))
        );

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  
  
  /*const [progressObj, dispatch] = useReducer((state, action) => {
    if (action.type === "Delete") {
      delete state[action.value];
      return state;
    } else {
      return { ...state, ...action.value };
    }
  }, {});*/
 
  const onDrop = useCallback(async (acceptedFiles) => {
    if(stateRef.current === null || stateRef.current.length === 0)
    {
      if (acceptedFiles.length === 1) {
        const allowedFileTypes = await getPortalAllowedFileTypes();
        if (allowedFileTypes.includes(acceptedFiles[0].type)) {
          const allowedPath = await getPortalAllowedPath();
         // const allowedDimension = await getPortalAllowedFileDimension();
          const authorization = await getAutorenewedToken();
          const img = new Image();
          img.src = URL.createObjectURL(acceptedFiles[0]);
          img.onload = () => {
            if (img.height != imageDimensions.height || img.width != imageDimensions.width) {
              setNotify({
                isOpen: true,
                message: 'The image is not of accepted dimension',
                type: "error",
              });
            }
            else {
              const formData = new FormData();
              formData.append("file", acceptedFiles[0]);
              const headerReqest = {
                method: "POST",
                headers:
                {
                  authorization: authorization,
                },
                body: formData
              }
              fetch(
                process.env.REACT_APP_API_PATH +
                "/portallogo?contianerName=" +
                allowedPath,
                headerReqest
              ).then(async (response) => {
                getFileList();
                const data = await response.json();
                // check for error response
                if (!response.ok) {
                  // get error message from body or default to response statusText
                  setNotify({
                    isOpen: true,
                    message: 'Failed to upload image',
                    type: "error",
                  });
                  const error = (data && data.message) || response.statusText;
                  return Promise.reject(error);
                }
                else {
                  setNotify({
                    isOpen: true,
                    message: 'Image uploaded successfully',
                    type: "success",
                  });
                }
              });
            }
          };
        } else {
          setNotify({
            isOpen: true,
            message: 'Please upload image in .png/jpeg format',
            type: "error",
          });
        }
      } else {
        setNotify({
          isOpen: true,
          message: "Please upload one image at a time",
          type: "error",
        });
      }

    }else {
      setNotify({
        isOpen: true,
        message: "Please delete the existing image",
        type: "error",
      });
    }
    
  }, []);

    
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  
  const style = useMemo(
    () => ({
      flex: 1,
      display: "flex",
      //flexDirection: "column",
      //flexDirection:"column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,

      borderStyle: "dashed",
      color: globalTheme.palette.text.secondary,
      outline: "none",
      transition: "border .24s ease-in-out",
      ...(isDragActive
        ? { borderColor: globalTheme.palette.primary.main }
        : {}),
      ...(isDragAccept
        ? { borderColor: globalTheme.palette.primary.main }
        : {}),
      ...(isDragReject
        ? { borderColor: globalTheme.palette.secondary.red }
        : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, globalTheme]
  );

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, null, records);

    const onDelete = async (row) => {
      const allowedPath = await getPortalAllowedPath();
      const authorization = await getAutorenewedToken();
      const headerReqest = {
        method: "DELETE",
        headers: 
        {
          authorization: authorization,
        },
      }
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      fetch(
        process.env.REACT_APP_API_PATH +
          `/portallogo?file=${row.fileName}&contianerName=` +
          allowedPath,
        headerReqest
      ).then(async (response) => {
        getFileList();
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          setNotify({
            isOpen: true,
            message: 'Failed to delete image',
            type: "error",
          });
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        } 
        else{
          setNotify({
            isOpen: true,
            message: 'Image deleted successfully',
            type: "success",
          });
        }
      });
    }
    
  useEffect(() => {

      getFileList();

    }, []);

  return (
    <Page title="Update Portal Logo">
      <PageHeader
        title="Update Portal Logo"
        subtitle="Customize portal logo"
        icon={<CloudUploadIcon />}
        disableBackButton={false}
      />

       <Notification notify={notify} setNotify={setNotify} />
       <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}></ConfirmDialog>
       <Paper className={classes.pageContent}>

       

        <Toolbar>
            <div {...getRootProps({ style })}  className={classes.hover}>
              <input
                {...getInputProps()}
                accept=".png,.jpeg,.jpg"
                type="file"
                multiple={false}
              />
              <p>
                <CloudUploadIcon />
              </p>
              <p>Drag and drop file here, or click to select files</p>
            </div>
        </Toolbar>
        <Divider />
        <Typography color="textSecondary" className={classes.labeltext} display="inline">Upload image of dimension 36x182 </Typography>
        {isLoading && (
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            spacing={0}
          >
            <CircularProgress />
          </Grid>
        )}
        {records !== null && records.length > 0? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row, idx) => (
                  <TableRow hover key={idx} value={row}>
                    {activeHeaders.map((col) => (
                      col.delete == true?(
                        <TableCell key={col.id}>{row[col.value]}</TableCell>
                      ):(
                        <TableCell key={col.id}>
                          <CloseIcon fontSize="small" onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "Are you sure you want to delete this record?",
                            subtitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(row);
                            },
                          });
                        }}/>
                        </TableCell>
                      )
                      
                    ))}
                     
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <br></br>
            <TblPagination />
          </>
        ) : (
          ""
        )}
       </Paper>
    </Page>
  )
}


