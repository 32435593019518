import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useMsal } from "@azure/msal-react";

import { Config } from "src/types/config";
import { RowActions } from "./RowActions";

interface RowProps {
  activeHeaders: Config[];
  row: any;
  idName: string;
  openInPopup: Function;
  setConfirmDialog: Function;
  onDelete: Function;
  selected?: Array<any>;
}

export const Row = ({
  activeHeaders,
  idName,
  row,
  openInPopup,
  setConfirmDialog,
  onDelete,
  selected,
}: RowProps) => {
  const { accounts } = useMsal();

  const useremail = () => {
    if (accounts && accounts?.length > 0) {
      return accounts[0]?.username;
    }
  };

  return (
    <TableRow hover key={`${idName}`}>
      {activeHeaders.map((col, idx) => {
        const isSelect = col.type === "select";
        const value =
          idx === 3 && idName === "resourceCategoryId"
            ? row.dashboardName
            : col?.options
            ? col?.options?.display(row)
            : col.value(row);
            
        const key = `${value}-${idx}`;

        switch (col.type) {
          case "checkbox":
            return (
              <TableCell key={key}>
                {value ? (
                  <CheckIcon style={{ color: "green" }} />
                ) : (
                  <ClearIcon style={{ color: "red" }} />
                )}
              </TableCell>
            );
          case "collapse":
            return (
              <TableCell component="th" scope="row" key={key}>
                <Checkbox
                  checked={selected?.includes(value)}
                  inputProps={{
                    "aria-labelledby": value,
                  }}
                />
              </TableCell>
            );
          case "actions":
            return (
              <RowActions
                onEditButton={() => openInPopup(row, "edit")}
                onDeleteButton={() =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure you want to delete this record?",
                    subtitle: "You can't undo this operation",
                    onConfirm: () => {
                      onDelete(row[idName]);
                    },
                  })
                }
                buttonDisable={
                  (idName === "userId" && row.userName === useremail()) ||
                  (idName === "userId" && !row.userActive)
                    ? true
                    : false
                }
                colId={idx}
                key={key}
              />
            );
          default:
            return (
              <TableCell
                key={key}
                onClick={() => {
                  openInPopup(row);
                }}
              >
                {value}
              </TableCell>
            );
        }
      })}
      <RowActions
        onEditButton={() => openInPopup(row, "edit")}
        onDeleteButton={() =>
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure you want to delete this record?",
            subtitle: "You can't undo this operation",
            onConfirm: () => {
              onDelete(row[idName]);
            },
          })
        }
        buttonDisable={
          (idName === "userId" && row.userName === useremail()) ||
          (idName === "userId" && !row.userActive)
            ? true
            : false
        }
        colId={99}
        key={1}
      />
    </TableRow>
  );
};
