import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField, Popover, Typography, makeStyles, Box, IconButton } from '@material-ui/core';
import ChatPage from '../chat/ChatPage';
import ChatTopBar from '../chat/ChatTopBar';
import ChatBox from '../chat/ChatBox';
import { ChatMessage, GPTMessage } from '../chat/ChatMessage';
import ChatInput from '../chat/ChatInput';
import { msalConfig } from '../auth/authConfig';
import { useMsal } from '@azure/msal-react';
import RedditIcon from "@material-ui/icons/Reddit";

const useStyles = makeStyles((theme) => ({
  chatButton: {
    margin: theme.spacing(1),
  },
  messageContainer: {
    padding: theme.spacing(2),
    maxWidth: '300px',
    minWidth: '200px',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  circularButton: {
    borderRadius: '50%',
    width: theme.spacing(8),
    height: theme.spacing(8),
    background: "#26890D",
    '&:hover': {
      backgroundColor: "#26890D", // Set hover background color to transparent
      animation: '$glow 1.5s infinite alternate',
      boxShadow: '0 0 5px 5px #26890D',
    },
  },
  icon:{
    width: theme.spacing(4),
    height: theme.spacing(4),
    color:"white"
  },
}));

type ChatbotProps = {
  onSumbit:(value:string) => ""
}
const Chatbot = (props:ChatbotProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const { instance, accounts, inProgress } = useMsal();
  const [initials, setInitials] = useState<string>("?");
  const messageInputRef = useRef<HTMLInputElement | null>(null);
  const apiScope = msalConfig.auth.clientId + "/.default";
  const [devMode, setDevMode] = useState(false);
  const handleMessageSubmit = (message) => {
    setMessages([...messages, { text: message, sender: 'user' }]);
    // Here, you can process the user's message and generate a response
  };

  const handleMessageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSlideClose = () => {
    setAnchorEl(null);
};
useEffect(() => {
  if (
    chatHistory.length >= 1 &&
    chatHistory[chatHistory.length - 1].role === "user"
  ) {
    const getResponse = async () => {
      const lastMsg = chatHistory[chatHistory.length - 1].message;
      const jsonData = { prompt: lastMsg };
      const message: GPTMessage = {
        message: "",
        role: "llm",
        name: "IMCP ASSIST",
        tokens: 0,
        price: 0,
        date: new Date()
      };
      setChatHistory([...chatHistory, message]);
      try {
        const apiScope = msalConfig.auth.clientId + "/.default";
        const accessTokenRequest = {
          scopes: [`${apiScope}`],
          account: accounts[0],
        };
        const token = await instance.acquireTokenSilent(accessTokenRequest);
        const url: string | undefined = process.env.REACT_APP_API_PATH + "/imcpassist";
        if (url === undefined) {
          throw new Error("API URL is not defined");
        }
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token.accessToken,
          },
          body: JSON.stringify(jsonData),
          //body: lastMsg
        });
        if (!response.ok) {
          throw new Error();
        } else {
          const data = await response.json();
          console.log (data);
          const message: GPTMessage = {
            message: data.response,
            role: "llm",
            name: "IMCP ASSIST",
            tokens: data.tokens,
            price: data.price,
            date: new Date()
          };
          setChatHistory([...chatHistory, message]);
        }
      } catch {
        const message: GPTMessage = {
          message: "IMCP ASSIST had an error. Try again, or rephrase your question.",
          role: "llm",
          name: "IMCP ASSIST",
          tokens: 0,
          price: 0,
          date: new Date()
        };
        setChatHistory([...chatHistory, message]);
      }
    };

    getResponse();
  }
}, [chatHistory]);

  const handleEnterMessage = async (e: KeyboardEvent | MouseEvent) => {
    const lastChat = chatHistory[chatHistory.length - 1];
    if ((e.key === "Enter" && !e.shiftKey) || e.type == "click") {
      if (messageInputRef?.current?.value != "" && messageInputRef?.current?.value != null) {
        if (!lastChat || lastChat.role !== "user") {
          // If user can send a message, add to chatHistory
          if (messageInputRef.current) {
            const input = messageInputRef.current.value;
            const newMessage = {
              message: input,
              role: "user",
              name: initials,
              date: new Date()
            };
            setChatHistory([...chatHistory, newMessage]);
            messageInputRef.current.value = "";
            e.preventDefault(); // prevent new line on enter
          }
        }
      }
      e.preventDefault();
    }

  };

function toggleDevMode() {
  setDevMode(!devMode);
}
useEffect(() => {
  if (accounts && accounts.length > 0 && accounts[0]) {
    const initials = accounts[0].name
      .split(", ")
      .map((name) => name[0])
      .join("");
    setInitials(initials);
  }
}, [accounts]);
  const open = Boolean(anchorEl);

  return (
    <div>
      {/* <Button className={classes.chatButton} variant="contained" color="primary" onClick={handleMessageClick}>
        Open Chat
      </Button> */}
      <IconButton
        className={classes.circularButton}
        //color="primary"
        onClick={handleMessageClick}
      >
        {/* <RedditIcon className={classes.icon} /> */}
        <img
          // style={{ maxWidth: "100%", width: "auto", height: "auto" }}
          alt="Logo"
          src="/static/images/auto_awesome_white.svg"
        // {...props}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { marginTop: '-2%', borderRadius:"20px", boxShadow:"rgba(0, 0, 0, 0.5) 0px 0px 10px 2px"},
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ChatTopBar toggleDevMode={toggleDevMode} closeSlide={handleSlideClose} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "65vh",
            width: "55vh"
          }}
        >
          <ChatBox chatHistory={chatHistory} devMode={devMode} />
        </Box>
        <Box
          sx={{
            paddingLeft: "2%",
            paddingRight: "2%",
            paddingBottom: "2%",
          }}
        >
          <ChatInput
            handleEnterMessage={handleEnterMessage}
            messageInputRef={messageInputRef}
          />
        </Box>
      </Popover>
    </div>
  );
};

export default Chatbot;