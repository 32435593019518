import React from "react";
import {
  FormLabel,
  FormControl,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

export default function RadioGroup(props) {
  const { name, label, value, onChange, items, error } = props;

  return (
    <FormControl error helperText={name ? error[name] : null}>
      <FormLabel>{label} </FormLabel>
      <MuiRadioGroup row name={name} value={value} onChange={onChange}>
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.value}
            control={<Radio color="primary" />}
            label={item.label}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
