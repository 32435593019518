import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react'
import Controls from '../../utils/controls/Controls';
import { useForm, Form } from '../../form/useForm';
import MuiButton from '../../utils/Button';


const initialValues = {
    sourceId: 0,
    sourceName: "",
    sourceGroup: "",
    sourceType: "",
    connectionStringSecret: "",
    folderPathRaw: "",
    folderPathCurated: "",
    folderPathConsumption: "",
    connectionStringSecretAsql: "",
    isActive: true,
    ingestionPriorityNo: 0,
    refreshInterval: "",
    autoIngest: true,
    asqlServiceObjective: "",
    asqlScaleUp: true,
    asqlEdition: "",
    asqlMaxSize: ""
}

export default function UpdateSourceForm(props) {

    const { addOrEdit, recordForEdit, disableContent } = props
    const validate = (fieldValues = values) => {

        let temp = { ...errors }

        if ('sourceName' in fieldValues){
            temp.sourceName = fieldValues.sourceName ? "" : "This field is required."
        }
        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x === "")
        }
        
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null){
            setValues({
                ...recordForEdit
            })
        }     
    }, [recordForEdit, setValues])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
            <Grid item xs={4}>
                    <Controls.Input
                        variant="outlined"
                        label="Source ID"
                        name="sourceId"
                        value={values.sourceId}
                        onChange={handleInputChange}
                        error={errors.sourceId}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Source Type"
                        name="sourceType"
                        value={values.sourceType}
                        onChange={handleInputChange}
                        error={errors.sourceType}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Folder Path Curated"
                        name="folderPathCurated"
                        value={values.folderPathCurated}
                        onChange={handleInputChange}
                        error={errors.folderPathCurated}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="isActive"
                        label="Active"
                        value={values.isActive}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="autoIngest"
                        label="Auto Ingest"
                        value={values.autoIngest}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="ASQL Edition"
                        name="asqlEdition"
                        value={values.asqlEdition}
                        onChange={handleInputChange}
                        error={errors.asqlEdition}
                        margin="dense"
                        disabled={disableContent}
                    />
                </Grid>

                <Grid item xs={4}>                    
                    <Controls.Input
                        variant="outlined"
                        label="Source Name"
                        name="sourceName"
                        value={values.sourceName}
                        onChange={handleInputChange}
                        error={errors.sourceName}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Connection String Secret"
                        name="connectionStringSecret"
                        value={values.connectionStringSecret}
                        onChange={handleInputChange}
                        error={errors.connectionStringSecret}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Folder Path Consumption"
                        name="folderPathConsumption"
                        value={values.folderPathConsumption}
                        onChange={handleInputChange}
                        error={errors.folderPathConsumption}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Ingestion Priority No"
                        name="ingestionPriorityNo"
                        value={values.ingestionPriorityNo}
                        onChange={handleInputChange}
                        error={errors.ingestionPriorityNo}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="ASQL Service Objective"
                        name="asqlService"
                        value={values.asqlServiceObjective}
                        onChange={handleInputChange}
                        error={errors.asqlServiceObjective}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="ASQL Max Size"
                        name="asqlMaxSize"
                        value={values.asqlMaxSize}
                        onChange={handleInputChange}
                        error={errors.asqlMaxSize}
                        margin="dense"
                        disabled={disableContent}
                    />
                </Grid>
                <Grid item xs={4}>                    
                    <Controls.Input
                        variant="outlined"
                        label="Source Group"
                        name="sourceGroup"
                        value={values.sourceGroup}
                        onChange={handleInputChange}
                        error={errors.sourceGroup}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Folder Path Raw"
                        name="folderPathRaw"
                        value={values.folderPathRaw}
                        onChange={handleInputChange}
                        error={errors.folderPathRaw}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Connection String Secret ASQL"
                        name="connectionStringSecretAsql"
                        value={values.connectionStringSecretAsql}
                        onChange={handleInputChange}
                        error={errors.connectionStringSecretAsql}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Refresh Interval"
                        name="refreshInterval"
                        value={values.refreshInterval}
                        onChange={handleInputChange}
                        error={errors.refreshInterval}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="asqlScaleUp"
                        label="ASQL Scale Up"
                        value={values.asqlScaleUp}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />
                </Grid>

                <Grid container item xs={12} justifyContent="flex-end">
                    {disableContent? <div> </div> : 
                        <div>
                            <MuiButton
                                    text="Submit"
                                    type="submit"
                            />
                            <MuiButton
                                variant="contained"
                                color="default"
                                size="large"
                                text="Reset"
                                onClick={resetForm}
                            />
                        </div>
                    }                    
                </Grid>
            </Grid>
        </Form>
    )
}
