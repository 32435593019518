import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
// import ActionButton from '../../utils/ActionButton';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    margin: 1,
    padding: theme.spacing(2),
  },
  iconButton: {
    float: "right",
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    // bottom: theme.spacing(1),
    // color: theme.palette.grey[500],
  },
}));

export default function Popup(props) {
  const {
    title,
    children,
    openPopup,
    setOpenPopup,
    disableContent,
    openInPopup,
    row,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      classes={{ paper: classes.dialogWrapper }}
    >
      <Grid container>
        <Grid item xs={10}>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant="h4">{title}</Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              setOpenPopup(false);
            }}
            className={classes.iconButton}
            aria-label="close"
            size="medium"
            variant="extended"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          {/* <IconButton onClick={toggleDeleteModal} aria-label="close" size="medium" variant="extended" style={{ float: 'right' }}>
                        <DeleteForeverTwoToneIcon fontSize="inherit" />
                    </IconButton> */}

          {disableContent ? (
            <IconButton
              onClick={() => {
                openInPopup(row, "edit");
              }}
              className={classes.iconButton}
              aria-label="edit"
              size="medium"
              variant="extended"
            >
              <EditOutlinedIcon fontSize="inherit" />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
