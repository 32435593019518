import React from "react";
import { useLocation } from "react-router";
import {
  Button,
  Popover,
  IconButton,
  TextField,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { makeStyles } from "@material-ui/core/styles";
import { getAutorenewedToken } from "../../auth/msalUtils";

import rgbConverter from "../../../utils/rgbConverter";



export default function Feedback() {
  
  const location = useLocation();
  const initialState = {
    data: {
      subject: "",
      userFeedback: "",
      url: location?.patjmame,
    },
    errors: null,
    open: false,
  };

  const [state, setState] = React.useState(initialState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "feedback-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //THIS NEEDS TO POST AN API TO THE LOCATION WHERE FEEDBACK IS STORED
  const handleSubmit = async () => {
    fetch(process.env.REACT_APP_API_PATH + "/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: await getAutorenewedToken(),
      },
      body: JSON.stringify({
        feedbackId: 0,
        userFeedback: state.data.userFeedback,
        subject: state.data.subject,
      }),
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          setState(initialState);
          handleClose();
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          alert("Error happened while updating feed");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        alert("An error while calling API to update feed!");
      });
  };

  return (
    <Tooltip title="Send Feedback" arrow>
    <Box>
      <IconButton
        style={{ fill: "greyColor" }}
        //ref={anchorRef}
        onClick={handleClick}
      >
        <ChatBubbleOutlineIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height="100%" display="flex" flexDirection="column" padding="15px">
          <Box alignItems="right" display="flex" flexDirection="column">
            <Typography color="textSecondary" variant="body2" margin="normal">
              Please provide us with your feedback
            </Typography>

            <TextField
              error={state.errors && state.errors.subject}
              label="Subject"
              variant="outlined"
              value={state.data.subject}
              onChange={(e) =>
                setState({
                  ...state,
                  data: { ...state.data, subject: e.target.value },
                })
              }
              helperText={state.errors && state.errors.subject}
              margin="dense"
            />

            <TextField
              label="Feedback"
              variant="outlined"
              multiline
              rows="5"
              value={state.data.userFeedback}
              onChange={(e) =>
                setState({
                  ...state,
                  data: { ...state.data, userFeedback: e.target.value },
                })
              }
              helperText={state.errors && state.errors.userFeedback}
              margin="normal"
            />
            <Button
              onClick={handleSubmit}
              variant="outlined"
              color="primary"
              margin="normal"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
    </Tooltip>
  );
}
