import UpdateContainer from "../UpdateContainer";
import { useGet } from "../../../hooks";
import { Config } from "../../../types/config";

const initialValues = {
  resourceCategoryId: 0,
  resourceCategoryName: "",
  resourceCategoryIcon: "",
  defaultDashboardId: "",
};

const config: Config[] = [
  {
    name: "resourceCategoryId",
    value: (state) => state?.resourceCategoryId,
    label: "Id",
    active: true,
    type: "id",
  },
  {
    name: "resourceCategoryName",
    value: (state) => state?.resourceCategoryName,
    label: "Name",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) => (!value ? "Name is required" : undefined),
    },
  },
  {
    name: "resourceCategoryIcon",
    value: (state) => state?.resourceCategoryIcon,
    label: "Icon",
    active: true,
    type: "input",
  },
  {
    name: "defaultDashboardId",
    value: (state) => state?.defaultDashboardId,
    options: {
      endpoint: "DASHBOARDS",
      map: (itm: any) => ({
        id: itm.dashboardId.toString(),
        title: itm.dashboardName,
      }),
      display: (state: any) => state?.dashboard?.dashboardName,
    },
    label: "Dashboard",
    active: true,
    type: "select",
  },
];

const UpdateResourceCategory = () => {
  const reducer = (data: typeof initialValues) => {
    return data;
  };
  const { data, refetch } = useGet("RESOURCE_CATEGORY");
  return (
    <UpdateContainer
      name="Resource Categories"
      title="Update Resource Categories"
      subtitle="View, Add or Update resource categories"
      endpoint="RESOURCE_CATEGORY"
      idName="resourceCategoryId"
      skip={["resourceCategoryName", "routePath", "routeActive"]}
      config={config}
      initialValues={initialValues}
      data={data}
      refetch={refetch}
      reducer={reducer}
    />
  );
};
export default UpdateResourceCategory;
