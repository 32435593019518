import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// test
import Page from "src/components/utils/Page";
import { Link } from "react-router-dom";
import { getAutorenewedToken } from "../auth/msalUtils";
import { setAdimIconName } from "../utils/RouterStorage";
import rgbConverter from "../../utils/rgbConverter";
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(0),
  },
  content: {
    maxWidth: "94%",
    padding: "0 3%",
  },
  cardRoot: {
    "&:hover": { 
      background: "#e0e0e0" ,
    },
  },
}));

function AdminList() {
  const classes = useStyles();
  const [admin, setAdmin] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadAdminListData = async () => {
      fetch(process.env.REACT_APP_API_PATH + "/admin/active", {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      })
        .then(async (response) => {
          const data = await response.json();
          
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ? process.env.REACT_APP_MIXPANEL_TOKEN : "")
          mixpanel.track_pageview({user_id:window.sessionStorage.getItem('USER_ID')});

          //check for REACT_APP_SHOW_DCC environment variable to display DCC when its set to true
          let showDCC =
            "REACT_APP_SHOW_DCC" in process.env
              ? process.env.REACT_APP_SHOW_DCC === "true"
                ? true
                : false
              : false;

          //check for REACT_APP_SHOW_TABLECOLUMN environment variable to display Table/TableColumn when its set to true
          let showTableColumn =
            "REACT_APP_SHOW_TABLECOLUMN" in process.env
              ? process.env.REACT_APP_SHOW_TABLECOLUMN === "true"
                ? true
                : false
              : false;

          //filtering data to show active and enabled routes from environment variables
          let adminConsole = data.filter((item) =>
            item.adminName === "Update DCC"
              ? showDCC && item.route && item.route.routeActive
              : item.adminName === "Update Table"
              ? showTableColumn && item.route && item.route.routeActive
              : item.adminName === "Update Table Columns"
              ? showTableColumn && item.route && item.route.routeActive
              : item.route && item.route.routeActive
          );
          let values = [],
            result = [];
          for (let i = 0; i < adminConsole.length; i++) {
            const val = adminConsole[i]["adminTitle"];
            const index = values.indexOf(val);
            if (index > -1) result[index]["config"].push(adminConsole[i]);
            else {
              values.push(val);
              let newTitle = {};
              newTitle.adminTitle = val;
              newTitle.config = [adminConsole[i]];
              result.push(newTitle);
            }
          }
          setAdmin(result);
          setIsLoaded(true);
        })
        .catch((error) => {
          alert("There was an error while getting admin items!");
        });
    };

    loadAdminListData();
  }, []);

  return (
    <Page className={classes.root} title="Admin">
      {isLoaded ? (
        <Container maxWidth={false}>
          <Box pt={3}>
            <Grid container>
              {admin.map((items, i) => (
                <Grid container key={"container" + items.adminTitle + i}>
                  <Grid
                    item
                    key={"title" + items.adminTitle + i}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Typography variant="h5">{items.adminTitle}</Typography>
                  </Grid>
                  <Grid
                    key={"items" + items.adminTitle + i}
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Grid container>
                      {items.config.map((card) => (
                        <Grid
                          key={card.adminName}
                          item
                          xl={3}
                          lg={4}
                          md={6}
                          sm={12}
                          xs={12}
                          style={{ padding: 8 }}
                        >
                          <Link
                            to={card.route.routePath}
                            onClick={card.onClick || ((_) => {})}
                            style={{
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            <Card
                              classes={{ root: classes.cardRoot }}
                              style={{ borderRadius: 10, boxShadow:"none" }}
                              onClick={() => {
                                setAdimIconName(card.adminIcon);
                              }}
                            >
                              <CardHeader
                                avatar={
                                  <FontAwesomeIcon
                                    icon={["fas", card.adminIcon]}
                                    style={{ fontSize: 18, opacity:0.54 }}
                                  />
                                }
                                classes={{ content: classes.content }}
                                title={card.adminName}
                                titleTypographyProps={{
                                  variant: "h5",
                                  style: {
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    fontSize: "18px",
                                  },
                                }}
                                subheaderTypographyProps={{
                                  style: {
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%",
                                    opacity:"0.8"
                                  },
                                }}
                                subheader={card.adminDescription}
                              />
                            </Card>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                    <br />
                    <br />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      ) : (
        <CircularProgress size={84} className={"fabProgress"} />
      )}
    </Page>
  );
}

export default AdminList;
