import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';

const TEXT_COLOR_SESSION_STORAGE_KEY = 'TEXT_COLOR';

export const theme = createTheme({
  palette: {
    background: {
      dark: '#F2F4F7',
      light: '#f8f9fa',
      default: colors.common.white,
      header: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#26890D', //THIS IS THE DELOITTE GREEN
     // main: '#86BC25', //THIS IS THE DELOITTE GREEN
      greyColor: '#97999B' //THIS IS THE DELOITTE GREY
    },
    secondary: {
      main: '#fffff',
      red: '#f83245',
      lightRed: '#f8324526',
      hoverOverColour: '#d8ebb5' //THIS IS THE HOVEROVER COLOUR FOR TABLES
    },
    text: {
      primary: colors.blueGrey[900], // #263238
      secondary: colors.blueGrey[600], // #546E7A
    },
    whiteColor: {
      main: '#fff'
    },
    blackColor: {
      main: '#000'
    }
  },
  shadows,
  typography: {
    fontFamily: 'OpenSansRegular, Helvetica, sans-serif'
  }
});
