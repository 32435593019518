import { getAutorenewedToken } from '../auth/msalUtils';
import { useState, useEffect } from 'react';

const CURRENT_ROUTE_PATH_SESSION_STORAGE_KEY = 'CURRENT_ROUTE_PATH';
const ROUTE_PATH_LIST_SESSION_STORAGE_KEY = 'ROUTE_PATH_LIST';
const ADMIN_ICON_NAME_SESSION_STORAGE_KEY = 'ADMIN_ICON_NAME';

export const storeCurrentPath = () => {
  window.sessionStorage.setItem(
    CURRENT_ROUTE_PATH_SESSION_STORAGE_KEY,
    window.location.pathname
  );
};

export const getStoredPath = () => {
  return window.sessionStorage.getItem(CURRENT_ROUTE_PATH_SESSION_STORAGE_KEY);
};

export const clearStoredPath = () => {
  window.sessionStorage.removeItem(CURRENT_ROUTE_PATH_SESSION_STORAGE_KEY);
};

export const storeRoutePathList = (inList) => {
  window.sessionStorage.setItem(ROUTE_PATH_LIST_SESSION_STORAGE_KEY, inList);
};

export const getRoutePathList = () => {
  return window.sessionStorage.getItem(ROUTE_PATH_LIST_SESSION_STORAGE_KEY);
};

export const clearRoutePathList = () => {
  window.sessionStorage.removeItem(ROUTE_PATH_LIST_SESSION_STORAGE_KEY);
};

export const setAdimIconName = (inName) => {
  window.sessionStorage.setItem(ADMIN_ICON_NAME_SESSION_STORAGE_KEY, inName);
};

export const getAdminIconName = () => {
  return window.sessionStorage.getItem(ADMIN_ICON_NAME_SESSION_STORAGE_KEY);
};

export const fetchRoutePathList = async () => {
  const response = await fetch(process.env.REACT_APP_API_PATH + '/route', {
    method: 'GET',
    headers: {
      authorization: await getAutorenewedToken(),
      'Content-Type': 'application/json'
    }
  });
  const data = response.json();
  // check for error response
  if (!response.ok) {
    // get error message from body or default to response statusText
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  return data;
};
