import React from "react";
import UpdateContainer from "../UpdateContainer";
import { useGet } from "../../../hooks";
import { Config } from "../../../types/config";

const userConfig: Config[] = [
  {
    name: "userId",
    value: (state) => state?.userId,
    label: "ID",
    active: true,
    type: "id",
  },
  {
    name: "userName",
    value: (state) => state?.userName,
    label: "Email Address",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) => (!value ? "Email is required" : undefined),
    },
  },
  {
    name: "userFirstName",

    value: (state) => state?.userFirstName,
    label: "First Name",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "First Name is required" : undefined,
    },
  },
  {
    name: "userLastName",
    value: (state) => state?.userLastName,
    label: "Last Name",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Last Name is required" : undefined,
    },
  },
  {
    name: "resourceCategory.resourceCategoryId",
    value: (state) => state?.resourceCategory?.resourceCategoryId,
    options: {
      endpoint: "RESOURCE_CATEGORY",
      map: (itm: any) => ({
        id: itm.resourceCategoryId,
        title: itm.resourceCategoryName,
      }),
      display: (state: any) => state?.resourceCategory?.resourceCategoryName,
    },
    label: "Resource Category",
    active: true,
    type: "select",
    validation: {
      validation: (value: string) =>
        !value ? "Resource Category is required" : undefined,
    },
  },
  {
    name: "userActive",
    value: (state) => state?.userActive,
    label: "User Active",
    active: true,
    type: "checkbox",
  },
  {
    name: "addComment",
    value: (state) => state?.addComment,
    label: "Add Comment",
    active: true,
    type: "checkbox",
  },
];

const initialValues = {
  userId: 0,
  userName: "",
  userFirstName: "",
  resourceCategory: {
    resourceCategoryId: 0,
    resourceCategoryName: "name",
    resourceCategoryIcon: "icon",
  },
  userLastName: "",
  userActive: true,
};

const UpdateUserView = () => {
  const reducer = (data: typeof initialValues) => {
    return data;
  };
  const { data, refetch } = useGet("USER");

  return (
    <UpdateContainer
      name="user"
      title="User details"
      subtitle="View, Add or Update users"
      endpoint="USER"
      idName="userId"
      config={userConfig}
      initialValues={initialValues}
      skip={["resourceCategoryName", "routePath", "routeActive"]}
      data={data}
      refetch={refetch}
      reducer={reducer}
    />
  );
};

export default UpdateUserView;
