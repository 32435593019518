import React ,{ useEffect,
  useState,}from 'react';
import { getAutorenewedToken } from "src/components/auth/msalUtils";
const MENU_COLOR_SESSION_STORAGE_KEY = 'MENU_COLOR';
const TEXT_COLOR_SESSION_STORAGE_KEY = 'TEXT_COLOR';
const Logo = (props) => {
  const [records, setRecords] = useState(null);
  const [loading, setLoading] = useState(true);

  async function getPortalAllowedPath() {
    return fetch(
      process.env.REACT_APP_API_PATH +
        "/portallogo/portal/allowedpaths",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      const datavalue = data.join(";").toString();
      return datavalue;
    });
  }

  const getFileList = async () => {
    const allowedPath = await getPortalAllowedPath();
    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
        "/portallogo/logo?contianerName=" +
        allowedPath,
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        setRecords(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert("Unexpected error occured while loading logo.")
      });
  };
  useEffect(async () => {
    if(window.sessionStorage.getItem(MENU_COLOR_SESSION_STORAGE_KEY) && window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY))
      await getFileList();
  }, []);
  let imageURL = "/static/" + (process.env.REACT_APP_LOGO_NAME ? process.env.REACT_APP_LOGO_NAME : "IMCPDeloitte.svg");
    return (
      <>
        {!loading && (
          <img
           style={{maxWidth:"100%", width:"auto", height:"auto"}}
            alt="Logo"
            src={(records?.contentType !== null && records?.base64 !== null) ? `data:${records?.contentType};base64,${records?.base64}` : imageURL}
            {...props}
          />
        )}
      </>
  );
};

export default Logo;
