import { makeStyles } from '@material-ui/core/styles';
import rgbConverter from "../../utils/rgbConverter";

const TEXT_COLOR_SESSION_STORAGE_KEY = 'TEXT_COLOR';
const drawerWidth = 270;
const LayoutStyles = makeStyles(theme=> ({
  root: {
    display: "flex"
  },
  avatar: {
    width: 60,
    height: 60
  },
  avatarClosed: {
    width: 30,
    height: 30,
    easing: theme.transitions.easing.sharp, 
    duration: theme.transitions.duration.leavingScreen,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: "0px",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  grow: {
    flexGrow: 1
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.dark
  },
  active: {
    "&:hover": {
      color: window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) ? window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) : theme.palette.text.secondary,
      backgroundColor: rgbConverter.getHover() ,
    },
    color: window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) ? window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) : theme.palette.text.primary,
    
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));

export default LayoutStyles
