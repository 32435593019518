import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// test
import Page from "src/components/utils/Page";
import { Link } from "react-router-dom";
import { getAutorenewedToken } from "../auth/msalUtils";
import { setAdimIconName } from "../utils/RouterStorage";
import rgbConverter from "../../utils/rgbConverter";
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(0),
  },
  content: {
    maxWidth: "94%",
    padding: "0 3%",
  },
  cardRoot: {
    "&:hover": { 
      background: "#e0e0e0" ,
    },
  },
}));

function ToolBox() {
  const classes = useStyles();
  const [admin, setAdmin] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  

  return (
    <Page className={classes.root} title="Admin">
      <Container maxWidth={false}>
        <Box pt={3}>
          <Grid container>
            <Grid container key={"container" + 1}>
              <Grid
                item
                key={"title" + 1}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Typography variant="h5">{"Analysis Tools"}</Typography>
              </Grid>
              <Grid
                key={"items" + 2}
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Grid container>
                  <Grid
                    key={"PCDS"}
                    item
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ padding: 8 }}
                  >
                    <Link
                      to={"/toolbox/pdcs"}
                      //onClick={card.onClick || ((_) => {})}
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Card
                        classes={{ root: classes.cardRoot }}
                        style={{ borderRadius: 10, boxShadow: "none" }}
                      // onClick={() => {
                      //   setAdimIconName(card.adminIcon);
                      // }}
                      >
                        <CardHeader
                          avatar={
                            <FontAwesomeIcon
                              icon={["fas", "tools"]}
                              style={{ fontSize: 18, opacity: 0.54 }}
                            />
                          }
                          classes={{ content: classes.content }}
                          title={"PDCS"}
                          titleTypographyProps={{
                            variant: "h5",
                            style: {
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontSize: "18px",
                            },
                          }}
                          subheaderTypographyProps={{
                            style: {
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                              opacity: "0.8",
                              textWrap:"wrap"
                            },
                          }}
                          subheader={"Project Delivery Model & Contract Stratergy Analysis Tool"}
                        />
                      </Card>
                    </Link>
                  </Grid>
                </Grid>
                <br />
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}

export default ToolBox;
