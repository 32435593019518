import React, { useEffect, useState } from 'react';

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  TableContainer
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Page from 'src/components/utils/Page';
import PageHeader from 'src/components/utils/PageHeader';
import DataTable from '../../utils/DataTable';
import ActionButton from '../../utils/ActionButton';
import Popup from 'src/components/utils/Popup';
import UpdateColumnForm from './UpdateDatasetColForm';
import Notification from 'src/components/utils/Notification';
import ConfirmDialog from 'src/components/utils/ConfirmDialog';
import Searchbar from 'src/components/utils/Searchbar';
import MaterialButton from 'src/components/utils/Button';
import { getAutorenewedToken } from '../../auth/msalUtils';
import { getAdminIconName } from '../../utils/RouterStorage';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  searchInput: {
    width: '25%'
  },
  newButton: {
    position: 'absolute',
    right: '10px',
    fontSize: '14px'
  }
}));

const sourceConfig = [
  {
    id: 0,
    value: 'colId',
    label: 'Col ID',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 1,
    value: 'dataSetId',
    label: 'Dataset ID',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 2,
    value: 'columnName',
    label: 'Col Name',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 3,
    value: 'ordinalPosition',
    label: 'Ordinal Position',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 4,
    value: 'columnDefault',
    label: 'Column Default',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 5,
    value: 'isNullable',
    label: 'Nullable?',
    active: true,
    checkbox: true,
    disableSorting: false
  },
  {
    id: 6,
    value: 'dataType',
    label: 'Data Type',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 7,
    value: 'characterMaximumLength',
    label: 'Character Maximum Length',
    active: false,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 8,
    value: 'colLengthInf',
    label: 'Col Length Inf',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 9,
    value: 'colLengthRec',
    label: 'Col Length Rec',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 10,
    value: 'characterOctetLength',
    label: 'Character Octet Length',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 11,
    value: 'numericPrecision',
    label: 'Numeric Precision',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 12,
    value: 'numericPrecisionRadix',
    label: 'Numeric Precision Radix',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 13,
    value: 'numericScale',
    label: 'Numeric Scale',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 14,
    value: 'datetimePrecision',
    label: 'Datetime Precision',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 15,
    value: 'characterSetCatalog',
    label: 'Character Set Catalog',
    active: false,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 16,
    value: 'characterSetSchema',
    label: 'Character Set Schema',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 17,
    value: 'characterSetName',
    label: 'Character Set Name',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 18,
    value: 'collationCatalog',
    label: 'Collation Catalog',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 19,
    value: 'collationSchema',
    label: 'Collation Schema',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 20,
    value: 'collationName',
    label: 'Collation Name',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 21,
    value: 'domainCatalog',
    label: 'Domain Catalog',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 22,
    value: 'domainSchema',
    label: 'Domain Schema',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 23,
    value: 'domainName',
    label: 'Domain Name',
    active: false,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 24,
    value: 'isActive',
    label: 'Active?',
    active: true,
    checkbox: true,
    disableSorting: false
  },
  {
    id: 25,
    value: 'colPrimaryKey',
    label: 'Col Primary Key?',
    active: true,
    checkbox: true,
    disableSorting: false
  },
  {
    id: 26,
    value: 'colComments',
    label: 'Col Comments',
    active: true,
    checkbox: false,
    disableSorting: false
  },
  {
    id: 27,
    value: 'actions',
    label: 'Actions',
    active: true,
    checkbox: false,
    disableSorting: true
  }
];

const UpdateDataColView = () => {
  const classes = useStyles();

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState(null);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  //POPUP RELATED
  const [openPopup, setOpenPopup] = useState(false);
  const [disableContent, setDisableContent] = useState(false);
  const [popupTitle, setPopupTitle] = useState('Dataset Column Details');

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: ''
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: ''
  });

  const activeHeaders = sourceConfig.filter((header) => header.active);

  const [errorMessage, setErrorMessage] = useState('');

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, filterFn);

  const getColTableData = async () => {
    fetch(process.env.REACT_APP_API_PATH + '/dcc/datasetcolumns', {
      method: 'GET',
      headers: { authorization: await getAutorenewedToken() }
    })
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        setRecords(data);
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        console.error('There was an error!', error);
      });
  };

  const addNewColumn = async (inParam, inFn) => {
    fetch(process.env.REACT_APP_API_PATH + '/dcc/datasetcolumns/0', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: await getAutorenewedToken()
      },
      body: JSON.stringify(inParam)
    })
      .then((response) => {
        const isJson = response.headers
          .get('content-type')
          ?.includes('application/json');
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          inFn();
          setRecordForEdit(null);
          setOpenPopup(false);
          setDisableContent(false);

          getColTableData();
          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success'
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.error('Error happened while adding new dataset column', error);
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        console.error('An error while calling API to add new dataset column!', error);
      });
  };

  const updateColumn = async (inParam, inFn) => {
    fetch(process.env.REACT_APP_API_PATH + '/dcc/datasetcolumns', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: await getAutorenewedToken()
      },
      body: JSON.stringify(inParam)
    })
      .then((response) => {
        const isJson = response.headers
          .get('content-type')
          ?.includes('application/json');
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          inFn();
          setRecordForEdit(null);
          setOpenPopup(false);
          setDisableContent(false);

          getColTableData();
          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success'
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.error('Error happened while updating column', error);
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        console.error('An error while calling API to update column!', error);
      });
  };

  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    getColTableData();
  }, []);

  const openInPopup = (item, editMode) => {
    //setDisableContent(disabled)
    setRecordForEdit(JSON.parse(JSON.stringify(item)));

    if (editMode) {
      setPopupTitle('Edit Dataset Column');

      setDisableContent(false);
    } else {
      setPopupTitle('Dataset Column Details');

      setDisableContent(true);
    }

    setOpenPopup(true);
  };

  const addOrEdit = (inParam, resetForm) => {
    if (inParam.datasetColId === 0) {
      addNewColumn(inParam);
    } else {
      updateColumn(inParam);
    }
    setNotify({
      isOpen: true,
      message: 'Submitted Successfully',
      type: 'success'
    });
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });

    fetch(process.env.REACT_APP_API_PATH + '/dcc/datasetcolumns/' + id, {
      method: 'DELETE',
      headers: { authorization: await getAutorenewedToken() }
    })
      .then((response) => {
        const isJson = response.headers
          .get('content-type')
          ?.includes('application/json');
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          getColTableData();
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          console.error('Error happened while deleting column', error);
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        console.error('An error while calling API to delete column!', error);
      });

    setNotify({
      isOpen: true,
      message: 'Deleted Successfully',
      type: 'error'
    });
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === '') {
          return items;
        } else {
          return items.filter((data) =>
            Object.values(data).some((val) =>
              String(val)
                .toLowerCase()
                .includes(target.value.toString().toLowerCase())
            )
          );
        }
      }
    });
  };

  return (
    <Page title="View Dataset Columns">
      <PageHeader
        title="View Dataset Columns"
        subtitle="View, Add or Update dataset columns"
        icon={<FontAwesomeIcon icon={['fas', getAdminIconName()]} />}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <Searchbar handleSearch={handleSearch} title="Search column" />

          <MaterialButton
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setPopupTitle('Add new dataset column');
              setDisableContent(false);
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          />
        </Toolbar>
        {records !== null ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow hover key={row['colId']} value={row}>
                    {activeHeaders.map((col) =>
                      row[col.value] && col.checkbox === true ? (
                        <TableCell
                          key={col.id}
                          onClick={() => {
                            openInPopup(row);
                          }}
                        >
                          {' '}
                          <CheckIcon style={{ color: 'green' }} />{' '}
                        </TableCell>
                      ) : row[col.value] === false && col.checkbox === false ? (
                        <TableCell
                          key={col.id}
                          onClick={() => {
                            openInPopup(row);
                          }}
                        >
                          {' '}
                          <ClearIcon style={{ color: 'red' }} />{' '}
                        </TableCell>
                      ) : col.value === 'actions' ? (
                        <TableCell key={col.id}>
                          <ActionButton
                            onClick={() => {
                              openInPopup(row, 'edit');
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </ActionButton>
                          <ActionButton
                            color="default"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title:
                                  'Are you sure you want to delete this record?',
                                subtitle: "You can't undo this operation",
                                onConfirm: () => {
                                  onDelete(row['colId']);
                                }
                              });
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </ActionButton>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={col.id}
                          onClick={() => {
                            openInPopup(row);
                          }}
                        >
                          {row[col.value]}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <br></br>
            <TblPagination />
          </>
        ) : (
          <TableContainer></TableContainer>
        )}
        <p>{errorMessage}</p>
      </Paper>

      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title={popupTitle}
        disableContent={disableContent}
        openInPopup={openInPopup}
        row={recordForEdit}
      >
        <UpdateColumnForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          disableContent={disableContent}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Page>
  );
};

export default UpdateDataColView;
