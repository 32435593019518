import UpdateContainer from "../UpdateContainer";
import { Config } from "../../../types/config";
import { isPathValid, isUrlValid } from "../../../validation";
import { useGet } from "../../../hooks";
import { Dashboard, Route, ResourceCategory } from "src/types";
import { getOption, mapOptions } from "src/utils";

const initialValues = {
  dashboardId: 0,
  dashboardName: "",
  dashboardDescription: "",
  dashboardVersion: null,
  dashboardImageUrl: "",
  showNavigationBar: true,
  dashboardDisplayOrder: 0,
  resourceCategoryName: "",
  dashboardPowerBiUrl: "",
  route: {
    routeId: 0,
    routePath: "",
    componentType: "Dashboard",
    routeActive: true,
    routeCategory: {
      routeCategoryId: 3,
      routeCategoryName: "Dashboard",
    },
  },
  resourceCategory: {
    resourceCategoryId: 0,
    resourceCategoryName: "name",
    resourceCategoryIcon: "icon",
  },
};

const config: Config[] = [
  {
    value: (state) => state?.dashboardId,
    name: "dashboardId",
    label: "ID",
    active: true,
    type: "id",
  },
  {
    value: (state) => state?.dashboardName,
    name: "dashboardName",
    label: "Name",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Dashboard Name is required" : undefined,
    },
  },
  {
    value: (state) => state?.dashboardDescription,
    name: "dashboardDescription",
    label: "Description",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Description is required" : undefined,
    },
  },
  {
    value: (state) => state?.dashboardVersion,
    name: "dashboardVersion",
    label: "Version",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Version is required" : undefined,
    },
  },
  {
    value: (state) => state?.dashboardImageUrl,
    name: "dashboardImageUrl",
    label: "Image URL",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Image URL is required" : undefined,
    },
  },
  {
    value: (state) => state?.dashboardPowerBiUrl,
    name: "dashboardPowerBiUrl",
    label: "PowerBI URL",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "PowerBI URL is required" : undefined,
    },
  },
  {
    name: "resourceCategory.resourceCategoryId",
    value: (state) => state?.resourceCategory?.resourceCategoryId,
    options: {
      endpoint: "RESOURCE_CATEGORY",
      map: (itm: any) => ({
        id: itm.resourceCategoryId,
        title: itm.resourceCategoryName,
      }),
      display: (state: any) => state?.resourceCategory?.resourceCategoryName,
    },
    label: "Resource Category",
    active: true,
    type: "select",
    validation: {
      validation: (value: string) =>
        !value ? "Resource Category is required" : undefined,
    },
  },
  {
    name: "route.routeId",
    value: (state) => state?.route.routeId,
    label: "Route Path",
    active: true,
    type: "select",
    options: {
      endpoint: "ROUTE",
      map: (itm: any) => ({
        id: itm.routeId,
        title: itm.routePath,
      }),
      display: (state: any) => state?.route?.routePath,
    },
    // validation: {
    //   validation: (value: string) =>
    //     !value ? "Route Path is required (starts with '/')" : undefined,
    // },
  },
  {
    name: "route.routeActive",
    value: (state) => state?.route.routeActive,
    label: "Route Active",
    active: true,
    type: "checkbox",
  },
  {
    name: "showNavigationBar",
    value: (state) => state?.showNavigationBar,
    label: "Show Navigation Bar",
    active: true,
    type: "checkbox",
  },
];

const UpdateDashView = () => {
  const { data, refetch } = useGet("DASHBOARDS");
  const reducer = (data: typeof initialValues) => {
    return { ...data, dashboardVersion: String(data?.dashboardVersion) };
  };
  return (
    <UpdateContainer
      name="dashboard"
      title="Update dashboards"
      subtitle="View, Add or Update dashboards"
      endpoint="DASHBOARDS"
      data={data}
      refetch={refetch}
      reducer={reducer}
      idName="dashboardId"
      config={config}
      initialValues={initialValues}
    />
  );
};

export default UpdateDashView;
