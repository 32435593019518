import { CircularProgress, FormControl, Grid, MenuItem, Select, makeStyles } from "@material-ui/core";
import { getAutorenewedToken } from "../auth/msalUtils";
import { useEffect, useState } from "react";
import { theme } from "src/theme";
import DestinationColumnTab from "./DestinationColumnTab";

const useStyles = makeStyles((theme) => ({

    newButton: {
        marginRight: '10px',
        fontSize: '14px',
        float: 'right'
    },
    fabProgress: {
        color: theme.palette.primary.main,
        marginTop: "20%",
        marginLeft: "35%",
        marginBottom: "15%",
    },
})
)
interface Dashboard {
    dashboardId: number
    dashboardName: string
}

interface DataMappingProps {
    linkedServiceId: string
    sourceConnection: string
}
const DataMapping = (props: DataMappingProps) => {
    const classes = useStyles();
    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<number>();
    const [loading, setLoading] = useState(true);
    const linkedServiceId = "/subscriptions/b894889e-0635-499e-afc1-fab441658728/resourceGroups/imcp-datafactory-dev/providers/Microsoft.DataFactory/factories/imcp-dev-datafactory/linkedservices/rest";


    const getDashboard = async () => {

        fetch(process.env.REACT_APP_API_PATH + "/dashboards", {
            method: "GET",
            headers: { authorization: await getAutorenewedToken() }
        }).then(async (response) => {
            const data = await response.json();
            setDashboards(data)
            setLoading(false);
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            //setDataSet(data);
        }).catch((error) => {
            setLoading(false);
        });
    }



    const handleDashboardChange = (event) => {
        setSelectedDashboard(event.target.value);
    };

    useEffect(() => {
        getDashboard()
    }, [])

    const dashboardDetail = () => {
        return (
            <Grid container style={{ border: '1px solid #dddddd', borderRadius: "5px", minHeight: "100px" }}>
                <Grid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <h4 style={{ minWidth: "30%", paddingLeft: theme.spacing(2) }}>Select Dashboard :</h4>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant='outlined'
                            value={selectedDashboard}
                            onChange={handleDashboardChange}
                        >
                            {dashboards.map(item => (
                                <MenuItem key={item.dashboardId} value={item.dashboardId}>{item.dashboardName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }


    return (
        <>
            <h2 style={{ paddingBottom: 10 }}>Data Mapping</h2>
            <div>{dashboardDetail()}</div>
            <div>{selectedDashboard && <DestinationColumnTab dashboardId={selectedDashboard} linkedServiceId={props.linkedServiceId} sourceConnection={props.sourceConnection} />}</div>
            <div>{loading && <CircularProgress size={84} className={classes.fabProgress} />}</div>
        </>
    );
}
export default DataMapping;