export const ENDPOINTS = {
  DASHBOARDS: "dashboards",
  RESOURCE_CATEGORY: "resourcecategory",
  ROUTE: "route",
  ADMIN: "admin",
  APPLICATION: "applications",
  FEEDBACK: "feedback",
  SECURITY_GROUPS: "securitygroups",
  USER: "user",
  COMPONENT_TYPE: "route/componenttypes",
  ROUTE_CATEGORY: "routecategory",
  SIDEBAR: "sidebar",
  FUNCTIONS_DASHBOARDS: "resourcecategory/dashboards/active",
  DASHBOARDS_ACTIVE: "dashboards/active",
  TERMS: "terms",
  PORTAL_LOGO: "portallogo",
  DATA_CONFIGURATION:"dataconfiguration",
};
