import React, { useState } from "react";
import { IconButton, Box, Tooltip } from "@material-ui/core";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";

import ConfirmDialog from "src/components/utils/ConfirmDialog";
import { makeStyles } from "@material-ui/core/styles";
import rgbConverter from "../../../utils/rgbConverter";



export default function Logout() {
 
  const { instance } = useMsal();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleClick = (event: any) => {
    instance.logoutRedirect({
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
  };

  return (
    <Tooltip title="Sign Out" arrow>
    <Box mr={1.5}>
      <IconButton
        onClick={() => {
          setConfirmDialog({
            isOpen: true,
            // @ts-ignore
            isNoIcon: true,
            subtitle: "Are you sure you would like to sign out?",
            btnText: "SIGN OUT",
            onConfirm: handleClick,
          });
        }}
      >
        <ExitToAppOutlinedIcon/>
      </IconButton>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Box>
    </Tooltip>
  );
}
