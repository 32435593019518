import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { getAutorenewedToken } from "../auth/msalUtils";

import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  makeStyles,
} from "@material-ui/core";

import Emitter from "../utils/Emitter";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  cardcontent: {
    padding: 0,
  },
}));

interface DashboardCardProps {
  title: string;
  subtitle?: string;
  onClick: Function;
  imageUrl: string;
}

const DashboardCard = ({
  title,
  subtitle,
  onClick,
  imageUrl,
}: DashboardCardProps) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      style={{ borderRadius: "5px", padding: "0px", border: "0px" }}
    >
      <CardActionArea onClick={() => onClick()}>
        <CardMedia
          component="img"
          alt={title}
          image={imageUrl ? `data:image/jpeg;base64,${imageUrl}` : "/static/images/dashboards/underdev.png"} 
          title={title}
        />
      </CardActionArea>
      <CardContent>
        <Typography align="left" color="textPrimary" gutterBottom variant="h5">
          {title}
        </Typography>

        <Typography
          align="left"
          color="textPrimary"
          variant="body2"
          component="p"
        >
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
