import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@material-ui/core";
import React from "react";
import MaterialButton from 'src/components/utils/Button';
import Error from "@material-ui/icons/ErrorOutline";

interface WarningProps{
    warningResponse:any
}
const ConnectionChangeWarning=(props:WarningProps)=> {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();

    

    const handleClose = () => {
        props.warningResponse(false);
    };

    const handleNext = () => {
        props.warningResponse(true);
    };
  
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">By proceeding, your current connection settings will be altered and cannot be restored.
             Additionally, you'll need to remap the data pertaining to all relevant tables. Are you sure you want to proceed?.</DialogContentText>
          </DialogContent>
          
          <DialogActions>
            <MaterialButton onClick={handleClose}
            text={'Close'}
            style={{
                backgroundColor:theme.palette.secondary.red,
                color: theme.palette.whiteColor.main
              }}>Close</MaterialButton>
              <MaterialButton onClick={handleNext}
            text={'Next'}
            style={{
                backgroundColor:theme.palette.primary,
                color: theme.palette.whiteColor.main
              }}>Next</MaterialButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  export default ConnectionChangeWarning