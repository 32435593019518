import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import { useDropzone } from "react-dropzone";
import Guid from "guid";

import {
  Paper,
  Grid,
  CircularProgress,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  useTheme,
  Link,
  Divider,
  Typography
} from "@material-ui/core";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Notification from "../utils/Notification";

import Templates from "../template_upload/Templates";
import AlertDialog from "../template_upload/AlertDialog";
import DataTable from "../utils/DataTable";
import { getAutorenewedToken } from "../auth/msalUtils";
import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import {
  humanFileSize,
  UploadProgressBar,
} from "../utils/BlobService";
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
  hover: {
    "&:hover": {
      backgroundColor: "#e8e9eb",
      cursor: "pointer",
    },
    "&:click": {
      borderColor: theme.palette.text.primary,
    },
    borderColor: theme.palette.background.light,
    backgroundColor: theme.palette.background.dark,
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 18,
  },
  linkMargin:{
    cursor:"pointer",
    color: "#007FFF",
    display:"flex",
    flexDirection:"row-reverse",
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
}));

const fileConfig = [
  {
    id:0,
    value:  "uploadedSucessfully",
    label: "Status",
    active:true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 1,
    value: "fileName",
    label: "File Name",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 2,
    value: "fileSize",
    label: "File Size",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 3,
    value: "logDetails",
    label: "Log Details",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 4,
    value: "uploadedBy",
    label: "Uploaded By",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 5,
    value: "createdOn",
    label: "Uploaded On",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
];

const TemplateUploadView = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [fileValidation, setFileValidation] = useState(false);
  const [missingProject, setMissingProject] = useState([]);
  const [records, setRecords] = useState(null);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const apiPath = process.env.REACT_APP_API_PATH;
  const activeHeaders = fileConfig.filter((header) => header.active);
  
  async function getPortalAllowedPath() {
    return fetch(
      apiPath + "/storageaccount/fileingestion/allowedpaths",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      const datavalue = data.join(";").toString();
      return datavalue;
    });
  }

  async function getPortalAllowedFileTypes() {
    return fetch(
      apiPath +"/storageaccount/fileingestion/allowedfileformats",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  }

  const getFileList = async () => {
    setIsLoading(true);
    //const allowedPath = await getPortalAllowedPath();
    // GET allowed blob storage details
    fetch(
      apiPath + "/template/getAllLogs",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        setRecords(
          data.map((media) => ({
            fileName: media.fileName,
            uploadedBy: media.user.userFirstName +" "+ media.user.userLastName,
            uploadedSucessfully: media.uploadedSucessfully,
            logDetails: media.logDetails,
            fileSize: humanFileSize(media.fileSize, true),
            createdOn: format(new Date(media.createdOn), 'MM/dd/yyyy HH:mm:ss')
          }))
        );

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert("There was an error!"+ error);
      });
  };

  const [progressObj, dispatch] = useReducer((state, action) => {
    if (action.type === "Delete") {
      delete state[action.value];
      return state;
    } else {
      return { ...state, ...action.value };
    }
  }, {});

  const updateProgress = (key, progress) => {
    dispatch({
      type: "Update",
      value: { [key]: Math.round(progress * 1000) / 10 },
    });
  };
  
  async function validateFile(formData:FormData) {
    return fetch(
      apiPath + "/template/validateTemplate",
      {
        method: "POST",
        headers: { authorization: await getAutorenewedToken() },
        body: formData
      }
     
    ).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        await getFileList();
        setNotify({
          isOpen: true,
          message: "Upload Failed: "+ data.detail,
          type: "error",
        });
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      
      return data;
    });
  }

  async function uploadTemplate(formData, data)
  {
    const allowedPath = await getPortalAllowedPath();
    const authorization = await getAutorenewedToken();

    const headerReqest = {
      method: "POST",
      headers: 
      {
        authorization: authorization,
      },
      body: formData
    }
    fetch(
      apiPath + "/template/fileingestion/upload?contianerName=" +
        allowedPath+"&fileName="+data.dataSetName,
      headerReqest
    ).then(async (response) => {
      getFileList();
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        setNotify({
          isOpen: true,
          message: 'Failed to upload data',
          type: "error",
        });
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      } 
      else{
        setNotify({
          isOpen: true,
          message: 'Data uploaded Successfully',
          type: "success",
        });
      }
    });
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    // GET allowed blob storage details
    if (acceptedFiles.length === 1) {
      const allowedFileTypes = await getPortalAllowedFileTypes();
      if (allowedFileTypes.includes(acceptedFiles[0].type)) {
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        setFileName(acceptedFiles[0].name)
        var data = await validateFile(formData);
        if(data && data.validate)
        {
          await uploadTemplate(formData, data);
        }
        else
        {
          setFileValidation(true);
          setMissingProject(data.missingProject);
        }
        await getFileList();
       
      } else {
        setNotify({
          isOpen: true,
          message: 'Please upload "Data Template" in .csv format',
          type: "error",
        });
      }
    } else {
      setNotify({
        isOpen: true,
        message: "Please upload one file at a time",
        type: "error",
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const globalTheme = useTheme();

  const style = useMemo(
    () => ({
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,

      borderStyle: "dashed",
      color: globalTheme.palette.text.secondary,
      outline: "none",
      transition: "border .24s ease-in-out",
      ...(isDragActive
        ? { borderColor: globalTheme.palette.primary.main }
        : {}),
      ...(isDragAccept
        ? { borderColor: globalTheme.palette.primary.main }
        : {}),
      ...(isDragReject
        ? { borderColor: globalTheme.palette.secondary.red }
        : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, globalTheme]
  );
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, null, records);
    
    
  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    getFileList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sendData = () => {
    setFileValidation(false)
  }
  return (
    <>
    <Notification notify={notify} setNotify={setNotify} />
    <Link className={classes.linkMargin}><span onClick={() => setOpenTemplate(!openTemplate)}>Click here to View Templates per Dashboard</span></Link>
    {openTemplate && (<Templates></Templates>)}
        <Toolbar>
          <div {...getRootProps({ style })} className={classes.hover}>
            <input
              {...getInputProps()}
              accept=".csv"
              type="file"
              multiple={false}
            />
            <p>
              <CloudUploadIcon />
            </p>
            <p>Drag and drop file here, or click to select files</p>
          </div>
        </Toolbar>
        <UploadProgressBar progress={progressObj} />
        {isLoading && (
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            spacing={0}
          >
            <CircularProgress />
          </Grid>
        )}
        {fileValidation &&  <AlertDialog project={missingProject} fileName={fileName}  sendData={sendData}/>}
        {records !== null ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row, idx) => (
                  <TableRow hover key={idx} value={row} >
                    {activeHeaders.map((col) => (
                      <TableCell key={col.id} style={{ width: col.value == 'logDetails' ? '50%' : null}}>
                        {col.value == 'uploadedSucessfully' ? ( 
                          row[col.value] == true? <CheckIcon style={{ color: "green" }} />: <ClearIcon style={{ color: "red" }} />
                          ) : (
                            row[col.value]
                        )}
                      
                      </TableCell>
                      
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <br></br>
            <TblPagination />
          </>
        ) : (
          ""
        )}
    </>
  );
};

export default TemplateUploadView;
