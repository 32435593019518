import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@material-ui/core";
import React from "react";
import MaterialButton from 'src/components/utils/Button';
import Block from "@material-ui/icons/Block";
import Warning from "@material-ui/icons/ReportProblemOutlined";

interface StepDialog{
    sendData:any
    errorPhrase:string
    currStep:number
    prevStep:number
}
const StepDialog=(props:StepDialog)=> {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const handleClose = () => {
        setOpen(false);
        props.sendData(props.prevStep);
    };
    const handlecontinue = () => {
        setOpen(false);
        props.sendData(props.currStep);
    };
  
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{display:"flex", alignItems:"center"}}>
                <div style={{display:"flex", alignItems:"center", marginRight:"5%"}}>
                    {props.currStep == 3 ? <Warning style={{height:"50px", width:"50px", color:"#ffcc00"}}></Warning> : <Block style={{height:"50px", width:"50px", color:"red"}}></Block> }
                    
                    <span style={{paddingLeft:"2%"}}></span></div>{props.errorPhrase}
            </DialogContentText>
          </DialogContent>
          
          <DialogActions style={{justifyContent:"center"}}>
            <MaterialButton onClick={handleClose}
            text={'Ok, Cancel'}
            style={{
                backgroundColor:theme.palette.secondary.red,
                color: theme.palette.whiteColor.main
              }}>Ok</MaterialButton>
            {props.currStep == 3 && props.prevStep != 3 &&<MaterialButton onClick={handlecontinue}
            text={'Continue Anyway'}
            style={{
                backgroundColor:theme.palette.primary.main,
                color: theme.palette.blackColor.main
              }}>Ok</MaterialButton>}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  export default StepDialog