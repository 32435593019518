import React, {
    useEffect,
    useState,
    useMemo,
  } from "react";
  import { useDropzone } from "react-dropzone";
  import {
    makeStyles,
    TableBody,
    TableRow,
    TableCell,
    useTheme,
  } from "@material-ui/core";
  import Notification from "../utils/Notification";
  import DataTable from "../utils/DataTable";
  import { getAutorenewedToken } from "../auth/msalUtils";
  import {
    humanFileSize,
    UploadProgressBar,
  } from "../utils/BlobService";
  import CheckIcon from "@material-ui/icons/Check";
  import ClearIcon from "@material-ui/icons/Clear";
  import { format } from 'date-fns';
  
  const useStyles = makeStyles((theme) => ({
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(3),
    },
    searchInput: {
      width: "25%",
    },
    newButton: {
      position: "absolute",
      right: "10px",
      fontSize: "14px",
    },
    hover: {
      "&:hover": {
        backgroundColor: "#e8e9eb",
        cursor: "pointer",
      },
      "&:click": {
        borderColor: theme.palette.text.primary,
      },
      borderColor: theme.palette.background.light,
      backgroundColor: theme.palette.background.dark,
    },
    icon: {
      color: theme.palette.text.primary,
      fontSize: 18,
    },
    linkMargin:{
      cursor:"pointer",
      color: "#007FFF",
      display:"flex",
      flexDirection:"row-reverse",
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(3),
    },
  }));
  
  const fileConfig = [
    {
      id:0,
      value:  "uploadedSucessfully",
      label: "",
      active:true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 1,
      value: "fileName",
      label: "File Name",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 2,
      value: "fileSize",
      label: "File Size",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 3,
      value: "logDetails",
      label: "Log Details",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 4,
      value: "uploadedBy",
      label: "Uploaded By",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 5,
      value: "createdOn",
      label: "Uploaded On",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
  ];
  
  const DataUploadLogTable = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState(null);
    const [openTemplate, setOpenTemplate] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [notify, setNotify] = useState({
      isOpen: false,
      message: "",
      type: "",
    });
    const apiPath = process.env.REACT_APP_API_PATH;
    const activeHeaders = fileConfig.filter((header) => header.active);
  
    
  
    const getFileList = async () => {
      setIsLoading(true);
      //const allowedPath = await getPortalAllowedPath();
      // GET allowed blob storage details
      fetch(
        apiPath + "/template/getAllLogs",
        {
          method: "GET",
          headers: { authorization: await getAutorenewedToken() },
        }
      )
        .then(async (response) => {
          const data = await response.json();
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          setRecords(
            data.map((media) => ({
              fileName: media.fileName,
              uploadedBy: media.user.userLastName +" , "+ media.user.userFirstName,
              uploadedSucessfully: media.uploadedSucessfully,
              logDetails: media.logDetails,
              fileSize: humanFileSize(media.fileSize, true),
              createdOn: format(new Date(media.createdOn), 'MM/dd/yyyy HH:mm:ss')
            }))
          );
  
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          alert("There was an error!"+ error);
        });
    };
  
    const globalTheme = useTheme();
    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
      DataTable(records, activeHeaders, null, records);
      
      
    //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
    useEffect(() => {
      getFileList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
      <Notification notify={notify} setNotify={setNotify} />
          {records !== null ? (
            <>
              <TblContainer>
                <TblHead />
                <TableBody>
                  {recordsAfterPagingAndSorting().map((row, idx) => (
                    <TableRow hover key={idx} value={row} >
                      {activeHeaders.map((col) => (
                        <TableCell key={col.id} style={{ width: col.value == 'logDetails' ? '50%' : null}}>
                          {col.value == 'uploadedSucessfully' ? ( 
                            row[col.value] == true? <CheckIcon style={{ color: "green" }} />: <ClearIcon style={{ color: "red" }} />
                            ) : (
                              row[col.value]
                          )}
                        
                        </TableCell>
                        
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </TblContainer>
              <br></br>
              <TblPagination />
            </>
          ) : (
            ""
          )}
      </>
    );
  };
  
  export default DataUploadLogTable;
  