import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@material-ui/core";
import React from "react";
import MaterialButton from 'src/components/utils/Button';
import Warning from "@material-ui/icons/ReportProblemOutlined";

interface ErrorProps{
    closeDialog:any
    currStep:number
    nextStep:number
}
const MissedSaveingProject=(props:ErrorProps)=> {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const handleClose = () => {
        setOpen(false);
        props.closeDialog(props.currStep);
    };
    const handlecontinue = () => {
        setOpen(false);
        props.closeDialog(props.nextStep);
    };
  
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{display:"flex", alignItems:"center"}}>
                <div style={{display:"flex", alignItems:"center", marginRight:"5%"}}>
                    <Warning style={{height:"50px", width:"50px", color:"#ffcc00"}}></Warning>
                    <span style={{paddingLeft:"2%"}}></span></div>{"Looks like you have entered project information. Please save or you will lose this information"}
            </DialogContentText>
          </DialogContent>
          
          <DialogActions style={{justifyContent:"center"}}>
            <MaterialButton onClick={handlecontinue}
            text={'Discard and Continue'}
            style={{
                backgroundColor:theme.palette.primary.main,
                color: theme.palette.blackColor.main
              }}></MaterialButton>
              <MaterialButton onClick={handleClose}
            text={'Cancel'}
            style={{
                backgroundColor:theme.palette.secondary.red,
                color: theme.palette.whiteColor.main
              }}></MaterialButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  export default MissedSaveingProject