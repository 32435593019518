import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react'
import Controls from '../../utils/controls/Controls';
import { useForm, Form } from '../../form/useForm';
import MuiButton from '../../utils/Button';

const initialValues = {
    tableId: 0,
    tableName: "",
    route: { routeId: 0, routePath: 'None', componentType:'', routeActive: true },
    tableDescription: "",
    tableColumns: []
}

let initRouteRecord;

export default function UpdateTableForm(props) {

    const { addOrEdit, recordForEdit, disableContent } = props
    const validate = (fieldValues = values) => {

        let temp = { ...errors }

        if ('tableName' in fieldValues){
            temp.tableName = fieldValues.tableName ? "" : "This field is required."
        }
        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x === "")
        }
        
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(recordForEdit ? recordForEdit : initialValues, true, validate, initRouteRecord);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
      if (recordForEdit != null){
        initRouteRecord = recordForEdit.route ? JSON.parse(JSON.stringify(recordForEdit.route)):null;
        setValues({ ...recordForEdit })
      } else {
        initRouteRecord = { routeId: 0, routePath: '', componentType:'', routeActive: true };
      }     
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Input
                        variant="outlined"
                        label="Table Name"
                        name="tableName"
                        value={values.tableName}
                        onChange={handleInputChange}
                        error={errors.tableName}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="route.routeActive"
                        label="Active"
                        value={values.route.routeActive}
                        onChange={handleInputChange}
                        disabled={disableContent}
                    />
                </Grid>

                <Grid item xs={6}>                    
                    <Controls.Input
                        variant="outlined"
                        label="Table Description"
                        name="tableDescription"
                        value={values.tableDescription}
                        onChange={handleInputChange}
                        error={errors.tableDescription}
                        margin="dense"
                        disabled={disableContent}
                    />
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                    {disableContent? <div> </div> : 
                        <div>
                            <MuiButton
                                    text="Submit"
                                    type="submit"
                            />
                            <MuiButton
                                variant="contained"
                                color="default"
                                size="large"
                                text="Reset"
                                onClick={resetForm}
                            />
                        </div>
                    }                    
                </Grid>
            </Grid>
        </Form>
    )
}
