import { Config } from "src/types";
import Controls from "src/components/utils/controls/Controls";
// import { Field } from "formik";

export const columnMap = (
  itm: Config,
  idx: number,
  formik: any,
  error: any
) => {
  switch (itm?.type) {
    case "input":
      return (
        <Controls.Input
          key={`input-${idx}`}
          label={itm?.label}
          name={itm?.name}
          validation={itm?.validation}
          error={error}
        />
      );
    case "checkbox":
      return (
        <Controls.Checkbox
          key={`input-${idx}`}
          label={itm?.label}
          name={itm?.name}
        />
      );
    case "select":
      return (
        (!!itm.options || !!itm.staticOptions) && (
          <Controls.Select
            name={itm.name}
            key={`input-${idx}`}
            label={itm?.label}
            endpoint={itm.options} //this should be changed to options when the old options prop is retired (in config.ts)
            isNameValue={itm?.isNameValue}
            validation={itm?.validation}
            error={error}
          />
        )
      );
    case "auto":
      return (
        <Controls.Inputdrop
          key={`input-${idx}`}
          variant="outlined"
          label={itm?.label}
          name={itm?.value}
          value={!formik.values[itm?.name] ? "" : formik.values[itm?.name]}
          options={itm?.options}
          onChange={formik.handleInputChange}
          margin="dense"
          validation={itm?.validation}
          error={error}
        />
      );
    default:
      break;
  }
};
