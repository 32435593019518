import { getAutorenewedToken } from "../components/auth/msalUtils";

export const fetchWrap = async <T>(
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: T,
  id?: number | string
): Promise<Array<T>> => {
  const isGet = !!(method === "GET");
  const response = await fetch(
    `${process.env.REACT_APP_API_PATH}/${endpoint}${id ? `/${id}` : ""}`,
    {
      method: `${method}`,
      headers: {
        "Content-Type": !isGet ? "application/json" : undefined,
        authorization: await getAutorenewedToken(),
      },
      body: body && !isGet ? JSON.stringify(body) : undefined,
    } as RequestInit
  );
  if (!response.ok) {
    const errorResponse = await response.json();
    if (errorResponse?.detail) throw new Error(errorResponse?.detail);
    var errorText = "";
    for (const item in errorResponse?.errors) {
      for (const message in errorResponse?.errors?.[item]) {
        errorText = errorText + errorResponse?.errors?.[item]?.[message] + " ";
        console.error(errorText);
      }
      throw new Error(errorText);
    }
  }
  return response.json() as Promise<Array<T>>;
};
