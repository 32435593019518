import React from "react";
import { Paper, Card, Typography, makeStyles } from "@material-ui/core";
import BackButton from "../layout/topbar/BackButton.tsx";
//TAKEN FROM TUTORIAL: https://www.youtube.com/watch?v=-XKaSCU0ZLM&list=PLjC4UKOOcfDQtvkTBfjqeWP8EJKi_WaUn&index=4

const pageHeaderStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.header,
  },
  pageHeader: {
    padding: theme.spacing(4),
    display: "flex",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    fontSize: 18,
  },
  pageTitle: {
    paddingLeft: theme.spacing(4),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  BackButton: {
    display: "inline-block",
    paddingTop: theme.spacing(1),
    color: theme.palette.text.primary,
    marginLeft: "auto",
    fontSize: 18,
  },
}));

function backButtonActive(disableBackButton) {
  if (disableBackButton != true) {
    const classes = pageHeaderStyles();
    return (
      <div className={classes.BackButton}>
        <BackButton />
      </div>
    );
  }
}

export default function PageHeader(props) {
  const classes = pageHeaderStyles();
  const { title, subtitle, icon, disableBackButton } = props;
  return (
    <Paper elevation={0} square className={classes.root}>
      <div className={classes.pageHeader}>
        <Card className={classes.pageIcon}>{icon}</Card>
        <div className={classes.pageTitle}>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {subtitle}
          </Typography>
        </div>
        {backButtonActive(disableBackButton)}
      </div>
    </Paper>
  );
}
