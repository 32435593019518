import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react'
import Controls from '../../utils/controls/Controls';
import { useForm, Form } from '../../form/useForm';
import MuiButton from '../../utils/Button';


const initialValues = {
    colId: 0,
    dataSetId: 0,
    columnName: "",
    ordinalPosition: 0,
    columnDefault: "",
    isNullable: true,
    dataType: "",
    characterMaximumLength: 0,
    colLengthInf: 0,
    colLengthRec: 0,
    characterOctetLength: 0,
    numericPrecision: 0,
    numericPrecisionRadix: 0,
    numericScale: 0,
    datetimePrecision: 0,
    characterSetCatalog: "",
    characterSetSchema: "",
    characterSetName: "",
    collationCatalog: "",
    collationSchema: "",
    collationName: "",
    domainCatalog: "",
    domainSchema: "",
    domainName: "",
    isActive: true,
    colPrimaryKey: true,
    colComments: ""
}

export default function UpdateColumnForm(props) {

    const { addOrEdit, recordForEdit, disableContent } = props
    const validate = (fieldValues = values) => {

        let temp = { ...errors }

        if ('datasetId' in fieldValues){
            temp.datasetId = fieldValues.datasetId ? "" : "This field is required."
        }
        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x === "")
        }
        
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null){
            setValues({
                ...recordForEdit
            })
        }     
    }, [recordForEdit, setValues])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
            <Grid item xs={4}>
                    <Controls.Input
                        variant="outlined"
                        label="Col ID"
                        name="colId"
                        value={values.colId}
                        onChange={handleInputChange}
                        error={errors.colId}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Ordinal Position"
                        name="ordinalPosition"
                        value={values.ordinalPosition}
                        onChange={handleInputChange}
                        error={errors.ordinalPosition}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Data Type"
                        name="dataType"
                        value={values.dataType}
                        onChange={handleInputChange}
                        error={errors.dataType}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Col Length Rec"
                        name="colLengthRec"
                        value={values.colLengthRec}
                        onChange={handleInputChange}
                        error={errors.colLengthRec}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Numeric Precision Radix"
                        name="numericPrecisionRadix"
                        value={values.numericPrecisionRadix}
                        onChange={handleInputChange}
                        error={errors.numericPrecisionRadix}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Character Set Catalog"
                        name="characterSetCatalog"
                        value={values.characterSetCatalog}
                        onChange={handleInputChange}
                        error={errors.characterSetCatalog}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Collation Catalog"
                        name="collationCatalog"
                        value={values.collationCatalog}
                        onChange={handleInputChange}
                        error={errors.collationCatalog}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Domain Catalog"
                        name="domainCatalog"
                        value={values.domainCatalog}
                        onChange={handleInputChange}
                        error={errors.domainCatalog}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="isActive"
                        label="Active"
                        value={values.isActive}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />                    
                </Grid>

                <Grid item xs={4}>                    
                    <Controls.Input
                        variant="outlined"
                        label="Dataset ID"
                        name="dataSetId"
                        value={values.dataSetId}
                        onChange={handleInputChange}
                        error={errors.dataSetId}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Col Default"
                        name="columnDefault"
                        value={values.columnDefault}
                        onChange={handleInputChange}
                        error={errors.columnDefault}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Character Maximum Length"
                        name="characterMaximumLength"
                        value={values.characterMaximumLength}
                        onChange={handleInputChange}
                        error={errors.characterMaximumLength}
                        margin="dense"
                        disabled={disableContent}
                    />                    
                    <Controls.Input
                        variant="outlined"
                        label="Character Octet Length"
                        name="characterOctetLength"
                        value={values.characterOctetLength}
                        onChange={handleInputChange}
                        error={errors.characterOctetLength}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Numeric Scale"
                        name="numericScale"
                        value={values.numericScale}
                        onChange={handleInputChange}
                        error={errors.numericScale}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Character Set Schema"
                        name="characterSetSchema"
                        value={values.characterSetSchema}
                        onChange={handleInputChange}
                        error={errors.characterSetSchema}
                        margin="dense"
                        disabled={disableContent}
                    />                    
                    <Controls.Input
                        variant="outlined"
                        label="Collation Schema"
                        name="collationSchema"
                        value={values.collationSchema}
                        onChange={handleInputChange}
                        error={errors.collationSchema}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Domain Schema"
                        name="domainSchema"
                        value={values.domainSchema}
                        onChange={handleInputChange}
                        error={errors.domainSchema}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="colPrimaryKey"
                        label="Col Primary Key"
                        value={values.colPrimaryKey}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />                    
                </Grid>
                <Grid item xs={4}>                    
                    <Controls.Input
                        variant="outlined"
                        label="Col Name"
                        name="columnName"
                        value={values.columnName}
                        onChange={handleInputChange}
                        error={errors.columnName}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Checkbox
                        name="isNullable"
                        label="Is Nullable"
                        value={values.isNullable}
                        onChange={handleInputChange}                        
                        disabled={disableContent}
                    />                    
                    <Controls.Input
                        variant="outlined"
                        label="Col Length Inf"
                        name="colLengthInf"
                        value={values.colLengthInf}
                        onChange={handleInputChange}
                        error={errors.colLengthInf}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Numeric Precision"
                        name="numericPrecision"
                        value={values.numericPrecision}
                        onChange={handleInputChange}
                        error={errors.numericPrecision}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Datetime Precision"
                        name="datetimePrecision"
                        value={values.datetimePrecision}
                        onChange={handleInputChange}
                        error={errors.datetimePrecision}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Character Set Name"
                        name="characterSetName"
                        value={values.characterSetName}
                        onChange={handleInputChange}
                        error={errors.characterSetName}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Collation Name"
                        name="collationName"
                        value={values.collationName}
                        onChange={handleInputChange}
                        error={errors.collationName}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Domain Name"
                        name="domainName"
                        value={values.domainName}
                        onChange={handleInputChange}
                        error={errors.domainName}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Col Comments"
                        name="colComments"
                        value={values.colComments}
                        onChange={handleInputChange}
                        error={errors.colComments}
                        margin="dense"
                        disabled={disableContent}
                    />
                </Grid>

                <Grid container item xs={12} justifyContent="flex-end">
                    {disableContent? <div> </div> : 
                        <div>
                            <MuiButton
                                    text="Submit"
                                    type="submit"
                            />
                            <MuiButton
                                variant="contained"
                                color="default"
                                size="large"
                                text="Reset"
                                onClick={resetForm}
                            />
                        </div>
                    }                    
                </Grid>
            </Grid>
        </Form>
    )
}
