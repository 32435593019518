import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  Typography,
  Grid,
  Chip,
} from "@material-ui/core";

import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "14px",
    backgroundColor: "white !important",
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "155px",
    height:"50px",
    marginRight: theme.spacing(1),
    borderRadius:"25px"
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
    marginLeft: 10,
    opacity: 0.54,
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: theme.palette.primary.main,
    backgroundColor:"rgba(0, 0, 0, 0.08) !important",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
      opacity: 1, // To make sure the color is fully applied
    },
  },
}));
interface FilterToolbarProps {
  // className: string;
  areaClicked: any;
  prevAreaClicked:any
  defaultCategory: string;
  areasList: any;
}

function FilterToolbar({
  areaClicked,
  prevAreaClicked,
  defaultCategory,
  areasList,
}: FilterToolbarProps) {
  const classes = useStyles();
  const [selectedArea, setSelectedArea] = useState(defaultCategory);

  const getIcon = (param: any) => {
    switch (param) {
      case "PeopleOutlineIcon":
        return <PeopleOutlineIcon className={classes.icon} />;
      case "MonetizationOnOutlinedIcon":
        return <MonetizationOnOutlinedIcon className={classes.icon} />;
      case "BuildOutlinedIcon":
        return <BuildOutlinedIcon className={classes.icon} />;
      case "SettingsApplicationsOutlinedIcon":
        return <SettingsApplicationsOutlinedIcon className={classes.icon} />;
      case "DateRangeOutlinedIcon":
        return <DateRangeOutlinedIcon className={classes.icon} />;
      case "LocalShippingOutlinedIcon":
        return <LocalShippingOutlinedIcon className={classes.icon} />;
      case "LocalHospitalOutlinedIcon":
        return <LocalHospitalOutlinedIcon className={classes.icon} />;
      case "ReportProblemOutlinedIcon":
        return <ReportProblemOutlinedIcon className={classes.icon} />;
      default:
        return <WorkOutlineOutlinedIcon className={classes.icon} />;
    }
  };

  const handleAreaClicked = async (area: any) => {
    if (area === selectedArea) {
      await setSelectedArea("");
      areaClicked("");
      prevAreaClicked(area);
    } else {
      await setSelectedArea(area);
      areaClicked(area);
      prevAreaClicked("");
    }
  };

  return (
    <div>
      <Box mt={3}>
        <Typography color="textSecondary" variant="body1">
          Filter dashboards by area
        </Typography>
        <Card style={{ boxShadow:"none" }}>
          <CardContent>
            <Grid container spacing={1} direction="row" justifyContent="center">
              {areasList?.map((area: any, i: any) => {
                const selected = area.resourceCategoryName === selectedArea;
                return (
                  <Grid item key={i}>
                    <Chip
                      size="medium"
                      variant="outlined"
                      avatar={getIcon(area.resourceCategoryIcon)}
                      // className={
                      //   classes.button + " " + (selected && classes.active)
                      // }
                      className={clsx(classes.button, { [classes.active]: selected })}
                      label={area.resourceCategoryName}
                      onClick={() =>
                        handleAreaClicked(area.resourceCategoryName)
                      }
                    >
                      {/* {getIcon(area.resourceCategoryIcon)}
                      {area.resourceCategoryName} */}
                    </Chip>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

FilterToolbar.propTypes = {
  className: PropTypes.string,
};

export default FilterToolbar;
