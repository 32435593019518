import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react'
import Controls from '../../utils/controls/Controls';
import { useForm, Form } from '../../form/useForm';
import MuiButton from '../../utils/Button';


const initialValues = {
    propertyId: 0,
    datasetId: 0,
    propertyType: "",
    propertyName: "",
    propertyValue: ""
}

export default function UpdatePropertyForm(props) {

    const { addOrEdit, recordForEdit, disableContent } = props
    const validate = (fieldValues = values) => {

        let temp = { ...errors }

        if ('datasetId' in fieldValues){
            temp.datasetId = fieldValues.datasetId ? "" : "This field is required."
        }
        if ('propertyName' in fieldValues){
            temp.propertyName = fieldValues.propertyName ? "" : "This field is required."
        }
        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x === "")
        }
        
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null){
            setValues({
                ...recordForEdit
            })
        }     
    }, [recordForEdit, setValues])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
            <Grid item xs={4}>
                    <Controls.Input
                        variant="outlined"
                        label="Property ID"
                        name="propertyId"
                        value={values.propertyId}
                        onChange={handleInputChange}
                        error={errors.propertyId}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Property Name"
                        name="propertyName"
                        value={values.propertyName}
                        onChange={handleInputChange}
                        error={errors.propertyName}
                        margin="dense"
                        disabled={disableContent}
                    />
                </Grid>

                <Grid item xs={4}>                    
                    <Controls.Input
                        variant="outlined"
                        label="Dataset ID"
                        name="datasetId"
                        value={values.datasetId}
                        onChange={handleInputChange}
                        error={errors.datasetId}
                        margin="dense"
                        disabled={disableContent}
                    />
                    <Controls.Input
                        variant="outlined"
                        label="Property Value"
                        name="propertyValue"
                        value={values.propertyValue}
                        onChange={handleInputChange}
                        error={errors.propertyValue}
                        margin="dense"
                        disabled={disableContent}
                    />                    
                </Grid>
                <Grid item xs={4}>                    
                    <Controls.Input
                        variant="outlined"
                        label="Property Type"
                        name="propertyType"
                        value={values.propertyType}
                        onChange={handleInputChange}
                        error={errors.propertyType}
                        margin="dense"
                        disabled={disableContent}
                    />                    
                </Grid>

                <Grid container item xs={12} justifyContent="flex-end">
                    {disableContent? <div> </div> : 
                        <div>
                            <MuiButton
                                    text="Submit"
                                    type="submit"
                            />
                            <MuiButton
                                variant="contained"
                                color="default"
                                size="large"
                                text="Reset"
                                onClick={resetForm}
                            />
                        </div>
                    }                    
                </Grid>
            </Grid>
        </Form>
    )
}
