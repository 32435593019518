import UpdateContainer from "../UpdateContainer";
import { useGet } from "../../../hooks";
import { Config } from "../../../types/config";

const initialValues = {
  termsId: 0,
  termsName: "",
  termsFileURL: "",
};

const config: Config[] = [
  {
    name: "termsId",
    value: (state) => state?.termsId,
    label: "Id",
    active: true,
    type: "id",
  },
  {
    name: "termsName",
    value: (state) => state?.termsName,
    label: "Name",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value
          ? "Name is required"
          : value.replace(/[^\w]/g, "").replace(/[^\w]/g, "").length > 0
          ? undefined
          : "Enter Valid Name",
    },
  },
  {
    name: "termsFileURL",
    value: (state) => state?.termsFileURL,
    label: "FileURL",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value
          ? "URL is required"
          : value.match(
              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            ) !== null
          ? undefined
          : "Enter Valid URL",
    },
  },
];

const UpdateTerms = () => {
  const reducer = (data: typeof initialValues) => {
    return data;
  };
  const { data, refetch } = useGet("TERMS");
  return (
    <UpdateContainer
      name="Terms of Use"
      title="Update Terms of Use"
      subtitle="View, Add or Update Terms of Use"
      endpoint="TERMS"
      idName="termsId"
      skip={[]}
      config={config}
      initialValues={initialValues}
      data={data}
      refetch={refetch}
      reducer={reducer}
    />
  );
};
export default UpdateTerms;
