import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  Button,
  Popover,
  IconButton,
  TextField,
  Typography,
  Box,
  Tooltip,
  Badge,
} from "@material-ui/core";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { getAutorenewedToken } from "../../auth/msalUtils";
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOffOutlined';
import { useNavigate } from "react-router-dom";
import rgbConverter from "../../../utils/rgbConverter";
import { format } from "date-fns";

interface Notification
{
  notificationId:number,
  notificationFromUserId:number,
  notificationFromUserFirstName:string,
  notificationFromUserLastName:string,
  notificationMessage:string,
  notificationDescription:string,
  route:string,
  commentId:number,
  isSeen:boolean,
  notificationExpires:Date,
  createdOn:Date
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        maxWidth: '56ch',
        minWidth: '56ch',
        backgroundColor: theme.palette.background.paper,
        height:200,
        overflow:"auto",
        padding:5
      },
      inline: {
        display: 'inline',
      },
      avatar: {
        backgroundColor: theme.palette.primary.main,
      },
      nonotification:{
        display:'flex',
        alignItems:'center',
        margin:'auto',
        justifyContent:'center',
        padding:30
      },
    }),
  );
  
  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -3,
      // border: `1px solid ${theme.palette.text.secondary}`,
      padding: '0 4px',
      borderRadius: '50%', // Make the badge circular
      minWidth: '22px', // Ensure it has a minimum width
      height: '22px', // Ensure it has a fixed height
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:theme.palette.primary.main
    },
  }))(Badge);
  
  

export default function Notification() {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [notification, setNotification] = useState<Notification[]>([])
  const [tooltipOpen, setTooltipOpen] = useState(true);
  const open = Boolean(anchorEl);
  const id = open ? "feedback-popover" : undefined;
  let navigate = useNavigate();

  const getAllNotification = async () => {
    setIsLoading(true);
    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
      "/notification",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        setNotification(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const updateSeenNotification = async () => {
    setIsLoading(true);
    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
      "/notification/updateSeenNotification",
      {
        method: "PUT",
        headers: { authorization: await getAutorenewedToken() },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        if(data === true)
        {
          notification.map(x => x.isSeen = true)
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const inactiveNotification = async (notificationId:number) => {
    setIsLoading(true);
    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
      "/notification/inactiveNotification?notificationId="+notificationId,
      {
        method: "PATCH",
        headers: { authorization: await getAutorenewedToken() },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        if(data === true)
        {
          var filterNotification = notification.filter(x => x.notificationId != notificationId)
          setNotification(filterNotification);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const formatText = (text) => {
    const regex = /@\[(.*?)\]\((.*?)\)/g;
    return text.replace(regex, (match, p1) => `<span style="font-weight: 200;">${p1}</span>`);
  };
  
  useEffect(() => {
    getAllNotification();
  },[])
  const handleClick = (event) => {
    updateSeenNotification();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  

  return (
      <Box>
      <Tooltip title="Notifications" arrow>
        <IconButton
          style={{ fill: "greyColor" }}
          //ref={anchorRef}
          onClick={handleClick}
        >
          {notification.filter(x => !x.isSeen).length > 0 ?
            <StyledBadge badgeContent={notification.filter(x => !x.isSeen).length} color="secondary">
              <NotificationsNoneIcon />
            </StyledBadge>
            :
            <NotificationsNoneIcon />}
        </IconButton>
      </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{
            style: { borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 10px 2px" },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {notification.length > 0 ? 
          <List className={classes.root}>
            {notification.map((option) => (
              <>
                <ListItem
                  alignItems="flex-start"
                  style={{cursor:"pointer"}}
                  onClick={() =>
                  {
                    navigate(option.route, { state: { open: true, commentId: option.commentId } });
                    inactiveNotification(option.notificationId)
                    handleClose();
                  }
                }
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>{option.notificationFromUserFirstName.substring(0,1)}{option.notificationFromUserLastName.substring(0,1)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <div>
                          <div>
                            <span style={{fontWeight:"bold"}}>{option.notificationFromUserFirstName + " " + option.notificationFromUserLastName}</span>
                            {option.notificationMessage}
                          </div>
                        </div>
                      </>
                    }
                    secondary={
                      <React.Fragment>
                        <div style={{paddingTop:5}} dangerouslySetInnerHTML={{ __html: `${formatText(option.notificationDescription.substring(0, 100))}...` }}></div>
                        <div style={{paddingTop:5, display:'flex', justifyContent:'flex-end', color:'black'}}>
                          <span>{format(new Date(option.createdOn), 'dd-MMM-yyyy')}</span>
                        </div>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />

              </>

            ))}
          </List> : <Typography className={classes.nonotification} variant="subtitle2">No active notifications!!</Typography>}

        </Popover>
      </Box>
  );
}
