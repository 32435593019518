import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Inputdrop from "./Inputdrop";

const Controls = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  DatePicker,
  Inputdrop,
};
export default Controls;
