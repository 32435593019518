import React from 'react';
import { AntTabs, AntTab, TabPanel } from 'src/components/utils/Tabs';

import RouteTab from './RouteTab';

const UpdateRouteSecurity = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // placeholder until I figure out how to refactor this into UpdateTable
  return <RouteTab name="User" />;
};

export default UpdateRouteSecurity;
