import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import jwt_decode from "jwt-decode";

import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

export const getAutorenewedToken = async () => {
  let token = "";
  try {
    const allAccounts = msalInstance.getAllAccounts();
    const apiScope = msalConfig.auth.clientId + "/.default";
    if (allAccounts && allAccounts.length > 0) {
      const accessTokenRequest = {
        scopes: [`${apiScope}`],
        account: allAccounts[0],
      };
      try {
        const response = await msalInstance.acquireTokenSilent(
          accessTokenRequest
        );
        token = response.accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          await new Promise(() =>
            msalInstance.acquireTokenRedirect(accessTokenRequest)
          );
        }
        console.error(error);
        throw new Error("Cannot get access token.");
      }
    } else {
      console.error("Cannot get token due to no logined account");
    }
  } catch (e) {
    console.error("Error refreshing API token:", e);
    return;
  }
  // @ts-ignore
  const millisToExpiry = jwt_decode(token).exp * 1000 - Date.now();
  const twoMinutesMillis = 1000 * 60 * 2;
  // Queue another refresh, two minutes before the token is due to expire
  setTimeout(() => getAutorenewedToken(), millisToExpiry - twoMinutesMillis);

  return "Bearer " + token;
};

export const getApiToken = () => {
  return "Bearer " + sessionStorage.getItem("MSAL_AUTH_TOKEN_4_API");
};

export const setApiToken = (token: any) => {
  sessionStorage.setItem("MSAL_AUTH_TOKEN_4_API", token);
};

export const getUserType = () => {
  return sessionStorage.getItem("USER_TYPE");
};

export const setUserType = (typeID: any) => {
  sessionStorage.setItem("USER_TYPE", typeID);
};

export const getUserName = () => {
  const allAccounts = msalInstance.getAllAccounts();
  return allAccounts && allAccounts[0] ? allAccounts[0].name : "";
};

export const getUserFirstName = () => {
  const allAccounts = msalInstance.getAllAccounts();
  return allAccounts && allAccounts[0] && allAccounts[0]?.name
    ? allAccounts[0]?.name.substring(allAccounts[0].name.indexOf(",") + 1)
    : "";
};

export const getLoginName = () => {
  const allAccounts = msalInstance.getAllAccounts();
  return allAccounts && allAccounts[0] ? allAccounts[0].username : "";
};
