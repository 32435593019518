import { ReactNode, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  SortDirection,
  TableCell,
  makeStyles,
  TablePagination,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
    "& thead th": {
      fontWeight: "600",
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.light,
    },
    "& tbody td": {
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      // @ts-ignore
      backgroundColor: theme.palette.secondary.hoverOverColour,
      cursor: "pointer",
    },
  },
}));

interface TblContainerProps {
  children: ReactNode;
}

interface TblHeadProps {
  // handleHeadClicked: Function;
}

export default function useTable(records: any, headCells: any, filterfn?: any) {
  const classes = useStyles();

  const pages = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState<SortDirection>(false);
  const [orderBy, setOrderBy] = useState<string | number>("");
  const [checked, setChecked] = useState(false);
  // const [filter, setfilter] = useState((items: any) => {
  //   return items;
  // });
  const TblContainer = ({ children }: TblContainerProps) => (
    <Table size="small" className={classes.table}>
      {children}
    </Table>
  );

  // { handleHeadClicked }: TblHeadProps
  const TblHead = () => {
    const handleSortRequest = (cellId: string | number) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    const onChangeTHead = (event: any) => {
      setChecked(event.target.checked);
      // Emitter.emit('SELECT_ALL_TABLE_ROWS', event.target.checked);
      // handleHeadClicked(event.target.checked);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell: any) =>
            headCell.value === "isSelected" ? (
              <TableCell key={headCell.name}>
                <Checkbox checked={checked} onChange={onChangeTHead} />
              </TableCell>
            ) : (
              <TableCell
                key={headCell.value}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <TableSortLabel
                    active={order === headCell.value}
                    direction="asc"
                    onClick={() => {
                      handleSortRequest(
                        headCell.value ? headCell.value : false
                      );
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>
                )}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records?.length}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  function stableSort(array: any, comparator: any) {
    const stabilizedThis = array.map((el: any, index: any) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  }

  function getComparator(order: any, orderBy: any) {
    return order === "desc"
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    const doesfilterexist = (records: any) => {
      if (filterfn?.fn != undefined) {
        return filterfn?.fn(records);
      } else {
        return records;
      }
    };
    return stableSort(
      doesfilterexist(records),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}
