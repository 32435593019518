import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from "clsx";


export interface StyleProps {
  hover: string;
  selection:string;
  text:string;
}

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    '& $selected': {
      backgroundColor:  props.selection + "!important",
    }
  }),
  nested: {
    paddingLeft: theme.spacing(5),
    cursor: "pointer",
  },
  active: {
    "&:hover": {
      color: theme.palette.text.secondary,
      backgroundColor: (props) => props.hover,
      borderTopRightRadius:25,
      borderBottomRightRadius:25,
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.secondary,
      },
      "& $icon": {
        color: theme.palette.text.secondary,
      },
    },
   
  },
  title: {
    marginRight: "auto",
    color: (props) => props.text,
  },
  selected: {
    backgroundColor: (props) => props.selection + "!important",
    borderTopRightRadius:25,
    borderBottomRightRadius:25,
    borderLeft:"thick solid #26890D",
    color:"#26890D",
    "& $title": {
      color: "#26890D",
    },
    "& $icon": {
      color: "#26890D",
    },
  }
}));
const subMenu = [
  {
    id:1,
    menu:"Child 1"
  },
  {
    id:2,
    menu:"Child 2"
  }
]
export default function PreviewMenu({hover, selection, text}:StyleProps) {
  const props = {
    hover:hover,
    selection: selection,
    text:text
  }
 
  const classes = useStyles(props);
  
  const [open, setOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button onClick={handleClick} 
          className={clsx(classes.active)}
          classes={{ selected: classes.selected }}>
        <ListItemIcon>
          <MenuIcon className={classes.title} />
        </ListItemIcon>
        <ListItemText className={classes.title} primary="Parent" />
        {open ? <ExpandLess className={classes.title} /> : <ExpandMore className={classes.title}/>}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            subMenu.map((item) => 
              <ListItem  
              key={item.id} 
              className={clsx(classes.nested, classes.active)}
              classes={{ selected: clsx(classes.selected) }}
              selected={selectedIndex === item.id}
              onClick={(event) => handleListItemClick(event, item.id)}>
              <ListItemText className={classes.title} primary={item.menu} />
              </ListItem>
            )
          }
        </List>
      </Collapse>
    </List>
  );
}