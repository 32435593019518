import { Box } from "@material-ui/core";
import ChatBubble from "./ChatBubble";
import { ChatMessage, GPTMessage } from "./ChatMessage";
import React, { useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";

type ChatViewProps = {
  chatHistory: ChatMessage[];
  devMode: boolean;
};

function ChatBox(props: ChatViewProps) {
  const scrollRef = useRef(null);
  const { instance, accounts, inProgress } = useMsal();
  
  const name = accounts[0].name.split(", ")[1];

  const [welcomeMessage, setWelcomeMessage] = useState(
    {
      message: `Hi ${name} I’m IMCP Assist, an AI assistant that can help you search for information and collate data. What can I help you with?`,
      role: "llm",
      name: "",
      date: new Date()
    });

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.chatHistory]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "95%",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          margin:2,
          overflow:"auto"
        }}
      >
        <Box
          sx={{
            display: "flex",
            p: 1,
            m: 1,
            flexDirection: "column",
            borderRadius: 1,
          }}
        >
           <ChatBubble chatMessage={welcomeMessage} devMode={props.devMode} />
          {props.chatHistory.map((chatMessage, index) => (
            <ChatBubble chatMessage={chatMessage} devMode={props.devMode} />
          ))}
        </Box>
        <div ref={scrollRef} />
      </Box>
    </Box>
  );
}

export default ChatBox;
