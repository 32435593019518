import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import Emitter from '../utils/Emitter'; 

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  const handleSearch = event => {
    Emitter.emit('FILTER_APPLICATIONS', event.target.value);
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >

      
      <Box>
   
        <Typography color="textPrimary" variant="h4" >
            Applications
        </Typography>
      </Box>

      <Box mt={3}> 
        <Card>
          <CardContent>
            <Box display="flex" p={0} bgcolor="background.paper">
              <Box flexGrow={1}>
                  <TextField  
                              InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SvgIcon fontSize="small" color="action">
                                          <SearchIcon />
                                      </SvgIcon>
                                    </InputAdornment>
                                    )
                              }}
                              placeholder="Search applications"
                              variant="outlined"
                              size="small"
                              onChange={handleSearch}
                              />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
