import React from "react";

import SearchIcon from "@material-ui/icons/Search";
import { makeStyles, InputAdornment, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    width: "25%",
  },
}));

export default function Searchbar(props) {
  const classes = useStyles();
  const { handleSearch, title } = props;

  return (
    <TextField
      label={title || "Search"}
      className={classes.searchInput}
      margin="dense"
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: "#97999B" }} />
          </InputAdornment>
        ),
      }}
    />
  );
}
