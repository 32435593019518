import React from "react";
import { Grid } from "@material-ui/core";
import Controls from "src/components/utils/controls/Controls";
import { useFormik, Formik, Form, Field } from "formik";
import MuiButton from "../utils/Button";
import { Config } from "src/types/config";
import { columnMap } from "./columnMap";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(2),
  },
}));

interface UpdateFormProps {
  idName: string;
  addOrEdit: Function;
  initialValues: any;
  disableContent: boolean;
  config: Config[];
}

const UpdateForm = ({
  idName,
  addOrEdit,
  disableContent,
  config,
  initialValues,
}: UpdateFormProps) => {
  const classes = useStyles();
  console.log("addOrEdit")
  console.log(addOrEdit)
  const handleSubmit = (state: any) => {
    addOrEdit(state);
  };

  const configFilter = (mod: number) => (itm: Config, idx: number) => {
    if (itm?.type === "id") return undefined;
    // skips route inputs on edit
    // assumes id will be the first element, and true if on create
    return idx % 3 === mod;
  };

  const columnConfig = [
    config.filter(configFilter(1)),
    config.filter(configFilter(2)),
    config.filter(configFilter(0)),
  ];

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, touched, isValidating }) => (
        <Form>
          <Grid container>
            <Grid item xs={4} className={classes.pageContent}>
              {columnConfig[0].map((itm, idx) =>
                columnMap(itm, idx, undefined, errors)
              )}
            </Grid>
            <Grid item xs={4} className={classes.pageContent}>
              {columnConfig[1].map((itm, idx) =>
                columnMap(itm, idx, undefined, errors)
              )}
            </Grid>
            <Grid item xs={4} className={classes.pageContent}>
              {columnConfig[2].map((itm, idx) =>
                columnMap(itm, idx, undefined, errors)
              )}
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              {disableContent ? (
                <div> </div>
              ) : (
                <div>
                  <MuiButton text="Submit" type="submit" />
                  {/* removed the MUI button until it is fixed and working */}
                  {/* <MuiButton
                  variant="contained"
                  color="default"
                  size="large"
                  text="Reset"
                /> */}
                </div>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default UpdateForm;
