import { ReactNode, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core";
import { Config } from "src/types";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "95%",
      margin: theme.spacing(1.5),
    },
  },
}));

export const formReducer = (
  state: any,
  {
    name,
    value,
    reducer = () => 0,
  }: { name: string; value?: any; reducer?: Function }
) => {
  if (name === "reset") return {};
  return reducer(state, value);
};

export const useForm = (
  initialValues: any,
  config: Config[],
  onSubmit: Function
) => {
  const [state, dispatch] = useReducer(formReducer, initialValues);
  const initErrors: { [name: string]: any } = {};
  const [errors, setErrors] = useState(initErrors);

  const validate = (fieldValues = state) => {
    let temp: { [name: string]: any } = { ...errors };
    const routeValues = { ...fieldValues?.route };
    config.forEach((itm) => {
      if (
        itm?.value(state) in fieldValues ||
        itm?.value(state) in routeValues
      ) {
        temp[itm?.value(state)] = itm?.validation
          ? //@ts-ignore
            itm?.validation?.validation(fieldValues)
            ? ""
            : //@ts-ignore
              itm?.validation?.message
          : "";
      }
    });
    setErrors({ ...temp });
    if (fieldValues === state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const resetForm = () => {
    dispatch({ name: "reset" });
    setErrors({});
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate()) {
      onSubmit(state, resetForm);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    reducer?: Function
  ) => {
    // This takes in the name and the value of the form filled out
    const { name, value } = e.target;
    dispatch({ name, value, reducer });
  };

  return {
    state,
    errors,
    resetForm,
    handleInputChange,
    handleSubmit,
  };
};

interface FormProps {
  children: ReactNode;
  [other: string]: any;
}
export function Form({ children, ...other }: FormProps) {
  const classes = useStyles();

  return (
    <form className={classes.root} {...other}>
      {children}
    </form>
  );
}
