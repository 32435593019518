import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function Inputdrop(props) {
  let {
    name,
    label,
    value,
    error = null,
    onChange,
    disabled,
    options,
    freeSolo,
    ...other
  } = props;

  const [isRendered, setIsRendered] = useState(true);

  return (
    <Autocomplete
      name={name}
      value={value}
      inputValue={value}
      onInputChange={(event, value, reason) => {
        // Only fired while first render
        if (isRendered && value === "") {
          setIsRendered(false);
          return;
        }
        if (reason === "clear") onChange({ target: { name, value: "" } });
        if (value !== null && value !== undefined && value !== "") {
          onChange({ target: { name, value } });
        }
      }}
      options={options}
      freeSolo
      getOptionLabel={(option) => option.title || ""}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          error
          helperText={name ? error[name] : null}
          {...params}
          label={label}
          margin="dense"
          {...other}
          {...(error && { error: true, helperText: error })}
        />
      )}
    />
  );
}
