import "react-perfect-scrollbar/dist/css/styles.css";
import { useRef, useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./appInsights";

import { ThemeProvider } from "@material-ui/core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import GlobalStyles from "./styles/GlobalStyles";
import { QueryClient, QueryClientProvider } from "react-query";
import { theme } from "./theme/index";
import { routes } from "./routes";
import { storeCurrentPath } from "./components/utils/RouterStorage";
import { msalConfig } from "./components/auth/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Location } from "history";
import { getAutorenewedToken, getUserName } from "src/components/auth/msalUtils";
import mixpanel from 'mixpanel-browser';

const msalInstance = new PublicClientApplication(msalConfig);

library.add(fas);
const MENU_COLOR_SESSION_STORAGE_KEY = 'MENU_COLOR';
const TEXT_COLOR_SESSION_STORAGE_KEY = 'TEXT_COLOR';
const USER_ID_SESSION_STORAGE_KEY = 'USER_ID';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ? process.env.REACT_APP_MIXPANEL_TOKEN : "")

export const App = () => {

  const routing = useRoutes(routes);
  const allAccounts = msalInstance.getAllAccounts();
  const location = useLocation();
  const usePrevious = (value: Location): Location => {
    const ref = useRef(value);
    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  };

  const queryClient = new QueryClient();

  const prevLocation = usePrevious(location);


  const getThemeAndCurrentUser = async () => {
    try {
      const [themeResponse, userResponse] = await Promise.all([
        fetch(process.env.REACT_APP_API_PATH + "/theme", {
          method: "GET",
          headers: { authorization: await getAutorenewedToken() }
        }),
        fetch(process.env.REACT_APP_API_PATH + "/user/current", {
          method: "GET",
          headers: { authorization: await getAutorenewedToken() }
        })
      ]);
  
      if (!themeResponse.ok) {
        const themeError = (await themeResponse.json()).message || themeResponse.statusText;
        return Promise.reject(themeError);
      }
  
      if (!userResponse.ok) {
        const userError = (await userResponse.json()).message || userResponse.statusText;
        return Promise.reject(userError);
      }
  
      const themeData = await themeResponse.json();
      const userData = await userResponse.json();
  
      themeData.map((item) => {
        if (item.category === "Menu")
          window.sessionStorage.setItem(MENU_COLOR_SESSION_STORAGE_KEY, item.colorHexCode);
        else if (item.category === "Text")
          window.sessionStorage.setItem(TEXT_COLOR_SESSION_STORAGE_KEY, item.colorHexCode);
      });
  
      window.sessionStorage.setItem(USER_ID_SESSION_STORAGE_KEY, userData.userId);
  
      window.location.reload(false);
      
    } catch (error) {
      alert("Error occurred while setting theme and fetching user data");
    }
  };

  useEffect(() => {
    if (
      location.pathname !== "/loginCallback" &&
      prevLocation &&
      prevLocation.pathname !== "/loginCallback"
    )
      storeCurrentPath();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(async () => {
    if(allAccounts.length > 0 && !window.sessionStorage.getItem(MENU_COLOR_SESSION_STORAGE_KEY) && !window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY) && !window.sessionStorage.getItem(USER_ID_SESSION_STORAGE_KEY))
    {
      await getThemeAndCurrentUser();
      mixpanel.track_pageview({user_id:window.sessionStorage.getItem(USER_ID_SESSION_STORAGE_KEY)});
    }
    
  },[allAccounts]);

  return (
    <MsalProvider instance={msalInstance}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppInsightsErrorBoundary
            onError={() => <></>}
            appInsights={reactPlugin}
          >
            <GlobalStyles />
            {routing}
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </QueryClientProvider>
    </ThemeProvider>
  </MsalProvider>
  );
};
