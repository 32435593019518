import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
import MuiButton from '../utils/Button';
import PageHeader from '../utils/PageHeader';
import Page from '../utils/Page';
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import TemplateUploadView from "../template_upload/TemplateUpload";
import ProjectPortfolioTab from './ProjectPortfolioTab';
import ProjectSetUpTab from './ProjectSetUpTab';
import StepDialog from './StepDialog';
import { getAutorenewedToken } from '../auth/msalUtils';
import axios from "axios";
import MissedSaveingProject from './MissedSaveingProject';

const ProjectTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#26890D',
  },
})(Tabs);

const ProjectTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
  disabled:{}
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 18,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    boxShadow:"none"
  },
}));

interface ValidateProject{
  ProjectsExits:boolean
  WbsExits:boolean
  ContractPackageExits:boolean
}
export default function DataUploadTabs() {
  const classes = useStyles();
  const [currStep, setCurrStep] = React.useState(0);
  const [prevStep, setPrevStep] = React.useState(0);
  const [nextStep, setNextStep] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isStepSet, setIsStepSet] = useState(false);
  const [validateProject, setValidateProject] = useState<ValidateProject>();
  const [openValidationDialog, setOpenValidationDialog] = useState<boolean>(false);
  const [projectSaved, setProjectSaved] = useState(true);
  const [openMissedSaveigProjectDialog, setOpenMissedSaveigProjectDialog] = useState<boolean>(false);
  const [errorPhrase, setErrorPhrase] = useState<string>(false);
  const apiPath = process.env.REACT_APP_API_PATH;

  const handleChange = (event, newValue) => {
    if(currStep == 0 && !projectSaved)
    {
      setNextStep(newValue)
      setOpenMissedSaveigProjectDialog(true)
    }
    else
    {
      currStep != newValue ? setPrevStep(currStep) : setPrevStep(prevStep);
      setCurrStep(newValue);
    }
   
  };

  async function ValidateProject()
  {
    setIsLoading(true)
    return fetch(
      apiPath +"/project/ValidateProject",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        setIsLoading(false);
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      if(!data.projectsExits && currStep > 0 && currStep < 3)
      {
        !data.wbsExits && currStep == 2 ? setErrorPhrase("Before proceeding you need to configure your portfolio of projects and define work break down structure.") :  setErrorPhrase("Before proceeding you need to configure your portfolio of projects.")
        setOpenValidationDialog(true)
        setCurrStep(prevStep)
      }
      else if(!data.wbsExits && currStep > 1 && currStep < 3){
        setOpenValidationDialog(true)
        currStep == 2 ? setErrorPhrase("Before proceeding you need to define work break down structure on step 2."):setErrorPhrase("")
        setCurrStep(prevStep)
      }
      else if(!data.contractPackageExits && currStep > 2){
        if(prevStep != 3)
        {
          setOpenValidationDialog(true)
          currStep == 3 ? setErrorPhrase("We advise configure project, work break down structure and contract package before uploading dashboard specific project data to IMCP."):setErrorPhrase("")
        }
        else{
          setCurrStep(prevStep)
        }
        //setCurrStep(prevStep)
      }

     if(!isStepSet)
     {
        var step = 0;
        step = !data.projectsExits ?  0 : !data.wbsExits? 1 : !data.contractPackageExits ? 2 : 3
        setCurrStep(step)
        setPrevStep(step)
     }
      setIsStepSet(true);
      setValidateProject(data); 
      setIsLoading(false)
    });
  }

  const sendData = (step:number) => {
    setCurrStep(step)
    setOpenValidationDialog(false)
  }
  const closeSaveingProjectDialog = (step:number) => {
    setCurrStep(step)
    setOpenMissedSaveigProjectDialog(false)
  }
  const isProjectSaved = (save:boolean) => {
    !save ? setProjectSaved(save) : setProjectSaved(save)
  }
  const nevigateNextStep = () =>{
    if(currStep == 0 && !projectSaved)
    {
      setNextStep(currStep+1)
      setOpenMissedSaveigProjectDialog(true)
    }
    else
      setCurrStep(currStep + 1)
  }
  useEffect(() => {
    prevStep != 3 ? setPrevStep(prevStep) : setCurrStep(currStep)
    ValidateProject();
  }, [currStep]);

  return (
      <Page title="Data Upload">
          <PageHeader
              title="Data Upload"
              subtitle="Upload Data"
              icon={<GroupRoundedIcon className={classes.icon} forntsize="large" />}
              disableBackButton={true}
          />
          <Paper className={classes.pageContent}>
              <div className={classes.root}>
                  <div className={classes.demo1}>
                      <ProjectTabs value={currStep} onChange={handleChange} aria-label="ant example">
                          <ProjectTab label="Step:1 Project & Portfolios" />
                          <ProjectTab label="Step:2 WBS" />
                          <ProjectTab label="Step:3 Contract Package" />  
                          <ProjectTab label="Step:4 Dashboards" />
                      </ProjectTabs>
                      <Typography className={classes.padding} />
                     {(!isLoading && !openValidationDialog)&&
                     <>
                        {currStep == 0 && <ProjectPortfolioTab projectSaved={isProjectSaved}/>}
                        {currStep == 1 && <ProjectSetUpTab 
                            heading = "Work Breakdown Structure" 
                            subHeading ="Populate and upload provided CSV templates to define Work Breakdown Structure (WBS) for individual projects or apply to all projects within a portfolio."
                            fileName='WorkBreakdownStructure'
                            />
                        }
                        {currStep == 2 && <ProjectSetUpTab 
                            heading = "Contract Package" 
                            subHeading ="Populate and upload provided CSV templates to define Contract Packages for individual projects or apply to all projects within a portfolio."
                            fileName='ContractPackage'
                            />
                        } 
                        {currStep == 3 && <TemplateUploadView />}
                     </>
                      }
                      <Typography className={classes.padding} />
                      <Grid container item xs={12} justifyContent="flex-end">
                          {currStep != 0 && <MuiButton onClick={() => setCurrStep(currStep - 1)} variant="outlined" color="secondary" text="Back" type="submit" />}
                          {currStep != 3 && <MuiButton onClick={nevigateNextStep} text="Next" type="submit" />}
                      </Grid>
                  </div>
              </div>
              {openValidationDialog && <StepDialog errorPhrase={errorPhrase} currStep={currStep} prevStep={prevStep} sendData = {sendData}></StepDialog>}
              {openMissedSaveigProjectDialog && <MissedSaveingProject currStep={currStep} nextStep={nextStep} closeDialog = {closeSaveingProjectDialog}></MissedSaveingProject>}
          </Paper>

      </Page>
  );
}   