// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs

import { Configuration } from "@azure/msal-browser";

// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const msalConfig: Configuration = {
  auth: {
    clientId: String(process.env.REACT_APP_CLIENT_ID),
    authority:
      "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID,
    redirectUri: window.location.origin + "/loginCallback",
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};
