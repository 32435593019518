import '../../styles/uploading.css'
import { useTheme } from '@material-ui/core';

const {
    BlobServiceClient
} = require("@azure/storage-blob");
 
export const deleteFileFromBlob = async (file, containerURL, containerName='') => {
    const blobServiceClient = new BlobServiceClient(
        `${containerURL}`
      );
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(file.fileName)
    const response = await blockBlobClient.deleteIfExists()
                                          .catch(err => {
                                              return {error: 'Delete File Error' + err}
                                          });
    return Promise.resolve(response)
}
 
export const uploadFileToBlob = async (containerURL, file, progress, fileGUID = 0, containerName='') => {
    const blobServiceClient = new BlobServiceClient(
        `${containerURL}`
      );
    const containerClient = blobServiceClient.getContainerClient( containerName );
    const blockBlobClient = containerClient.getBlockBlobClient( fileGUID
                                                                ? (fileGUID + '_' + file.name)
                                                                : file.name );
    const response = await blockBlobClient.upload(
      file,
      file.size,
      {
        blobHTTPHeaders: { blobContentType: file.type },
        onProgress: p => progress(
                            fileGUID ? ( fileGUID + '_' + file.name ) : file.name,
                            p.loadedBytes / file.size )
      }
    ).catch(err => {return {error: 'Upload Error' + err}});
    return Promise.resolve(response)
}
 
export const getBlobs = async (containerURL) => {
    const blobServiceClient = new BlobServiceClient(
        `${containerURL}`
    );
    const containerClient = blobServiceClient.getContainerClient('');

    let items=[];
    let blobs = containerClient.listBlobsFlat();
    for await (const blob of blobs) {
      items.push({...blob, url: containerURL.url});
    }
    return Promise.resolve(items)
}

export const humanFileSize = (bytes, si=false, dp=1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

export const UploadProgressBar = props => {
  const { progress } = props;
  const theme = useTheme();
  // const classes = useStyles(useTheme());
  const fileNameList = Object.keys(progress);

  return ( fileNameList.length > 0 ?
    <div style={{margin: '15px 25px', boxShadow: '0px 1px 2px #97999B'}}>
      {fileNameList.map((fileName, idx) => 
        <div key={idx} className={'wrapperItem'}>
          <div className={'leftSide'}>
            <div className={'progressBar'} style={{ backgroundColor: theme.palette.primary.greyColor }}>
              <div style={{ width: `${progress[fileName]}%`, backgroundColor: theme.palette.primary.main }} />
            </div>
            <label>{fileName}</label>
          </div>
          <span className={'percentage'}>{progress[fileName]}%</span>
        </div>
      )}
    </div>: ''
  )
}
