import UpdateContainer from "../UpdateContainer";
import { isUrlValid } from "../../../validation";
import { Config } from "src/types";
import { useGet } from "src/hooks";

const initialValues = {
  applicationId: 0,
  applicationName: "",
  applicationUrl: "",
  applicationImageUrl: "",
  // applicationCategory: "",
  resourceCategory: {
    resourceCategoryId: 0,
    resourceCategoryName: "Name",
    resourceCategoryIcon: "Icon",
  },
  applicationDescription: "",
  route: {
    routeId: 0,
    routePath: "",
    componentType: "Application",
    routeActive: true,
    routeCategory: {
      routeCategoryId: 4,
      routeCategoryName: "Application",
    },
  },
};

const config: Config[] = [
  {
    name: "applicationId",
    value: (state) => state?.applicationId,
    label: "Id",
    active: true,
    type: "id",
    validation: {
      validation: (value: string) =>
        !value ? "Idvalidation is required" : undefined,
    },
  },
  {
    name: "applicationName",
    value: (state) => state?.applicationName,
    label: "Name",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) => (!value ? "Title is required" : undefined),
    },
  },
  {
    name: "applicationDescription",
    value: (state) => state?.applicationDescription,
    label: "Description",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Description is required" : undefined,
    },
  },
  {
    name: "applicationUrl",
    value: (state) => state?.applicationUrl,
    label: "URL",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) => (!value ? "URL is required" : undefined),
    },
  },
  {
    name: "applicationImageUrl",
    value: (state) => state?.applicationImageUrl,
    label: "Image URL",
    active: true,
    type: "input",
    validation: {
      validation: (value: string) =>
        !value ? "Image URL is required" : undefined,
    },
  },
  {
    name: "resourceCategory.resourceCategoryId",
    value: (state) => state?.resourceCategory?.resourceCategoryId,
    options: {
      endpoint: "RESOURCE_CATEGORY",
      map: (itm: any) => ({
        id: itm.resourceCategoryId,
        title: itm.resourceCategoryName,
      }),
      display: (state: any) => state?.resourceCategory?.resourceCategoryName,
    },
    label: "Resource Category",
    active: true,
    type: "select",
    validation: {
      validation: (value: string) =>
        !value ? "Resource Category is required" : undefined,
    },
  },
];

const UpdateAppView = () => {
  const reducer = (data: typeof initialValues) => {
    return data;
  };
  const { data, refetch } = useGet("APPLICATION");
  return (
    <UpdateContainer
      name="application"
      title="Update applications"
      subtitle="View, Add or Update applications"
      endpoint="APPLICATION"
      idName="applicationId"
      config={config}
      skip={["routePath"]}
      initialValues={initialValues}
      data={data}
      refetch={refetch}
      reducer={reducer}
    />
  );
};

export default UpdateAppView;
