import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@material-ui/core";
import React from "react";
import MaterialButton from 'src/components/utils/Button';
import Error from "@material-ui/icons/ErrorOutline";

interface DeleteProps{
    deleteResponse:any
}
const DeleteData=(props:DeleteProps)=> {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    
    const handleClose = () => {
        props.deleteResponse(false);
    };

    const handleYes = () => {
        props.deleteResponse(true);
    };
  
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Would you like to retain the data (both raw and transformed versions) from related tables associated with this source up to this point on IMCP?
             (Select No if would you prefer to delete them all)</DialogContentText>
          </DialogContent>
          
          <DialogActions>
            <MaterialButton onClick={handleYes}
            text={'Yes'}
            style={{
                backgroundColor:theme.palette.secondary.red,
                color: theme.palette.whiteColor.main
              }}>Yes</MaterialButton>
              <MaterialButton onClick={handleClose}
            text={'No'}
            style={{
                backgroundColor:theme.palette.primary,
                color: theme.palette.whiteColor.main
              }}>No</MaterialButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  export default DeleteData