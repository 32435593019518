import { useQuery } from "react-query";
import { fetchWrap } from "src/utils/fetchWrap";
import UpdateContainer from "../UpdateContainer";
import { useGet } from "../../../hooks";
import { Config, OptionConfig } from "../../../types/config";

const initialValues = {
  routeId: 0,
  routePath: "",
  componentType: "",
  routeActive: true,
  routeCategory: {
    routeCategoryId: 0,
    routeCategoryName: "t",
  },
};
const UpdateRouteView = () => {
  const reducer = (data: typeof initialValues) => {
    return data;
  };
  const { data, refetch } = useGet("ROUTE");

  const routeConfig: Config[] = [
    {
      name: "routeId",
      value: (state) => state?.routeId,
      label: "Route ID",
      active: true,
      type: "id",
    },
    {
      name: "routePath",
      value: (state) => state?.routePath,
      label: "Route Path",
      active: true,
      type: "input",
      validation: {
        validation: (value: string) =>
          !value ? "Route Path is required" : undefined,
      },
    },
    {
      name: "componentType",
      value: (state) => state?.componentType,
      label: "Component Type",
      active: true,
      isNameValue: true,
      type: "select",
      options: {
        endpoint: "COMPONENT_TYPE",
        map: (itm: any, index) => ({
          id: index,
          title: itm,
        }),
        display: (state) => state.componentType,
      },
      validation: {
        validation: (value: string) =>
          !value ? "Component type is required" : undefined,
      },
    },
    {
      name: "routeCategory.routeCategoryId",
      value: (state) => state?.routeCategory.routeCategoryId,
      label: "Route Categories",
      active: true,
      type: "select",
      options: {
        endpoint: "ROUTE_CATEGORY",
        map: (itm: any, idx: any) => ({
          id: itm.routeCategoryId,
          title: itm.routeCategoryName,
        }),
        display: (state) => state.routeCategory.routeCategoryName,
      },
      validation: {
        validation: (value: string) =>
          !value ? "Route Category is required" : undefined,
      },
    },
    {
      name: "routeActive",
      value: (state) => state?.routeActive,
      label: "Route Active",
      active: true,
      type: "checkbox",
    },
  ];

  return (
    <UpdateContainer
      name="route"
      title="Update routes"
      subtitle="View, Add or Update routes"
      endpoint="ROUTE"
      idName="routeId"
      skip={["resourceCategoryName"]}
      config={routeConfig}
      initialValues={initialValues}
      data={data}
      refetch={refetch}
      reducer={reducer}
    />
  );
};

export default UpdateRouteView;
