import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import * as pbi from "powerbi-client";
import jwt_decode from "jwt-decode";
import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { makeStyles, Container, Typography, Paper } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import CircularProgress from "@material-ui/core/CircularProgress";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import AutorenewIcon from "@material-ui/icons/Autorenew";

import Page from "../utils/Page";
import PageHeader from "../utils/PageHeader";
import { getAutorenewedToken, getUserName } from "../auth/msalUtils";
import { adalConfig } from "../auth/adalConfig";
import MaterialButton from "../utils/Button";
import Emitter from "../utils/Emitter";
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingTop: theme.spacing(3),
    },
    fabProgress: {
        color: theme.palette.primary.main,
        marginTop: "20%",
        marginLeft: "35%",
        marginBottom: "15%",
    },
    newButtonFirst: {
        marginLeft: "0px",
        float: "left",
        marginRight: "1%",
        marginBottom:70,
        fontSize: "14px",
        background: theme.palette.whiteColor.main,
    },
    newButton: {
        float: "left",
        marginRight: "1%",
        fontSize: "14px",
        marginBottom:70,
        background: theme.palette.whiteColor.main,
    },
    reportClasses: {
        marginBottom: "1%",
        maxHeight: "790px",
        maxWidth: "1400px",
        height: "calc(75vw * 9/16)",
        width: "75vw",
    },
}));

const PdcstoolView = () => {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [pbiReport, setPbiReport] = useState(null);

    const [powerBIConfig, setPowerBIConfig] = useState({
        type: "report", // Supported types: report, dashboard, tile, visual and qna
        id: undefined,
        embedUrl: undefined,
    });

    const getCurrentPowerBIUrl = async () => {
        const authToken = await getAutorenewedToken();
        let powerBIPath = process.env.REACT_APP_PDCS_URL;
        powerBIPath = powerBIPath.replace(/(^"|"$)/g, '');
        const powerBIWebUrlArray = powerBIPath
            ? powerBIPath.split("/")
            : [];

        fetch(
            process.env.REACT_APP_API_PATH +
            "/embeddedtoken?WorkspaceId=" +
            powerBIWebUrlArray[4] + "&ReportId=" + powerBIWebUrlArray[6],
            {
                method: "GET",
                headers: { authorization: authToken },
            }
        ).then(async (response) => {
            const embedData = await response.json();

            setPowerBIConfig({
                type: "report",
                id: powerBIWebUrlArray[6],
                groupId: powerBIWebUrlArray[4],
                pageName: powerBIWebUrlArray[7],
                embedUrl: embedData.embedUrl,
                accessToken: embedData.accessToken,
                tokenType: pbi.models.TokenType.Embed,
                permission: pbi.models.Permissions.Read,
                viewMode: pbi.models.ViewMode.View,
                settings: {
                    localeSettings: {
                        language: "en",
                        formatLocale: "en-au",
                    },
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                    layoutType: pbi.models.LayoutType.Custom,
                    customLayout: {
                        displayOption: pbi.models.DisplayOption.FitToPage,
                    },
                },
            });
            setIsLoaded(true);
        });
    };
    useEffect(() => {
        getCurrentPowerBIUrl();
    },[]);

    const printPDF = () => {
        pbiReport.print();
      };
    
      const expand2Fullscreen = () => {
        pbiReport.fullscreen();
      };
    
      const reloadPBI = () => {
        window.location.reload(false);
      };

    return (
        <Page title="PDCS Tool">
            <PageHeader
                title="PDCS Tool"
                subtitle="Project Delivery Model & Contract Stratergy Analysis Tool"
                icon={<FontAwesomeIcon
                    icon={["fas", "tools"]}
                    style={{ fontSize: 18, opacity: 0.54 }}
                />}
                disableBackButton={false}
            />
                <Container maxWidth={true}>
                    {isLoaded ? (
                        <>
                            <PowerBIEmbed
                                embedConfig={powerBIConfig}
                                // eventHandlers={eventHandlersMap}
                                cssClassName={classes.reportClasses}
                                getEmbeddedComponent={(embedObject) => {
                                    setPbiReport(embedObject);
                                }}
                            />
                            <MaterialButton
                                text="Print"
                                variant="outlined"
                                color="primary"
                                startIcon={<PrintIcon />}
                                className={classes.newButtonFirst}
                                onClick={() => {
                                    printPDF();
                                }}
                            />

                            <MaterialButton
                                text="Fullscreen"
                                variant="outlined"
                                color="primary"
                                startIcon={<FullscreenIcon />}
                                className={classes.newButton}
                                onClick={() => {
                                    expand2Fullscreen();
                                }}
                            />

                            <MaterialButton
                                text="Reload"
                                variant="outlined"
                                color="primary"
                                startIcon={<AutorenewIcon />}
                                className={classes.newButton}
                                onClick={() => {
                                    reloadPBI();
                                }}
                            />
                            
                        </>
                    ) : (
                        <CircularProgress size={84} className={classes.fabProgress} />
                    )}
                </Container>
        </Page>
    );
};

export default PdcstoolView;
