import { useState } from "react";
import { TableBody, Toolbar, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DataTable from "../utils/DataTable";
import { Row } from "./Row";
import Searchbar from "src/components/utils/Searchbar";
import MaterialButton from "src/components/utils/Button";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
}));

export const UpdateTable = (props: any) => {
  const {
    openInPopup,
    config,
    records,
    setConfirmDialog,
    onDelete,
    idName,
    buttonTitle,
    onButtonClick,
  } = props;

  const classes = useStyles();

  const activeHeaders = config.filter((header: any) => header.active);

  const [filterFn, setFilterFn] = useState({
    fn: (items: any) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, filterFn);

  const handleSearch = (e: any) => {
    let target = e.target;
    setFilterFn({
      fn: (items: any) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((data: any) =>
            Object.values(data).some((val) =>
              String(val)
                .toLowerCase()
                .includes(target.value.toString().toLowerCase())
            )
          );
        }
      },
    });
  };

  const displayGrid =
    records && records?.length > 0 ? (
      <TblContainer>
        <TblHead />
        <TableBody>
          <>
            {recordsAfterPagingAndSorting().map((row: any) => (
              <Row
                activeHeaders={activeHeaders}
                row={row}
                idName={idName}
                openInPopup={openInPopup}
                setConfirmDialog={setConfirmDialog}
                onDelete={onDelete}
              />
            ))}
          </>
        </TableBody>
      </TblContainer>
    ) : null;

  return (
    <>
      <Toolbar>
        <Searchbar handleSearch={handleSearch} title={`Search`} />
        <MaterialButton
          text={buttonTitle}
          variant="outlined"
          startIcon={<AddIcon />}
          className={classes.newButton}
          onClick={onButtonClick}
        />
      </Toolbar>
      {displayGrid}
      <br></br>
      <TblPagination />
    </>
  );
  // : null
};
