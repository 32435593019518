import {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useDropzone } from "react-dropzone";
import {
  makeStyles,
  Toolbar,
  useTheme,
  Link,
  Typography,
  Grid,
  CircularProgress
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Notification from "../utils/Notification";
import CSVDataTable from "./CSVDataTable";
import axios from "axios";
import { getAutorenewedToken } from "../auth/msalUtils";
import DataUploadLogTable from "./DataUploadLogTable";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MissingWbsDialog from "./MissingWbsDialog";
import AlertDialog from "./AlertDialog";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(1),
  },

  paddingLeft: {
    paddingLeft: theme.spacing(3),
  },

  paddingLeft4: {
    paddingLeft: theme.spacing(4),
  },


  hover: {
    "&:hover": {
      backgroundColor: "#e8e9eb",
      cursor: "pointer",
    },
    "&:click": {
      borderColor: theme.palette.text.primary,
    },
    borderColor: theme.palette.background.light,
    backgroundColor: theme.palette.background.dark,
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 18,
  },
  linkMargin: {
    cursor: "pointer",
    color: "#007FFF",
    display: "flex",
   // flexDirection: "row-reverse",
   // marginBottom: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  dataDictonary:{
    cursor: "pointer",
    color:"rgba(0,0,0,0.54)"
  },
  downloadTemplate: {
    display:"flex",
    alignItems:"center",
    margin:"auto",
    justifyContent:"space-between"
  }
}));

interface ProjectSetUpProps{
  heading:string,
  subHeading:string,
  fileName:string
}
const ProjectSetUpTab = (props: ProjectSetUpProps) => {
  const classes = useStyles();
  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProjectMissing, setIsProjectMissing] = useState(false);
  const [isWbsMissing, setIsWbsMissing] = useState(false);
  const [missingProject, setMissingProject] = useState([]);
  const [missingWbs, setMissingWbs] = useState([])
  const [fileName, setFileName] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const generateCsv = async () => {
    return axios.get(
      apiPath + "/template/generateTemplate?dataSet="+props.fileName,
      {
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response;

      
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data.data);
      hiddenElement.target = '_blank';
      hiddenElement.download = props.fileName+'.csv';
      hiddenElement.click();
      return data;
    }).catch(error => alert("Error occured while generating template."));
  }
  const apiPath = process.env.REACT_APP_API_PATH;

  const parseCSV = async (csvText: any) => {
    const lines = csvText.split("\n");
    const headers = lines[0].split(",");
    const parsedData: any = [];

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");

      if (currentLine.length === headers.length) {
        const row: any = {};
        for (let j = 0; j < headers.length; j++) {
          row[headers[j].trim()] = currentLine[j].trim();
        }
        parsedData.push(row);
      }
    }

    setCsvData(parsedData);
    return parsedData
  };
  const onDrop = useCallback(async (acceptedFiles) => {
    // GET allowed blob storage details
    if (acceptedFiles.length === 1) {
      const allowedFileTypes = await getPortalAllowedFileTypes();
      if (allowedFileTypes.includes(acceptedFiles[0].type)) {
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        setFileName(acceptedFiles[0].name)
        var data = await validateFile(formData);
        if(data && data.validate)
        {
          await uploadTemplate(formData, data);
        }
        else if (!data.validate && data.missingProject)
        {
          setIsProjectMissing(true);
          setMissingProject(data.missingProject);

        }
        else if (!data.validate && data.missingWbs)
        {
          setIsWbsMissing(true);
          setMissingWbs(data.missingWbs);
        }

        if(data && data.validate)
        {
          var file = acceptedFiles[0];
          const reader = new FileReader();
              reader.onload = async (e:any) => {
                const csvText = e.target.result;
                await parseCSV(csvText).then((response) => {
                  updateWbsContractPackage(response);
                });
              };
              reader.readAsText(file);
              
        }
        
        //await getFileList();
       
      } else {
        setNotify({
          isOpen: true,
          message: 'Please upload "Data Template" in .csv format',
          type: "error",
        });
      }
    } else {
      setNotify({
        isOpen: true,
        message: "Please upload one file at a time",
        type: "error",
      });
    }
  }, []);
  async function getPortalAllowedFileTypes() {
    return fetch(
      apiPath +"/storageaccount/fileingestion/allowedfileformats",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
       
      }
      return data;
    });
  }
  async function getPortalAllowedPath() {
    return fetch(
      apiPath + "/storageaccount/fileingestion/allowedpaths",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      const datavalue = data.join(";").toString();
      return datavalue;
    });
  }
  const downloadDataDictonary = async (file:string) => {
    return axios.get(
      apiPath + "/storageaccount/fileingestion/getdatadictonary?file="+file,
      {
        headers: { authorization: await getAutorenewedToken() },
        responseType: 'arraybuffer'
      }
    ).then(async (response) => {
      const data = await response;
      var blob = new Blob([response.data], { type: 'application/pdf' });
      var hiddenElement = document.createElement('a');
      hiddenElement.href = URL.createObjectURL(blob)
      hiddenElement.target = '_blank';
      hiddenElement.download = file+".pdf";
      hiddenElement.click();
      return data;
    }).catch(error => alert("Error occured while generating template."));
  }

  async function validateFile(formData:FormData) {
    setIsLoading(true)
    return fetch(
      apiPath + "/template/validateTemplate?templateName="+props.fileName,
      {
        method: "POST",
        headers: { authorization: await getAutorenewedToken() },
        body: formData
      }
     
    ).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        setIsLoading(false);
       // await getFileList();
        setNotify({
          isOpen: true,
          message: "Upload Failed: "+ data.detail,
          type: "error",
        });
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      setIsLoading(false)
      return data;
    });
  }

  async function updateWbsContractPackage(data:any) {
    //setIsLoading(true)
    return fetch(
      apiPath +"/project/"+ props.fileName,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: await getAutorenewedToken(),
        },
        body: JSON.stringify(data)
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        //setIsLoading(false);
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
       
      }
      //setIsLoading(false)
      return data;
    });
  }

  async function uploadTemplate(formData:any, data:any)
  {
    const allowedPath = await getPortalAllowedPath();
    const authorization = await getAutorenewedToken();
    setIsLoading(true)
    fetch(
      apiPath + "/template/fileingestion/upload?contianerName=" +
        allowedPath+"&fileName="+data.dataSetName,
        {
          method: "POST",
          headers: 
          {
            authorization: authorization,
          },
          body: formData
        }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        setIsLoading(false);
        // get error message from body or default to response statusText
        setNotify({
          isOpen: true,
          message: 'Failed to upload data',
          type: "error",
        });
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      } 
      else{
        setNotify({
          isOpen: true,
          message: 'Data uploaded Successfully',
          type: "success",
        });
      }
      setIsLoading(false)
    });
  }
  const sendData = () => {
    setIsProjectMissing(false)
    setIsWbsMissing(false)
  }
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const globalTheme = useTheme();

  const style = useMemo(
    () => ({
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,

      borderStyle: "dashed",
      color: globalTheme.palette.text.secondary,
      outline: "none",
      transition: "border .24s ease-in-out",
      ...(isDragActive
        ? { borderColor: globalTheme.palette.primary.main }
        : {}),
      ...(isDragAccept
        ? { borderColor: globalTheme.palette.primary.main }
        : {}),
      ...(isDragReject
        ? { borderColor: globalTheme.palette.secondary.red }
        : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, globalTheme]
  );


  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    // getFileList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Typography className={classes.paddingLeft} variant="h5" component="div">{props.heading}</Typography>
      <Typography className={classes.paddingLeft} variant="caption" component="div">{props.subHeading}</Typography>
      <Typography className={classes.padding} />
      <Grid container>
        <Grid item xs={10}>
          <Grid spacing={10}>
            <div className={classes.downloadTemplate}>
              <Typography className={classes.paddingLeft4} variant="h6" component="div">Step 1- Download Csv Template</Typography>
              <div  className={classes.downloadTemplate}>
              <Link className={classes.linkMargin} onClick={generateCsv}><span>{props.heading} Template</span></Link>
              <MenuBookIcon className={classes.dataDictonary} onClick={() => downloadDataDictonary("Project Level Data")} />
              </div>
            </div>
            <Typography className={classes.paddingLeft4} variant="h6" component="div">Step 2- Populate with your {props.fileName == 'ContractPackage' ? 'Contract Package': 'WBS'} for all projects</Typography>
            <Typography className={classes.paddingLeft4} variant="h6" component="div">Step 3- Upload the completed template below</Typography>
            <Typography className={classes.padding} />
          </Grid>
        </Grid>
      </Grid> 
      <Toolbar>
        <div {...getRootProps({ style })} className={classes.hover}>
          <input
            {...getInputProps()}
            accept=".csv"
            type="file"
            multiple={false}
          />
          <p>
            <CloudUploadIcon />
          </p>
          <p>Drag and drop file here, or click to select files</p>
        </div>
      </Toolbar>
      {/* <CSVDataTable data={csvData} /> */}
      {!isLoading && <DataUploadLogTable></DataUploadLogTable>}
      {isLoading && (
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            spacing={0}
          >
            <CircularProgress />
          </Grid>
        )}
      {isWbsMissing &&  <MissingWbsDialog missingWbs={missingWbs} fileName={fileName}  sendData={sendData}/>}
      {isProjectMissing &&  <AlertDialog project={missingProject} fileName={fileName}  sendData={sendData}/>}
    </>
  );
};

export default ProjectSetUpTab;
