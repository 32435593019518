import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  Link,
  Tooltip,
  Box,
  CardHeader,
  Avatar,
  IconButton
} from "@material-ui/core";
import { useEffect, useState } from "react";
import axios from "axios";
import { getAutorenewedToken } from "../auth/msalUtils";
import MenuBookIcon from '@material-ui/icons/MenuBook';

const apiPath = process.env.REACT_APP_API_PATH;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  cardcontent: {
    padding: 0,
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    minHeight: 150,
    paddingTop: 0,
    paddingBottom: 0
  },
  link: {
    paddingTop: 0,
    paddingBottom: 0,
    cursor: "pointer",
    color: "#007FFF"
  },
  fontBold: {
    fontWeight: "bold"
  },
  bottomSpace: {
    minHeight: 40
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    borderRadius: "5px",
    padding: "0px",
    border: "0px",
    backgroundColor: "#fafafa"
  },
  dataUpload: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
  }
}));

export default function Templates() {
  const [records, setRecords] = useState([]);

  const getFileList = async () => {
    return axios.get(
      apiPath + "/template",
      {
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.data;
      setRecords(data);
      return data;
    }).catch(error => alert("Error occured while retriving template details."));
  }
  const generateCsv = async (file: string) => {
    return axios.get(
      apiPath + "/template/generateTemplate?dataSet=" + file,
      {
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response;


      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data.data);
      hiddenElement.target = '_blank';
      hiddenElement.download = file + '.csv';
      hiddenElement.click();
      return data;
    }).catch(error => alert("Error occured while generating template."));
  }
  const downloadDataDictonary = async (file: string) => {
    return axios.get(
      apiPath + "/storageaccount/fileingestion/getdatadictonary?file=" + file,
      {
        headers: { authorization: await getAutorenewedToken() },
        responseType: 'arraybuffer'
      }
    ).then(async (response) => {
      const data = await response;
      var blob = new Blob([response.data], { type: 'application/pdf' });
      var hiddenElement = document.createElement('a');
      hiddenElement.href = URL.createObjectURL(blob)
      hiddenElement.target = '_blank';
      hiddenElement.download = file + ".pdf";
      hiddenElement.click();
      return data;
    }).catch(error => alert("Error occured while generating template."));
  }

  useEffect(() => {
    getFileList()

  }, [])
  const classes = useStyles();

  return (
    <>
      <div className={classes.dataUpload}>
        <Typography align="left" color="textPrimary" gutterBottom variant="h5" classes={{ root: classes.item }} className={classes.fontBold}> Data Upload Templates</Typography>
        <Typography align="left" color="textPrimary" variant="body2" component="p">
          With the following templates you can populate and update dashboards within the portal. Download the CSV template files, populate the required columns, upload below and the dashboard will be refreshed
        </Typography>
      </div>
      <Grid container>
        {
          records.map((record) => (
            <Grid item key={record.dasboardId} lg={3} md={4} xs={12}>
              <Card
                className={classes.card}
              >
                <CardHeader
                  action={
                    <Tooltip title="Download Data Dictionary" arrow>
                      <IconButton aria-label="settings">
                        <MenuBookIcon onClick={() => downloadDataDictonary(record?.dasboardName == null ? "Project Level Data" : record?.dasboardName)} />
                      </IconButton>
                    </Tooltip>
                  }
                  title={<Typography align="left" color="textPrimary" gutterBottom variant="h5" classes={{ root: classes.item }} className={classes.fontBold}>{record?.dasboardName == null ? "Project Level Data" : record?.dasboardName}</Typography>}
                />
                <CardContent style={{ paddingTop: "0px", paddingLeft: "10px" }}>

                  <List classes={{ root: classes.listItem }}>
                    {record?.template?.length > 0 &&
                      record.template.map((temp) => (
                        temp?.dataSet && (
                          <ListItem
                            onClick={() => generateCsv(temp.dataSet)}
                            classes={{ root: classes.item }}
                            component={Link}
                            key={temp.templateId}
                          >
                            <ListItemText classes={{ root: classes.link }}>
                              {temp.dataSet}.csv
                            </ListItemText>
                          </ListItem>
                        )
                      ))
                    }
                  </List>

                  <Typography
                    align="left"
                    color="textPrimary"
                    variant="body2"
                    component="p"
                    className={classes.bottomSpace}
                  >
                    {record.dasboardName === null ?
                      ("*Use the templates above to refresh Project Level Data Addition/Modification")
                      : (`*Use the templates above to refresh ${record.dasboardName} Dashboard`)
                    }
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>

    </>
  )
}