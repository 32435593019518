import React, { useEffect, useState } from "react";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  TableContainer,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import DataTable from "../../utils/DataTable";
import ActionButton from "../../utils/ActionButton";
import Popup from "src/components/utils/Popup";
import UpdateDatasetForm from "./UpdateDatasetForm";
import Notification from "src/components/utils/Notification";
import ConfirmDialog from "src/components/utils/ConfirmDialog";
import Searchbar from "src/components/utils/Searchbar";
import MaterialButton from "src/components/utils/Button";
import { getAutorenewedToken } from "../../auth/msalUtils";
import { getAdminIconName } from "../../utils/RouterStorage";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
}));

const sourceConfig = [
  {
    id: 0,
    value: "dataSetId",
    label: "Dataset ID",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 1,
    value: "sourceId",
    label: "Source ID",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 2,
    value: "dataSetContainer",
    label: "Dataset Container",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 3,
    value: "dataSetSchema",
    label: "Dataset Schema",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 4,
    value: "dataSetType",
    label: "Dataset Type",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 5,
    value: "dataSetName",
    label: "Dataset Name",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 6,
    value: "dataSetTitle",
    label: "Dataset Title",
    active: false,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 7,
    value: "lastUpdatedDateTime",
    label: "Last Updated Date Time",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 8,
    value: "isActive",
    label: "Active?",
    active: true,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 9,
    value: "customSqlQuery",
    label: "Custom SQL Query",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 10,
    value: "customSourcePath",
    label: "Custom Source Path",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 11,
    value: "dataSetFormat",
    label: "Dataset Format",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 12,
    value: "dataSetStatus",
    label: "Dataset Status",
    active: false,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 13,
    value: "folderPathRaw",
    label: "Folder Path Raw",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 14,
    value: "folderPathCurated",
    label: "Folder Path Curated",
    active: true,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 15,
    value: "folderPathConsumption",
    label: "Folder Path Consumption",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 16,
    value: "databricksNotebookPath",
    label: "Databricks Notebook Path",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 17,
    value: "cmRenameCols",
    label: "Cm Rename Cols?",
    active: true,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 18,
    value: "cmDropNullCols",
    label: "Cm Drop Null Cols?",
    active: true,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 19,
    value: "fastTrackCopy",
    label: "Fast Track Copy?",
    active: true,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 20,
    value: "keyColumns",
    label: "Key Columns",
    active: false,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 21,
    value: "ingestType",
    label: "Ingest Type",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 22,
    value: "explore",
    label: "Explore?",
    active: false,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 23,
    value: "landingTable",
    label: "Landing Table",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 24,
    value: "sourceLoadStoredProcedure",
    label: "Source Load Stored Procedure",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 25,
    value: "targetTable",
    label: "Target Table",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 26,
    value: "targetLoadStoredProcedure",
    label: "Target Load Stored Procedure",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 27,
    value: "firstRowHasHeader",
    label: "First Row Has Header?",
    active: true,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 28,
    value: "indexColumns",
    label: "Index Columns",
    active: false,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 29,
    value: "datasetDateColumn",
    label: "Dataset Date Column",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 31,
    value: "fullLoadStartDateTime",
    label: "Full Load Start Date Time",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 32,
    value: "actions",
    label: "Actions",
    active: true,
    checkbox: false,
    disableSorting: true,
  },
];

const UpdateDatasetView = () => {
  const classes = useStyles();

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState(null);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //POPUP RELATED
  const [openPopup, setOpenPopup] = useState(false);
  const [disableContent, setDisableContent] = useState(false);
  const [popupTitle, setPopupTitle] = useState("Dataset Details");

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const activeHeaders = sourceConfig.filter((header) => header.active);

  const [errorMessage, setErrorMessage] = useState("");

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, filterFn);

  const getDatasetTableData = async () => {
    fetch(process.env.REACT_APP_API_PATH + "/dcc/datasets", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        setRecords(data);
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("There was an error!");
      });
  };

  const addNewDataset = async (inParam, inFn) => {
    fetch(process.env.REACT_APP_API_PATH + "/dcc/datasets/0", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: await getAutorenewedToken(),
      },
      body: JSON.stringify(inParam),
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          inFn();
          setRecordForEdit(null);
          setOpenPopup(false);
          setDisableContent(false);

          getDatasetTableData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          setNotify({
            isOpen: true,
            message: "Failed to add due to:" + data.message,
            type: "error",
          });
          alert("Error occured while adding new dataset");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("An error while calling API to add new dataset row!");
      });
  };

  const updateDataset = async (inParam, inFn) => {
    fetch(process.env.REACT_APP_API_PATH + "/dcc/datasets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: await getAutorenewedToken(),
      },
      body: JSON.stringify(inParam),
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          inFn();
          setRecordForEdit(null);
          setOpenPopup(false);
          setDisableContent(false);

          getDatasetTableData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          alert("Error happened while updating dataset row");
          setNotify({
            isOpen: true,
            message: "Failed to update due to:" + data.message,
            type: "error",
          });
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("An error while calling API to update dataset row!");
      });
  };

  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    getDatasetTableData();
  }, []);

  const openInPopup = (item, editMode) => {
    //setDisableContent(disabled)
    setRecordForEdit(JSON.parse(JSON.stringify(item)));

    if (editMode) {
      setPopupTitle("Edit Data Source");

      setDisableContent(false);
    } else {
      setPopupTitle("Data Source Details");

      setDisableContent(true);
    }

    setOpenPopup(true);
  };

  const addOrEdit = (inParam, resetForm) => {
    if (inParam.datasetId === 0) {
      addNewDataset(inParam);
    } else {
      updateDataset(inParam);
    }
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    fetch(process.env.REACT_APP_API_PATH + "/dcc/datasets/" + id, {
      method: "DELETE",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          getDatasetTableData();
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          alert("Error happened while deleting dataset row");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("An error while calling API to delete dataset row!");
      });

    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((data) =>
            Object.values(data).some((val) =>
              String(val)
                .toLowerCase()
                .includes(target.value.toString().toLowerCase())
            )
          );
        }
      },
    });
  };

  return (
    <Page title="View Dataset">
      <PageHeader
        title="View Dataset"
        subtitle="View, Add or Update dataset"
        icon={<FontAwesomeIcon icon={["fas", getAdminIconName()]} />}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <Searchbar handleSearch={handleSearch} title="Search dataset" />

          <MaterialButton
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setPopupTitle("Add new dataset");
              setDisableContent(false);
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          />
        </Toolbar>
        {records !== null ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow hover key={row["sourceId"]} value={row}>
                    {activeHeaders.map((col) =>
                      row[col.value] && col.checkbox === true ? (
                        <TableCell
                          key={col.id}
                          onClick={() => {
                            openInPopup(row);
                          }}
                        >
                          {" "}
                          <CheckIcon style={{ color: "green" }} />{" "}
                        </TableCell>
                      ) : row[col.value] === false && col.checkbox === false ? (
                        <TableCell
                          key={col.id}
                          onClick={() => {
                            openInPopup(row);
                          }}
                        >
                          {" "}
                          <ClearIcon style={{ color: "red" }} />{" "}
                        </TableCell>
                      ) : col.value === "actions" ? (
                        <TableCell key={col.id}>
                          <ActionButton
                            onClick={() => {
                              openInPopup(row, "edit");
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </ActionButton>
                          <ActionButton
                            color="default"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title:
                                  "Are you sure you want to delete this record?",
                                subtitle: "You can't undo this operation",
                                onConfirm: () => {
                                  onDelete(row["sourceId"]);
                                },
                              });
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </ActionButton>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={col.id}
                          onClick={() => {
                            openInPopup(row);
                          }}
                        >
                          {row[col.value]}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <br></br>
            <TblPagination />
          </>
        ) : (
          <TableContainer></TableContainer>
        )}
        <p>{errorMessage}</p>
      </Paper>

      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title={popupTitle}
        disableContent={disableContent}
        openInPopup={openInPopup}
        row={recordForEdit}
      >
        <UpdateDatasetForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          disableContent={disableContent}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Page>
  );
};

export default UpdateDatasetView;
