import React, { useEffect, useState } from "react";

import MaterialButton from "src/components/utils/Button";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import DataTable from "../../utils/DataTable";
import ActionButton from "../../utils/ActionButton";
import Popup from "src/components/utils/Popup";
import UpdateTableForm from "./UpdateTableForm";
import Notification from "src/components/utils/Notification";
import ConfirmDialog from "src/components/utils/ConfirmDialog";
import Searchbar from "src/components/utils/Searchbar";
import { getAutorenewedToken } from "../../auth/msalUtils";
import { getAdminIconName } from "../../utils/RouterStorage";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
}));

const tableConfig = [
  {
    id: 0,
    value: "tableId",
    label: "Table ID",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 1,
    value: "tableName",
    label: "Table Name",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 2,
    value: "tableDescription",
    label: "Table Description",
    active: true,
    checkbox: false,
    disableSorting: false,
  },
  {
    id: 3,
    value: "tableActive",
    label: "Active?",
    active: true,
    checkbox: true,
    disableSorting: false,
  },
  {
    id: 4,
    value: "actions",
    label: "Actions",
    active: true,
    checkbox: false,
    disableSorting: true,
  },
];

const UpdateTableView = () => {
  const classes = useStyles();

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //POPUP RELATED
  const [openPopup, setOpenPopup] = useState(false);
  const [disableContent, setDisableContent] = useState(false);
  const [popupTitle, setPopupTitle] = useState("Sidebar Details");

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const activeHeaders = tableConfig.filter((header) => header.active);

  const [errorMessage, setErrorMessage] = useState("");

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, filterFn);

  const getTableData = async () => {
    fetch(process.env.REACT_APP_API_PATH + "/table", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        setRecords(data);
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("There was an error!");
      });
  };

  const addNewTable = async (inTable, inFn) => {
    fetch(process.env.REACT_APP_API_PATH + "/table", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: await getAutorenewedToken(),
      },
      body: JSON.stringify(inTable),
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          inFn();
          setRecordForEdit(null);
          setOpenPopup(false);
          setDisableContent(false);

          getTableData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          alert("Error happened while adding new table");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("An error while calling API to add new table!");
      });
  };

  const updateTable = async (inTable, inFn) => {
    fetch(process.env.REACT_APP_API_PATH + "/table/" + inTable.tableId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: await getAutorenewedToken(),
      },
      body: JSON.stringify(inTable),
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          inFn();
          setRecordForEdit(null);
          setOpenPopup(false);
          setDisableContent(false);

          getTableData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          alert("Error happened while updating table");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("An error while calling API to update table!");
      });
  };

  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    getTableData();
  }, []);

  const openInPopup = (item, editMode) => {
    setRecordForEdit(JSON.parse(JSON.stringify(item)));

    if (editMode) {
      setPopupTitle("Edit Table");

      setDisableContent(false);
    } else {
      setPopupTitle("Table Details");

      setDisableContent(true);
    }

    setOpenPopup(true);
  };

  const addOrEdit = (inTable, resetForm) => {
    if (inTable.tableId === 0) {
      addNewTable(inTable);
    } else {
      updateTable(inTable);
    }
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    fetch(process.env.REACT_APP_API_PATH + "/table/" + id, {
      method: "DELETE",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        // check for error response
        if (response.ok) {
          getTableData();
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          alert("Error happened while deleting table");
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString());
        alert("An error while calling API to delete table!", error);
      });

    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((data) =>
            Object.values(data).some((val) =>
              String(val)
                .toLowerCase()
                .includes(target.value.toString().toLowerCase())
            )
          );
        }
      },
    });
  };

  return (
    <Page title="Update Table">
      <PageHeader
        title="Update Table"
        subtitle="View, Add or Update table configs"
        icon={<FontAwesomeIcon icon={["fas", getAdminIconName()]} />}
        backButton={true}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <Searchbar handleSearch={handleSearch} title="Search" />

          <MaterialButton
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setPopupTitle("Add new table");
              setDisableContent(false);
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((row) => (
              <TableRow hover key={row.tableId} value={row}>
                {activeHeaders.map((col) =>
                  col.checkbox === true ? (
                    <TableCell
                      key={col.tableId}
                      onClick={() => {
                        openInPopup(row);
                      }}
                    >
                      {row.route.routeActive ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <ClearIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                  ) : col.value === "actions" ? (
                    <TableCell key={col.tableId}>
                      <ActionButton
                        onClick={() => {
                          openInPopup(row, "edit");
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </ActionButton>
                      <ActionButton
                        color="default"
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "Are you sure you want to delete this record?",
                            subtitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(row.sidebarId);
                            },
                          });
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </ActionButton>
                    </TableCell>
                  ) : (
                    <TableCell
                      key={col.tableId}
                      onClick={() => {
                        openInPopup(row);
                      }}
                    >
                      {row[col.value]}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <br></br>

        <TblPagination />
        <p>{errorMessage}</p>
      </Paper>

      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title={popupTitle}
        disableContent={disableContent}
        openInPopup={openInPopup}
        row={recordForEdit}
      >
        <UpdateTableForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          disableContent={disableContent}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Page>
  );
};

export default UpdateTableView;
