import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  makeStyles,
  CardActionArea
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  actionArea: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '22px 16px 0px 16px'
  }
}));

const AppCard = ({ className, application, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardActionArea
        className={classes.actionArea}
        href={application.applicationUrl}
        target="_blank"
      >
        <CardMedia
          component="img"
          style={{
            maxWidth: '300px',
            height: '82px',
            flexGrow: 1,
            objectFit: 'contain',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
          alt={application.applicationName}
          image={`data:image/jpeg;base64,${application.applicationImageUrl}`}
          title={application.applicationName}
        />
        <div
          style={{
            display: 'flex',
            padding: '22px 16px 0px 16px',
            marginBottom: 20,
            flexGrow: 0
          }}
        >
          <Box style={{ flexGrow: 1 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              display="flex"
              variant="h5"
              flexGrow={1}
            >
              {application.applicationName}
            </Typography>

            <Box style={{ flexGrow: 0 }}>
              <Typography align="center" display="flex">
                {application.applicationDescription}
              </Typography>
            </Box>
          </Box>
        </div>
      </CardActionArea>
    </Card>
  );
};

AppCard.propTypes = {
  className: PropTypes.string,
  application: PropTypes.object.isRequired
};

export default AppCard;
