import React from 'react';
import { AntTabs, AntTab, TabPanel } from 'src/components/utils/Tabs';

import UpdateSourceView from './UpdateSource';
import UpdateDatasetView from './UpdateDataset';
import UpdateDataColView from './UpdateDatasetCol';
import UpdatePropertyView from './UpdateDatasetUser';

export const DccTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AntTabs value={value} onChange={handleChange} aria-label="DCC tabs">
        <AntTab label="Data Sources" />
        <AntTab label="Datasets" />
        <AntTab label="Dataset Columns" />
        <AntTab label="Dataset User Properties" />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <UpdateSourceView />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UpdateDatasetView />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UpdateDataColView />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <UpdatePropertyView />
      </TabPanel>
    </div>
  );
};
