import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import TocIcon from "@material-ui/icons/Toc";
import SettingIcon from "@material-ui/icons/Settings";
import { useGet } from "src/hooks";
import { useEffect, useState } from "react";
import { ConnectionType } from "./ConnectionTypeModel";
import { getAutorenewedToken } from "../auth/msalUtils";
import ConnectionForm from "./ConnectionForm";
import { useNavigate } from "react-router";
import ConnectionChangeWarning from "./ConnectionChangeWarning";
import DeleteData from "./DeleteData";

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    icon: {
        color: theme.palette.text.primary,
        fontSize: 18,
    },
    card:{
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(3),
        justifyContent:"center",
        borderRadius: "5px", 
        padding: "0px", 
        border: "0px" ,
        backgroundColor:"#fafafa",
        cursor:"pointer"
      },
      cardContent:{
        paddingTop: "0px", 
        paddingLeft: "10px", 
        height: "200px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
      },
      bottomSpace:{
        minHeight:50,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
      },
      fabProgress: {
        color: theme.palette.primary.main,
        marginTop: "10%",
        marginLeft: "50%",
        marginBottom: "10%",
      },

}));
interface ConnectionProps{
    type:any
}
export default function ConnectionTypeView(props:ConnectionProps)
{
    const classes = useStyles();
    const [connectionType, setConnectionType ] = useState<ConnectionType[]>([]);
    const [selectedConnectionType, setSelectedConnectionType ] = useState<number>();
    const [selectedConnectionName, setSelectedConnectionName ] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [openConnectionChangeWarning, setOpenConnectionChangeWarning,] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog,] = useState(false);
    const navigate = useNavigate();



    const getConnectionType = async () => {

        fetch(process.env.REACT_APP_API_PATH + "/dataconfiguration", {
            method: "GET",
            headers: { authorization: await getAutorenewedToken() }
        }).then(async (response) => {
            const data = await response.json();
            setLoading(false);
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            setConnectionType(data);

        }).catch((error) => {
                setLoading(false);
        });
    } 
    const handelConnectionTypeSelection = (type:number, name: string) =>{
        // setSelectedConnectionType(type)
        // setSelectedConnectionName(name)
        props.type(type, name)
        //setOpenConnectionChangeWarning(true)
    }

    useEffect(() =>{
        if(process.env.REACT_APP_DISABLE_DATA_CONFIGURATION == "true")
             navigate('/not-found')
        else
            getConnectionType()
    },[])

    const warningResponse = (res:boolean) => {
        if(res)
        setOpenConnectionChangeWarning(false)
        setOpenDeleteDialog(true)
        //setFileValidation(false)
    }
    const deleteRespose = (res: boolean) => {
        if (res) {
            setOpenDeleteDialog(false)
            props.type(selectedConnectionType, selectedConnectionName)
        }

        //setFileValidation(false)
    }
    return (
        <>
        <h2 style={{paddingBottom:10}}>Select Connection Type</h2>

        {!loading && 
            <Grid container>  
            {openConnectionChangeWarning && <ConnectionChangeWarning warningResponse={warningResponse}></ConnectionChangeWarning>} 
            {openDeleteDialog && <DeleteData deleteResponse={deleteRespose}></DeleteData>} 
            {connectionType.map((conn) => (
                    <Grid item lg={2} md={4} xs={12}>
                    <Card
                        className={classes.card}
                        onClick={() => handelConnectionTypeSelection(conn.connectionTypeId, conn.name)}
                    >
                        <CardContent className={classes.cardContent}>
                            <img
                                style={{ maxWidth: "150px", maxHeight:"150px"}}
                                alt="Logo"
                                src={(conn?.imageBase64 !== null) ? `data:image/png;base64,${conn?.imageBase64}` : ''}
                            />
                        </CardContent>
                        <Typography variant="h6" className={classes.bottomSpace}>{conn.name}</Typography>
                    </Card>

                    </Grid>
                )) 
            }
            </Grid>
        }
        {loading && <CircularProgress size={84} className={classes.fabProgress} />}
        </>
    );
}