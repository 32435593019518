import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, IconButton, Menu, MenuItem } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import MinimizeRoundedIcon from "@material-ui/icons/Remove";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

type ChatTopBarProps = {
  toggleDevMode: (value: boolean) => void;
  closeSlide: (value: boolean) => void;
}

function ChatTopBar(props: ChatTopBarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDevMode = () => {
    setAnchorEl(null);
    props.toggleDevMode();
  };

  const handleSlideClose = () => {
    props.closeSlide();
};

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        padding: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom:"2px solid gray"
        //color: "white",
        //bgcolor: "#26890D",
      }}
    >
      <Box></Box>
      <Box style={{ display: "flex" }}>
        <Typography variant="h5" style={{ fontWeight: "lighter" }}>
          <span>IMCP</span>
        </Typography>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          <span>ASSIST</span>
        </Typography>
      </Box>
      <Box style={{ display: "flex",  flexDirection:"row-reverse"}}>
        {/* <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          //style={{ color: "white" }}
          onClick={handleClick}
        >
          <MoreVertRoundedIcon />
        </IconButton> */}

        <IconButton onClick={handleSlideClose} 
       // style={{ color: "white" }}
        >
          <MinimizeRoundedIcon />
        </IconButton>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            left: "50%",
            transform: "translateX(-77%) translateY(52%)",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleDevMode}>Set Dev Mode</MenuItem>
        <MenuItem onClick={handleClose}>Export Chat</MenuItem>
        <MenuItem onClick={handleClose}>Delete Chat</MenuItem>
      </Menu>
    </Box>
  );
}

export default ChatTopBar;
