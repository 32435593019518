import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export const AntTabs = withStyles({
  root: {
    maxWidth: '1600px'
  },
  indicator: {
    backgroundColor: '#85BC22'
  }
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: '24%',
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    background: '#eaeaea',
    '&$selected': {
      color: '#85BC22',
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:focus': {
      color: '#85BC22'
    }
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />);
